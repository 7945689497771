import { fromJS } from 'immutable';

import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { memberIdsListToMemberIdsKey } from 'Containers/ViewBenefitPage/utils';
import {
  CoveredMembers,
  DefaultCoveredMembers,
  HouseholdMember,
  SelectedPlansCoveredMembers,
  SelectedSupplementalPlans,
  SupplementalPlan,
  SupplementalPlansCache,
  SupplementalPlansFullHousehold,
} from 'Types/entities';
import { TypedMap } from 'Utils/immutable-types';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';

import {
  SELECT_PLAN,
  REMOVE_PLAN,
  SET_DEFAULT_COVERED_MEMBERS,
  SET_DEFAULT_SELECTED_PLANS,
  CLEAR_SUPPLEMENTAL_PLANS_CACHE,
  GET_SUPPLEMENTAL_PLANS_REQUEST,
  GET_SUPPLEMENTAL_PLANS_SUCCESS,
  GET_SUPPLEMENTAL_PLANS_FAILURE,
  GET_SUPPLEMENTAL_PLANS_SUCCESS_SET_CACHE,
  RESET_AUTO_ENROLLED,
  getSelectableBenefits,
} from './constants';

export const defaultSelectedPlans = {
  dental: null,
  vision: null,
  accident: null,
  hospital: null,
  critical: null,
  life: null,
  life_add: null,
  std: null,
  ltd: null,
  add: null,
  pet: null,
  legal: null,
  id_theft: null,
};

export interface OverviewPageReducerState {
  supplemental_plans_full_household: SupplementalPlansFullHousehold;
  selected_plans: SelectedSupplementalPlans;
  selected_plans_covered_members: SelectedPlansCoveredMembers;
  supplemental_plans_household_subsets_cache: SupplementalPlansCache;
  default_covered_members: DefaultCoveredMembers | Record<string, never>;
  isLoading: boolean;
  isInitialLoad: boolean;
}

export type OverviewPageState = TypedMap<OverviewPageReducerState>;

const initialState: OverviewPageState = fromJS({
  supplemental_plans_full_household: {},
  selected_plans: defaultSelectedPlans,
  selected_plans_covered_members: {
    // this stores the selected members at the time of plan selection
    dental: {},
    vision: {},
    accident: {},
    hospital: {},
    critical: {},
    life: {},
    life_add: {},
    add: {},
    std: {},
    ltd: {},
    pet: {},
    legal: {},
    id_theft: {},
    '401k': {},
  },
  supplemental_plans_household_subsets_cache: {},
  default_covered_members: {}, // this gets populated via saga with household members from member page
  isLoading: false,
  isInitialLoad: true,
});

function overviewPageReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PLAN:
      return selectPlan(state, action);
    case REMOVE_PLAN:
      return removePlan(state, action);
    case GET_SUPPLEMENTAL_PLANS_REQUEST:
      return getSupplementalPlans(state);
    case GET_SUPPLEMENTAL_PLANS_SUCCESS:
      return getSupplementalPlansSuccess(state, action);
    case GET_SUPPLEMENTAL_PLANS_FAILURE:
      return getSupplementalPlansFailure(state, action);
    case GET_SUPPLEMENTAL_PLANS_SUCCESS_SET_CACHE:
      return getSupplementalPlansSuccessSetCache(state, action);
    case SET_DEFAULT_COVERED_MEMBERS:
      return setDefaultCoveredMembers(state, action);
    case SET_DEFAULT_SELECTED_PLANS:
      return setSelectedSupplementalPlans(state, action);
    case CLEAR_SUPPLEMENTAL_PLANS_CACHE:
      return clearSupplementalPlansCache(state);
    case RESET_AUTO_ENROLLED:
      return resetSelectedPlanToAutoEnrolledPlan(state, action);
    default:
      return state;
  }
}

function getEligibleCoveredMembers(members: HouseholdMember[], eligibleMembers: string[]) {
  const state = {};
  members.forEach((member) => {
    state[member.external_id] = {
      memberId: member.external_id,
      covered: eligibleMembers?.includes(member.external_id),
      gender: member.gender,
      age: member.age,
      policyholder: member.policyholder,
      dependant: member.dependant,
    };
  });

  return state;
}

// sets default members selected on ViewBenefitPage for all benefits by
// updating coveredMembers[member].covered for all members
function setDefaultCoveredMembers(
  state,
  action: {
    type: string;
    supplementalPlans: SupplementalPlansFullHousehold;
    members: HouseholdMember[];
    client: string;
  },
) {
  const covered = {};
  const planTypes = Object.keys(action.supplementalPlans) as InsuranceType[];
  const selectableBenefits = getSelectableBenefits(action.client);

  planTypes.forEach((planType) => {
    if (selectableBenefits.includes(planType)) {
      const selectedPlanMembers: CoveredMembers = state
        .getIn(['selected_plans_covered_members', planType])
        .toJS();
      const selectedPlanMemberIds = Object.values(selectedPlanMembers)
        .filter((member) => member.covered)
        .map((member) => member.memberId);

      if (selectedPlanMemberIds.length > 0) {
        // Case: User has a plan selected and is revisiting ViewBenefitPage
        covered[planType] = getEligibleCoveredMembers(action.members, selectedPlanMemberIds);
      } else {
        // Case: User is visiting ViewBenefitPage without a selected plan
        const eligibleMembers = state
          .getIn(['supplemental_plans_full_household', planType, 'plans'])
          ?.toJS()
          .filter((plan) => !plan.auto_enrolled)[0]?.eligible_members;

        covered[planType] = getEligibleCoveredMembers(action.members, eligibleMembers);
      }
    } else {
      // Case: Non-selectable benefits - they have no member selection options
      covered[planType] = {};
    }
  });

  return state.set('default_covered_members', fromJS(covered));
}

function setSelectedSupplementalPlans(
  state,
  action: {
    type: string;
    supplementalPlans: SupplementalPlansFullHousehold;
    selectedPlans: SelectedSupplementalPlans;
  },
) {
  const updatedSelectedPlans = { ...defaultSelectedPlans };

  // Rebuild selected plans using previously selected plans and auto-enrolled plans
  Object.entries(action.supplementalPlans).forEach(([insuranceType, benefit]) => {
    // Check if the previously selected plan is still in the new plan set
    const selectedPlan = action.selectedPlans[insuranceType];
    const hasSelectedPlan = selectedPlan
      ? benefit.plans.filter((plan) => plan.external_id === selectedPlan.external_id).length > 0
      : false;

    if (hasSelectedPlan) {
      // User is still eligible for previously selected plan
      updatedSelectedPlans[insuranceType] = action.selectedPlans[insuranceType];
    } else {
      // Select any auto-enrolled plans
      const autoEnrolledPlans = benefit.plans.filter((plan) => plan.auto_enrolled === true);

      if (autoEnrolledPlans.length > 0) {
        updatedSelectedPlans[insuranceType] = autoEnrolledPlans[0];
      }
    }
  });

  return state.setIn(['selected_plans'], fromJS(updatedSelectedPlans));
}

function clearSupplementalPlansCache(state) {
  return state.set('supplemental_plans_household_subsets_cache', fromJS({}));
}

function resetSelectedPlanToAutoEnrolledPlan(
  state,
  action: {
    type: string;
    supplementalPlans: SupplementalPlansFullHousehold;
    insuranceType: InsuranceType;
  },
) {
  const autoEnrollState = {};

  Object.entries(action.supplementalPlans).forEach(([insuranceType, insuranceObj]) => {
    if (insuranceType === action.insuranceType) {
      const autoEnrolledPlans = insuranceObj.plans.filter((plan) => plan.auto_enrolled === true); // filter auto enrolled plans

      if (autoEnrolledPlans.length > 0) autoEnrollState[insuranceType] = autoEnrolledPlans[0]; // select first auto enrolled plan
    }
  });

  return state.mergeIn(['selected_plans'], fromJS(autoEnrollState));
}

function selectPlan(
  state,
  action: {
    type: string;
    insuranceType: InsuranceType;
    plan: SupplementalPlan;
    coveredMembers: DefaultCoveredMembers;
  },
) {
  // Save supplemental_page_state with the current state of selected members when plan is selected
  const coveredMembers = action.coveredMembers;

  return state
    .setIn(['selected_plans', action.insuranceType], fromJS(action.plan))
    .setIn(
      ['selected_plans_covered_members', action.insuranceType],
      fromJS(coveredMembers[action.insuranceType]),
    );
}

function removePlan(
  state,
  action: {
    type: string;
    insuranceType: InsuranceType;
  },
) {
  return state
    .setIn(['selected_plans', action.insuranceType], null)
    .setIn(['selected_plans_covered_members', action.insuranceType], fromJS({})); // reset state
}

function getSupplementalPlans(state) {
  return state
    .set('supplemental_plans_full_household', fromJS({}))
    .set('isLoading', true)
    .set('isInitialLoad', false);
}

function getSupplementalPlansSuccess(
  state,
  action: {
    type: string;
    response: SupplementalPlansFullHousehold;
  },
) {
  return state.set('supplemental_plans_full_household', fromJS(action.response)).set('isLoading', false);
}

function getSupplementalPlansSuccessSetCache(
  state,
  action: {
    type: string;
    response: SupplementalPlansFullHousehold;
    coveredMemberIds: string[];
  },
) {
  const supplementalPlans = action.response;
  const supplementalCache = state.get('supplemental_plans_household_subsets_cache').toJS();

  Object.entries(supplementalPlans).forEach(([key, benefit]) => {
    const plans = benefit.plans || [];

    if (plans.length) {
      const coveredMemberString = memberIdsListToMemberIdsKey(action.coveredMemberIds);

      // TODO can this be simplified?
      supplementalCache[coveredMemberString] = {
        ...supplementalCache[coveredMemberString],
        [key]: {
          plans: benefit.plans,
        },
      };
    }
  });
  return state.set('supplemental_plans_household_subsets_cache', fromJS(supplementalCache));
}

function getSupplementalPlansFailure(
  state,
  action: {
    type: string;
    error: Error;
  },
) {
  return state.set('error', action.error).set('isLoading', false);
}

const pojoOverviewPageReducer = wrapImmutableReducerInPojoTranslation(overviewPageReducer);

export default pojoOverviewPageReducer;
