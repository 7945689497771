export const GET_HOUSEHOLD_REQUEST = 'app/ProfilePage/GET_HOUSEHOLD_REQUEST';
export const GET_HOUSEHOLD_SUCCESS = 'app/ProfilePage/GET_HOUSEHOLD_SUCCESS';
export const GET_HOUSEHOLD_FAILURE = 'app/ProfilePage/GET_HOUSEHOLD_FAILURE';

// Get prepopulate profile data
export const GET_INTEGRATED_USER_PROFILE_REQUEST = 'app/ProfilePage/GET_INTEGRATED_USER_PROFILE_REQUEST';
export const GET_INTEGRATED_USER_PROFILE_SUCCESS = 'app/ProfilePage/GET_INTEGRATED_USER_PROFILE_SUCCESS';
export const GET_INTEGRATED_USER_PROFILE_FAILURE = 'app/ProfilePage/GET_INTEGRATED_USER_PROFILE_FAILURE';

// Pre-Populate profile using AlexID user (JVP)
export const GET_ALEX_ID_USER_PROFILE_REQUEST = 'app/ProfilePage/GET_ALEX_ID_USER_PROFILE_REQUEST';
export const GET_ALEX_ID_USER_PROFILE_SUCCESS = 'app/ProfilePage/GET_ALEX_ID_USER_PROFILE_SUCCESS';
export const GET_ALEX_ID_USER_PROFILE_FAILURE = 'app/ProfilePage/GET_ALEX_ID_USER_PROFILE_FAILURE';

/* Eligibility section (QUESTIONS) */
export const GET_CLIENT_SURVEY_REQUEST = 'app/ProfilePage/GET_CLIENT_SURVEY_REQUEST';
export const GET_CLIENT_SURVEY_SUCCESS = 'app/ProfilePage/GET_CLIENT_SURVEY_SUCCESS';
export const GET_CLIENT_SURVEY_FAILURE = 'app/ProfilePage/GET_CLIENT_SURVEY_FAILURE';

export const GET_JVP_CLIENT_SURVEY_REQUEST = 'app/ProfilePage/GET_JVP_CLIENT_SURVEY_REQUEST';
export const GET_JVP_CLIENT_SURVEY_SUCCESS = 'app/ProfilePage/GET_JVP_CLIENT_SURVEY_SUCCESS';
export const GET_JVP_CLIENT_SURVEY_FAILURE = 'app/ProfilePage/GET_JVP_CLIENT_SURVEY_FAILURE';

export const CREATE_OR_UPDATE_HOUSEHOLD_REQUEST = 'app/ProfilePage/CREATE_OR_UPDATE_HOUSEHOLD_REQUEST';
export const CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS = 'app/ProfilePage/CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS';
export const CREATE_OR_UPDATE_HOUSEHOLD_FAILURE = 'app/ProfilePage/CREATE_OR_UPDATE_HOUSEHOLD_FAILURE';

export const SET_ACTIVE_HOUSEHOLE_MEMBERS = 'app/ProfilePage/SET_ACTIVE_HOUSEHOLE_MEMBERS';

export const GET_INCENTIVE_SURVEY_SUCCESS = 'app/ProfilePage/GET_INCENTIVE_SURVEY_SUCCESS';
export const GET_INCENTIVE_SURVEY_FAILURE = 'app/ProfilePage/GET_INCENTIVE_SURVEY_FAILURE';
export const SET_INCENTIVE_SURVEY = 'app/ProfilePage/SET_INCENTIVE_SURVEYS';
export const SET_RESULT_PAGE_INCENTIVE_SURVEY = 'app/ProfilePage/SET_RESULT_PAGE_INCENTIVE_SURVEY';
export const RESET_FORM_DIRTY_STATE = 'app/ProfilePage/RESET_FORM_DIRTY_STATE';

export const GET_JVP_ELIGIBILITY_QUESTIONS_SUCCESS = 'app/ProfilePage/GET_JVP_ELIGIBILITY_QUESTIONS_SUCCESS';
export const GET_JVP_ELIGIBILITY_QUESTIONS_FAILURE = 'app/ProfilePage/GET_JVP_ELIGIBILITY_QUESTIONS_FAILURE';

// Eligibility section (RESPONSES)
export const GET_CLIENT_SURVEY_RESPONSE_SUCCESS = 'app/ProfilePage/GET_CLIENT_SURVEY_RESPONSE_SUCCESS'; // From JVP
export const SUBMIT_CLIENT_SURVEY = 'app/ProfilePage/SUBMIT_CLIENT_SURVEY';

export const SUBMIT_INCENTIVE_SURVEY = 'app/ProfilePage/SUBMIT_INCENTIVE_SURVEY';
export const SUBMIT_RESULT_INCENTIVE_SURVEY = 'app/ProfilePage/SUBMIT_RESULT_INCENTIVE_SURVEY';

/* Location section */
export const SEARCH_LOCATIONS_REQUEST = 'app/ProfilePage/SEARCH_LOCATIONS_REQUEST';
export const SEARCH_LOCATIONS_SUCCESS = 'app/ProfilePage/SEARCH_LOCATIONS_SUCCESS';
export const SEARCH_LOCATIONS_FAILURE = 'app/ProfilePage/SEARCH_LOCATIONS_FAILURE';
export const RESET_COUNTY_RESULTS = 'app/ProfilePage/RESET_COUNTY_RESULTS';

export const CHANGE_FORM_VALUE = 'app/ProfilePage/CHANGE_FORM_VALUE';
export const CHANGE_SURVEY_VALUE = 'app/ProfilePage/CHANGE_SURVEY_VALUE';
export const CHANGE_SURVEY_VALUE_FROM_RESULT_PAGE = 'app/ProfilePage/CHANGE_SURVEY_VALUE_FROM_RESULT_PAGE';

/* Member section */
export const EDIT_POLICYHOLDER = 'app/ProfilePage/EDIT_POLICYHOLDER';
export const EDIT_SPOUSE = 'app/ProfilePage/EDIT_SPOUSE';
export const EDIT_DEPENDENT = 'app/ProfilePage/EDIT_DEPENDENT';

export const REMOVE_SPOUSE = 'app/ProfilePage/REMOVE_SPOUSE';
export const REMOVE_DEPENDENT = 'app/ProfilePage/REMOVE_DEPENDENT';

/* Prescription section */
export const SEARCH_DRUGS_REQUEST = 'app/ProfilePage/SEARCH_DRUGS_REQUEST';
export const SEARCH_DRUGS_SUCCESS = 'app/ProfilePage/SEARCH_DRUGS_SUCCESS';
export const SEARCH_DRUGS_FAILURE = 'app/ProfilePage/SEARCH_DRUGS_FAILURE';
export const SANITIZE_PROFILE_INPUTS = 'app/ProfilePage/SANITIZE_PROFILE_INPUTS';
export const UPDATE_HOUSEHOLD_FROM_RESULT_PAGE = 'app/ProfilePage/UPDATE_HOUSEHOLD_FROM_RESULT_PAGE';

export const PROFILE_SECTIONS = [
  'enrollment',
  'eligibility',
  'location',
  'member',
  'pregnancy',
  'income',
  'riskAssessment',
  'capacityToPay',
  'incentives',
];

export const SurveyQuestionMap = {
  '3': 'plan_child_question',
  '5': 'risk_question_1',
  '6': 'risk_question_2',
  '9': 'capacity_to_pay',
};

export const PUT_HOUSEHOLD_MEMBER_CONFIG = 'app/ProfilePage/PUT_HOUSEHOLD_MEMBER_CONFIG';

// These question codes have been pre-defined by CustOps
export const STANDARD_EQ_TO_QUESTION_CODE = {
  salary: 'salary',
  eligible_pay: 'eligiblePay',
  full_time_part_time: 'fullTimeStatus',
  zipcode: 'postalCode',
  state: 'stateProvince',
};
