import { ContentfulEligibilitySection } from 'ContentfulDefaults/types/eligibility';

export const eligibility: {
  eligibility_section: ContentfulEligibilitySection;
} = {
  eligibility_section: {
    contentful_title: 'Eligibility Section (DEFAULT DO NOT CHANGE)',
    name: 'Eligibility',
    tooltips: {
      name: {
        text: 'This is the eligibility tooltip',
        title: 'Eligibility',
        underline: 'Eligibility',
      },
    },
    button_text: {
      employment: 'Select an Answer',
      survey_page: 'Continue',
      landing_page: "Let's Get Started",
    },
    dropdown_instruction: 'Select an Answer',
    mobile_nav_instruction:
      'Navigate back and forth by clicking on any of these items that are unlocked. Feel free to change your answers at any time.',
    service_options: {
      benefits: 'benefits',
      insurance: 'health insurance',
    },
    landing_headings: {
      mobile: 'Choosing your {x}, finally effortless!',
      desktop: 'Who knew choosing your {x} could be this simple?',
    },
    landing_description:
      'Picwell is here to help you determine which plan is the best fit for you and your family! This, however, is not an enrollment platform; we will direct you to your enrollment site at the end of our experience. You should fully review all of your benefits documents before enrolling. Your responses to these questions are anonymous.\n\n',
    landing_arrow: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/cartoon-arrow.svg',
    terms_and_policy_disclaimer: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'By clicking "Let\'s Get Started," you acknowledge that you understand this and agree to the ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'Terms of Service',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' and ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'Privacy Policy',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'code' }],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    terms_title: 'Terms and Conditions',
    terms_and_conditions: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'These terms of use (the "Terms of Use") are a binding agreement between you and Picwell, Inc., a Delaware corporation ("Picwell," "us," "our," or "we"), governing your access to and use of the Picwell plan selection and decision support service (the "Services"). We acknowledge and you acknowledge that these Terms of Use are between Picwell and you, and not with any other third party, including any mobile platform provider.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The Picwell Plan Selection and Decision Support Service uses algorithms designed to create health and financial assumptions for future health and health care needs. Results of the Services are based on estimates, assumptions, and projections, including, but not limited to estimated annual future costs, health care needs and coverage assumptions. Due to uncertainties necessarily inherent in relying upon such estimates, assumptions and projections, your actual medical costs and coverage may vary, perhaps materially, from the estimates provided by the Services. Results generated by the Services are predictions for informational purposes only, are not guaranteed, and should not be relied upon in making any current or future decision.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'PLEASE READ THE TERMS OF USE CAREFULLY BEFORE YOU ACCESS OR START USE OF THE SERVICES. BY ACCEPTING THESE TERMS OF USE, EITHER BY CLICKING TO ACCEPT OR AGREE OR BY DOWNLOADING, INSTALLING, OR USING AND/OR CONTINUING TO USE THE SERVICES, YOU ACKNOWLEDGE AND REPRESENT THAT (A) YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE, (B) YOU ARE 18 YEARS OF AGE OR OLDER, AND (C) YOU ACCEPT AND AGREE TO THE TERMS OF THESE TERMS OF USE WITHOUT LIMITATION OR QUALIFICATION.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '1. Access and License',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '1.1 Picwell grants you a non-exclusive, non-transferable, revocable worldwide right to use the Services, subject to these Terms of Use (the "License"). You grant to Picwell the non-exclusive, worldwide right to use, copy, store, transmit, and display Your Data solely to the extent necessary to provide the Services. All rights not expressly granted to you are reserved by Picwell and its licensors. For the purposes hereof, "Your Data" means any data or information of any kind provided or submitted by you to Picwell or entered electronically in the course of using the Services.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '1.2 You agree that you will not or attempt to: (i) duplicate, copy, sell, resell, commercially exploit for any purpose, disrupt or interferer with any portion of, use of, or access to the Services; (ii) use the Services for any purpose other than your personal education; (iii) directly or indirectly provide access to the Service to any other person; or (iv) violate any applicable law or regulation.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '1.3 You are responsible for your access and use of the Services. You will assure that your access and use of the Services and Your Data will comply at all times with all applicable law.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2. Intellectual Property Rights; Your Data; Copyright Infringement Notification Process',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.1 The Services are protected by intellectual property rights (the "Intellectual Property Rights"), and any unauthorized use of the Services may violate such Intellectual Property Rights, applicable laws and these Terms of Use. Picwell and its licensors own all right, title, and interest in and to the Intellectual Property Rights. These Terms of Use do not convey or transfer any ownership rights to you. If you make a suggestion to us to for any type of addition or other change to the Services (the "Feedback"), you agree that Picwell shall own the Feedback and you are not entitled to any money, credit, or other compensation. The trademarks, logos, and service marks displayed on the Services, including, without limitation, "Picwell," are the property of Picwell or other third parties. You are not permitted to use these marks without the prior written consent of Picwell or such third party that may own the marks. You may not remove or obscure any copyright notice or other proprietary notices contained with the Services.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "2.2 You, not Picwell, shall have sole responsibility of all Your Data, and Picwell shall not be responsible or liable for the deletion, correction, destruction, damage, loss, or failure to store any of Your Data. While using the Services, you agree that you will not upload, post, email, transmit, or otherwise make available any of Your Data that: (i) is unlawful or otherwise objectionable; (ii) creates a risk of harm, loss, or damage to any person or property; (iii) seeks to harm or exploit minors in any way; (iv) violates, or encourages any conduct that violates, applicable law; or (v) infringes any third party's rights. Upon termination for breach, your right to access or use Your Data immediately ceases, and Picwell shall have no obligation to maintain or forward any of Your Data.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.3 You understand that the technical processing and transmission of Your Data (including the possible transmission of Your Data outside its country of origin) may be necessary to your use of the Services and content offered on or through the Services, and consent to our interception and storage of Your Data. You understand that you or Picwell may be transmitting Your Data over the Internet, and over various networks, only part of which may be owned and operated by Picwell. You agree that Picwell is not responsible for any portions of Your Data that are lost, altered, intercepted, or stored without authorization during the transmission of Your Data across networks not owned and operated by Picwell.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.4 Picwell abides by the Federal Digital Millennium Copyright Act (the "DMCA"). If you believe that any content included on the Services is your proprietary work and has been copied in a way that constitutes an infringement of your copyrights in that work, please immediately notify Picwell of any such infringement. Please also note that, under the DMCA, any person who knowingly materially misrepresents that material or activity is infringing or was removed or disabled by mistake or misidentification may be subject to liability. Your written notice should be sent to our designated agent as follows:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'DMCA Complaints:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [{ type: 'italic' }],
              value:
                'Picwell, Inc.\nAttn: Legal Department\n1900 Market Street, Suite 120, Philadelphia, PA 19103',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [{ type: 'italic' }],
              value: 'Email: legal@picwell.com',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'All reports and inquiries will be kept confidential, except to the extent necessary to investigate any alleged violation and enforce the terms and conditions of these Terms of Use. Before sending either a copyright infringement notification or counter-notification, you may wish to contact a lawyer to better understand your rights and obligations under the DMCA and other applicable laws. The following notice requirements are intended to comply with our rights and obligations under the DMCA and do not constitute legal advice. Picwell will remove the infringing content, subject to the procedures outlined in the DMCA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'To file a copyright infringement notification with us, you will need to send a written communication that includes substantially the following (please consult your legal counsel or see 17 U.S.C. Section 512(c)(3) to confirm these requirements): (1) a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site; (3) identification of the material that is claimed to be infringing or to be the subject of infringing activity and information reasonably sufficient to permit the service provider to locate the material; (4) information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available, an email address; (5) a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (6) a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "If Your Data has been taken down, you may elect to send us a counter notice. To be effective, your counter notice must be a written communication provided to our designated agent that includes substantially the following (please consult your legal counsel or see 17 U.S.C. Section 512(g)(3) to confirm these requirements): (1) a physical or electronic signature of the subscriber; (2) identification of the material that has been removed or to which access has been disabled, and the location at which the material appeared before it was removed or access to it was disabled; (3) a statement under penalty of perjury that the subscriber has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and (4) the subscriber's name, address, and telephone number, and a statement that the subscriber consents to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if the subscriber's address is outside of the United States, for any judicial district in which the service provider may be found, and that the subscriber will accept service of process from the person who provided notification under subsection (c)(1)(C) or an agent of such person.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '3. Terms and Termination',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The term for your access to and use of the Services shall commence upon the date you accept these Terms of Use and shall continue for the time period as set forth by Picwell (in connection with the applicable underlying subscription of your benefits plan administrator, employer, etc.) (the "Term"). Once terminated, you shall cease any use of the Services. You acknowledge and agree that Picwell will not be liable to you or any third party in connection with any termination of your access to or use of the Services pursuant to the terms hereof. Sections 5 and 6 of these Terms of Use, and the obligations and restrictions placed on you hereunder, shall survive termination.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '4. Disclaimer of Warranties/Limitation of Liability',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '4.1 THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. AS SUCH, THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, PICWELL AND ITS SUBSIDIARIES, PARTNERS, AFFILIATES, OFFICERS, DIRECTORS, STOCKHOLDERS, MANAGERS, MEMBERS, EMPLOYEES, AGENTS, AND LICENSORS (COLLECTIVELY, THE "PICWELL PARTIES") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE PICWELL PARTIES DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR SERVERS THAT MAKE THE SERVICES AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL MEET YOUR APPLICABLE REQUIREMENTS.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '4.2 YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOADING OF ANY CONTENT OR DATA THROUGH THE SERVICES. WE RESERVE THE RIGHT TO RESTRICT OR TERMINATE YOUR ACCESS TO THE SERVICES OR ANY FEATURE OR PART THEREOF AT ANY TIME. THE PICWELL PARTIES ASSUME NO RESPONSIBILITY FOR THE DELETION OR MIS-DELIVERY OF, OR FAILURE TO STORE, ANY CONTENT, DATA, OR PERSONALIZATION SETTINGS.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "4.3 YOU UNDERSTAND AND AGREE THAT THE PICWELL PARTIES WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF A PICWELL PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM (i) YOUR USE OR ACCESS OF, OR INABILITY TO USE OR ACCESS, THE SERVICES; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICES; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; OR (iv) ANY OTHER MATTER RELATING TO THE SERVICES. IN NO EVENT SHALL PICWELL'S TOTAL CUMULATIVE LIABILITY EXCEED THE AMOUNT PAID BY (AND NOT OTHERWISE REFUNDED TO) YOU TO PICWELL FOR USE OF THE SERVICES DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR DAMAGES.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '5. Indemnification and Release',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "5.1 You agree to indemnify, defend, and hold harmless the Picwell Parties for, from, and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or relating to any violation of these Terms of Use by you or any other actions connected with your use of the Services. Your indemnification obligations include, without limitation, claims arising out of any of Your Data, as well as any claims arising out of acts or omissions by any other person or entity who gains access to the Services through your registration information, either with your permission or as a result of your failure to use reasonable security measures.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '5.2 Other than as set forth herein, Picwell is not directly involved in the actual transaction between you and your health and wellness benefits provider (the "Provider") using the Services. As we are not involved in the actual direct transaction between you and the Provider, in the event that you have a dispute with the Provider, you release the Picwell Parties from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. If you are a California resident, you waive any and all rights and benefits conferred by the provisions of Section 1542 of the Civil Code of the State of California, which provides as follows:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '\n\n',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '\n',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Modification of the Terms of Use',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when posted, or up to thirty (30) days after publication where required by law, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of changes to these Terms of Use means that you accept and agree to such changes. It is your responsibility to check these Terms of Use periodically for changes, as these changes are binding on you.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Injunctive Relief',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Your breach of these Terms of Use may result in immediate and irreparable harm to us, for which there may be no adequate remedy at law, and, therefore, you agree that we are entitled to equitable relief to compel you to cease and desist all unauthorized use, evaluation, and disclosure of information obtained through the Services, which is in addition to any other remedies available at law or in equity.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Governing Law',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'These Terms of Use shall be governed by the laws of the State of Delaware and controlling U.S. federal law. The Uniform Computer Information Transactions Act, the United Nations Convention on the International Sale of Goods, and choice of law rules of any jurisdiction will not apply to these Terms of Use. Any legal action or proceeding relating to these Terms of Use shall be instituted in a state or federal court in New Castle County, Delaware, and each party hereby submits to the exclusive personal jurisdiction of such courts and waives any defense relating to venue or forum non convenience.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'No Waiver Severability',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'A waiver of any breach of any provision of these Terms of Use is not a waiver of any repetition of such breach and will not in any manner affect any other terms or conditions of these Terms of Use. We do not waive any rights by the failure to enforce any provision of these Terms of Use in every instance in which it might apply. In the event that any provision of these Terms of Use is held to be unenforceable, it will not affect the validity or enforceability of the remaining provisions, and will be replaced by an enforceable provision that is the closest to the intention underlying the unenforceable provision.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Assignability',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We may assign our rights and delegate our duties under these Terms of Use, either in whole or in part, at any time without notice. You may not assign, sublicense, or otherwise transfer your rights or obligations, in whole or in part, under these Terms of Use to anyone else without our prior written consent.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Relationship',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'This Agreement does not establish any relationship of partnership, joint venture, employment, franchise, or agency between you and us.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-2',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    privacy_title: 'Privacy Policy',
    privacy_banner_card:
      'All data provided within ALEX is anonymous. For more info, please review our privacy policy.',
    privacy_policy: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Last Modified: November 8, 2019',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell, Inc., a Delaware corporation ("Picwell," "us," "our," or "we") respect your privacy and are committed to protecting it through our compliance with this Privacy Policy ("Policy").',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'This Policy describes the types of information we may collect from you or that you may provide when you use the Picwell benefit plan selection and decision support service (the “Service”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'This Policy applies to information we collect through the Service or in email, text, and other electronic messages between you and the Website. This Policy does not apply to information collected by us offline or through any other means, including on any other website operated by Picwell or any third party, or any third party, including through any application or content that may link to or be accessible from or through the Service.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Please read this Policy carefully to understand our policies and practices regarding your information and how we treat it. If you do not agree with our policies and practices, your choice is not to use the Service. By accessing or using the Service, you agree to this Policy. This Policy may change from time to time (see Section 8 below (Changes to Our Privacy Policy)). Your continued use of the Service after we make changes is deemed to be acceptance of those changes, so please check this Policy periodically for updates.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '1. Types of Information Collected',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We collect several types of information from and about users of the Service, including information: (i) in the aggregate that has been deidentified or anonymized; and (ii) about your internet connection, the equipment you use to access the Service, and usage details. We collect this information directly from you when you provide it to us or automatically as you navigate through the Service.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The information we collect on or through the Service may include information you provide by filling in forms on our website, records and copies of your correspondence if you contact us, details of the transaction you carry out through the Service, and your search queries through the Service. Information collected automatically may include usage details, internet protocol (IP) addresses, and information collected through cookies, web beacons, and other tracking technologies.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell, in connection with the Service, will never request sensitive or personally identifiable information such as a name, email or mailing address, social security number, driver’s license number, or a personal identification number from a bank or credit card.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '2. Children',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "In accordance with the Children's Online Privacy Protection Act of 1998 (COPPA), Picwell is committed to the privacy of all children. Our Service is not intended for children under 13 years of age. No one under age 13 may provide any information to this Service. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Service. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at legal@picwell.com.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '3. Information Collected Automatically',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'As you navigate through and interact with the Service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including details of your visits to the Service and information about your computer and internet connection. The information we collect automatically is only statistical data and does not include personal information. It helps us to improve the Service and to deliver a better and more personalized service, including by enabling us to: (i) estimate our audience size and usage patterns; (ii) store information about your preferences; (iii) assist in evaluating and improving the Service; and (iv) promote our services by using the resultant data on an aggregate and/or deidentified or anonymous basis.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The technologies we use for automatic information collection may include cookies, web beacons, and log files.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [{ type: 'italic' }],
              value: 'Cookies',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of the Service. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to the Service.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The Service uses tracking software to monitor its visitors provided by Google Analytics which uses cookies to track visitor usage. The software will save a cookie to your computer’s hard drive in order to track and monitor your engagement and usage of the Website, but will not store, save or collect personal information. You can read about how Google Analytics works at: ',
              nodeType: 'text',
            },
            {
              data: {
                uri: 'https://policies.google.com/technologies/partner-sites',
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'policies.google.com',
                  nodeType: 'text',
                },
              ],
              nodeType: 'hyperlink',
            },
            {
              data: {},
              marks: [],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [{ type: 'italic' }],
              value: 'Web Beacons',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Pages of the Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Picwell, for example, to count users who have visited those pages or opened an email and for other related website statistics.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [{ type: 'italic' }],
              value: 'Log Files',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Like many sites, this Website makes use of log files. These files merely log visitors to the site – usually a standard procedure for hosting companies and a part of hosting service analytics. The information inside the log files includes IP addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user’s movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [{ type: 'italic' }],
              value: 'Do Not Track Signals',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Do Not Track (DNT) signals are signals sent through a browser informing us that you do not want to be tracked. We do not assign any meaning to DNT signals as there is no consistent industry standard for compliance.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '4. Use and Disclosure of Information',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We use information that we collect about you or that you provide to us: (i) to present our Service and its contents to you; (ii) to provide you with information, products, or services that you request from us; (iii) to fulfill any other purpose for which you provide it; (iv) to carry out our obligations and enforce our rights arising from any contracts entered into between you and us; (v) in any other way we may describe when you provide the information; and (vi) for any other purposes with your consent.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '5. Your Personal Information',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We will not collect or hold any personally identifiable information from you in connection with your use of the Service.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '6. California Privacy Rights',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Under California Civil Code Section 1798.83, California residents may request certain information regarding our disclosure of personal information to third parties for direct marketing purposes. To make such a request, please send us an email atlegal@picwell.com or write us at: Picwell, Inc., Attn: Legal Department, 1900 Market Street, Suite 120, Philadelphia, PA 19103.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '7. Data Security',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The safety and security of your information also depends on you. Where we have given you (or where you have chosen to) access certain parts of the Service, you are responsible for keeping the information you enter confidential.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect Your Data (as defined in our Terms of Use), we cannot guarantee the security of Your Data transmitted to the Service. Any transmission of Your Data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained through the Service.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '8. Changes to Our Policy',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'It is our policy to post any changes we make to our Policy on this page. If we make material changes to how we treat Your Data, we will notify you through a notice through the Service home page. The date the Policy was last revised is at the top of the page. You are responsible for periodically visiting the Service and this privacy policy to check for any changes.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '9. Third-Party Links',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The Service may contain links to third-party owned and/or operated applications or websites. We are not responsible for the privacy practices or the content of these entities. These third-party entities have separate privacy and data collection practices and therefore, we have no responsibility or liability relating to them.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '10. Contact Us',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'If you have any questions or comments about this Policy, please email us at legal@picwell.com.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    landing_illustrations_links: [
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-1.svg',
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-3.svg',
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-2.svg',
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-4.svg',
    ],
    illustration_phrases: [
      'out on your lunch break.',
      'while staying limber.',
      'during the commercial break.',
      'while grabbing a latte.',
    ],
    survey_description:
      'Before we begin, please answer the following questions so that we can determine which plans you are eligible for.',
    ie_message:
      'We noticed you are using Internet Explorer. To provide you the best experience, we recommend using another browser (like Google Chrome, Firefox, or Safari). ',
    english_flag: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/usa-flag.svg',
    spanish_flag: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/spain-flag.png',
    survey_questions: {
      zip5: {
        key: 'zip5',
        heading: 'What is your 5 digit zip code?',
        placeholder: 'Zipcode',
      },
      is_full_time_or_part_time: {
        key: 'is_full_time_or_part_time',
        heading: 'Are you full-time or part-time?',
        options: {
          'Full-Time': 'Full-Time',
          'Part-Time': 'Part-Time',
        },
      },
      annual_policyholder_salary: {
        key: 'annual_policyholder_salary',
        heading: 'Enter annual salary',
        placeholder: '50,000',
      },
      is_california_resident: {
        key: 'is_california_resident',
        heading: 'Are you a resident of the Great State of California?',
        options: { No: 'No', Yes: 'Yes' },
      },
      test_prop_name: {
        key: 'test_prop_name',
        heading: 'More than 2 option Dropdown?',
        options: {
          'Full-Time': 'Full-Time',
          'Part-Time': 'Part-Time',
          optionA: 'optionA',
          optionB: 'optionB',
        },
      },
      question_with_clarification: {
        key: 'question_with_clarification',
        heading: "What's the best Backstreet Boys album?",
        clarification: 'Strictly pre-2001 only',
        options: {
          bsb: 'Backstreet Boys',
          back: "Backstreet's Back",
          millennium: 'Millennium',
          bnb: 'Black & Blue',
        },
      },
    },
    do_not_sell_my_info_text: 'Do Not Sell My Info',
  },
};
