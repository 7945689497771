import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';

import { GlobalReducerState } from 'app/reducers';
import { getFullAlexUrl, stripSourceComponent } from 'Containers/App/utils';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, ButtonOptions } from 'DesignLibrary/atoms';
import { sendInsightsEvent } from 'Utils/insights';
import { WELCOME_PATH } from 'Utils/urls';

export type BaseButtonProps = {
  children: ReactNode;
  returnToUrl?: string;
  getStore?: () => GlobalReducerState;
} & ButtonOptions;

export interface AuthButtonReduxProps {
  userId: string | undefined;
  ssoEnabled: boolean;
  ssoConnectionName: string;
  isProfileEmpty: boolean;
}

export interface AuthButtonProps {
  action: 'login' | 'signUp';
}

const AuthButton = ({
  children,
  action,
  getStore,
  userId,
  ssoEnabled,
  ssoConnectionName,
  isProfileEmpty,
  returnToUrl = window.location.pathname,
  ...rest
}: AuthButtonProps & BaseButtonProps & AuthButtonReduxProps) => {
  const { loginWithRedirect } = useAuth0();
  const { textMap } = useTextContext();
  const { profileProgressMap } = useProfileContext();
  const { locale } = useTextContext();

  const unvalidatedSegmentSlug = getStore?.().globalApp.unvalidatedSegmentSlug;
  const alexUrl = getFullAlexUrl(window.location, unvalidatedSegmentSlug);
  const authLocale = locale.slice(0, 2);
  const ssoOptions = ssoEnabled ? { connection: ssoConnectionName } : {};

  const handleLogin = (action: string) => {
    const storeOnRedirect = {
      ...getStore?.(),
    };

    window.onbeforeunload = null;

    if (window.location.pathname !== WELCOME_PATH) {
      localStorage.setItem('store', JSON.stringify(storeOnRedirect));
      localStorage.setItem('contentful', JSON.stringify(textMap));
      localStorage.setItem('profileMap', JSON.stringify(profileProgressMap));
    }

    const goGuestId = stripSourceComponent(userId || '');

    const redirectOptions = {
      appState: {
        returnTo: returnToUrl,
      },
      authorizationParams: {
        login_hint: action,
        alex_url: alexUrl,
        jv_guest_id: goGuestId && !isProfileEmpty ? goGuestId : undefined,
        ...ssoOptions,
        ui_locales: authLocale,
      },
    };

    sendInsightsEvent(null, `auth_${action.toLowerCase()}`);

    loginWithRedirect({
      ...redirectOptions,
    });
  };

  return (
    <Button onClick={() => handleLogin(action)} {...rest}>
      {children}
    </Button>
  );
};

export default AuthButton;
