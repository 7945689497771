import React, { useEffect } from 'react';

import { RegexOrHideChoicesValidatedTextInput } from 'Containers/ProfilePage/EligibilitySection/SurveyQuestion/RegexOrHideChoicesValidatedTextInput';
import { ClientSurveyQuestion } from 'Containers/ProfilePage/types';
import { SurveyOptions } from 'ContentfulDefaults/types/eligibility';
import { useTextContext } from 'Contexts/textContext';
import { Icon } from 'DesignLibrary/atoms';
import SelectInput from 'DesignLibrary/atoms/inputs/SelectInput';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import { useViewport } from 'DesignLibrary/context';

import { Subtitle } from './styled';
import { QuestionWrapper } from '../../_shared/Question/styled';

export interface SurveyQuestionProps {
  questionId: string;
  question: ClientSurveyQuestion;
  response: string | number | null;
  isInvalid?: boolean;
  handleAnswerChange: (value: string, property: string, isValid: boolean) => void;
  onBlur?: (key: string, value: string | number) => void;
}

const SurveyQuestion = ({
  questionId,
  question,
  response,
  isInvalid,
  handleAnswerChange,
  onBlur,
}: SurveyQuestionProps) => {
  const isHidden = question.enum ? question.enum.length === 1 : false;

  const { retrieveContentfulData } = useTextContext();
  const { device } = useViewport();

  const labelId = `${questionId}-label`;

  useEffect(() => {
    // the question.enum check is redundant but makes the compiler happy
    //   it is a smell that we're abusing types here, but that's just gonna
    //   happen as we're transitioning into TS
    if (isHidden && question.enum) {
      handleAnswerChange(question.enum[0], questionId, true);
    }
  }, [isHidden]);

  const renderQuestionContent = () => {
    // If option text is longer than 15 characters we want to render a select instead of a toggle
    // to prevent awkward text-wrapping in Toggle buttons
    const hasLongOptionText =
      question &&
      question.enum &&
      question.enum.length === 2 &&
      question.enum.some((option) => option.length > 15);

    const salaryQuestionIds = ['annual_policyholder_salary', 'salary', 'eligible_pay'];

    if (question.type === 'number') {
      const isPolicyholderSalaryQuestion = salaryQuestionIds.includes(questionId);
      return (
        <TextInput
          labelId={labelId}
          data-testid={`${questionId}_input`}
          inputType={isPolicyholderSalaryQuestion ? 'currency' : 'normal'}
          inputMode="numeric"
          stretch={false}
          placeholder={isPolicyholderSalaryQuestion ? '50,000' : '0'}
          iconType="Search"
          error={isInvalid}
          handleChange={(e) => handleAnswerChange(e.target.value, questionId, true)}
          onBlur={(e) => (onBlur ? onBlur(questionId, e.target.value) : null)}
          value={response?.toString()}
        />
      );
    }

    if (question.enum) {
      let selectOptions = question.enum.map((o: string) => ({
        option: o,
        value: o,
      }));

      const contentfulChoices = retrieveContentfulData<string[]>(
        `eligibility_section.survey_questions[${questionId}].choices`,
      );
      const contentfulOptions = retrieveContentfulData<SurveyOptions>(
        `eligibility_section.survey_questions[${questionId}].options`,
      );

      // Use "choices" array if available to preserve order
      if (contentfulChoices?.length && typeof contentfulChoices[0] === 'string') {
        selectOptions = contentfulChoices.map((choice) => ({
          option: choice,
          value: choice,
        }));
      } else if (contentfulOptions) {
        // Use legacy "options" object as fallback
        selectOptions =
          Object.entries(contentfulOptions).map(([key, val]) => ({
            option: val,
            value: key,
          })) || [];

        const shouldSortOptions = questionId === 'state' || questionId === 'state_work';
        if (shouldSortOptions) {
          selectOptions = selectOptions.sort((a, b) => a.value.localeCompare(b.value));
        }
      }

      if ((question.enum.length > 2 || hasLongOptionText) && !question?.hideChoices) {
        return (
          <SelectInput
            placeholder={retrieveContentfulData<string>('eligibility_section.dropdown_instruction')}
            selected={response?.toString()}
            options={selectOptions}
            labelId={labelId}
            handleChange={(e) => handleAnswerChange(e.target.value, questionId, true)}
          />
        );
      }
      if (!question?.hideChoices) {
        return (
          <ToggleList
            layout="row"
            className={`select-${questionId} ${
              questionId === 'is_full_time_or_part_time' ? 'pingdom-full-time-or-part-time' : ''
            }`}
            groupName={questionId}
            label={question.description}
            options={selectOptions}
            required
            color="--colors-gray-black"
            handleChange={(e) => handleAnswerChange(e.target.value, questionId, true)}
            selected={response?.toString() || ''}
            stretch={device === 'mobile'}
          />
        );
      }
    }

    if (question?.pattern || question?.hideChoices) {
      return (
        <RegexOrHideChoicesValidatedTextInput
          questionId={questionId}
          labelId={labelId}
          question={question}
          handleAnswerChange={handleAnswerChange}
          response={response as string}
          errorMessageField="profile_section.error_text.invalidEligibilityInput"
        />
      );
    }
    return null;
  };

  if (isHidden) {
    return null;
  }

  const contentfulQuestion = retrieveContentfulData<Record<string, never>>(
    `eligibility_section.survey_questions.${questionId}`,
  );

  return (
    <QuestionWrapper className="question-wrapper">
      <Paragraph id={labelId}>
        {contentfulQuestion && contentfulQuestion.heading ? (
          <>
            {contentfulQuestion.heading}
            {contentfulQuestion.clarification && (
              <Subtitle>
                <Icon type="Info" weight="fill" size="small" color="--text-gray" />
                {contentfulQuestion.clarification}
              </Subtitle>
            )}
          </>
        ) : (
          question.description
        )}
      </Paragraph>
      {renderQuestionContent()}
    </QuestionWrapper>
  );
};

export default SurveyQuestion;
