import _, { merge } from 'lodash';

import { Benefits, Items, SupplementalPlan } from 'Types/entities';

const MEMBER_IDS_KEY_SEPARATOR = '/';

export function memberIdsListToMemberIdsKey(membersIds: string[]): string {
  return membersIds.sort().join(MEMBER_IDS_KEY_SEPARATOR);
}

// Builds a obj of the items, with item.data as the (unique) keys to the obj.
const itemsToObj = (items: Items[]): object => {
  const obj = {};

  items?.forEach((it) => {
    obj[it.item.data] = it;
  });

  return obj;
};

// Merges two lists of items, creating a new (copied) list.
// uniqueness of keys (item.data) is guaranteed, and
// overrides takes priority of defaults in a conflict.
// Order is not preserved.
export const mergeItems = (defaults: Items[], overrides: Items[]): Items[] => {
  if (overrides === undefined) {
    return defaults;
  }

  const mergedItems = merge({}, itemsToObj(defaults), itemsToObj(overrides));
  return _.values(mergedItems);
};

// Returns merge of two Benefit objects, with all items unique.
export const mergeBenefits = (defaults: Benefits, overrides: Benefits): Benefits => {
  if (overrides === undefined) {
    return defaults;
  }

  const itemized_overrides = mergeItems(defaults.items, overrides.items);

  return merge({}, defaults, itemized_overrides);
};

// Cleans up supplemental plan benefit items, such as converting "true"/"false" strings to boolean.
export const transformBenefitItems = (plan: SupplementalPlan) => {
  const cleanItems = {};

  plan?.benefits?.items.forEach((item) => {
    const [value, key] = Object.values(item);

    cleanItems[key.data] = ['true', 'false'].includes(value.data) ? value.data === 'true' : value.data;
  });

  return cleanItems;
};
