import { get, isEmpty, merge, omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { GO_GUEST_ID } from 'Containers/App/constants';
import { makeSelectConfigField, makeSelectJvpField } from 'Containers/App/selectors';
import { getCookie } from 'Containers/App/utils';
import { PROFILE_SECTIONS } from 'Containers/ProfilePage/constants';
import {
  makeGetSelectedEnrollmentEventId,
  makeGetCanBearChild,
  makeGetIntegratedUserPublicationKey,
  makeGetSelectedPublicationKey,
  makeSelectProfileField,
  makeSelectShowClientSurvey,
  makeSelectSurvey,
} from 'Containers/ProfilePage/selectors';
import { IncentiveSurveyQuestion, ProfileSection, Survey } from 'Containers/ProfilePage/types';
import { JVPBootstrapPublicationInfo } from 'Utils/apiTypes';
import { checkFormIsComplete } from 'Utils/context';
import { sendInsightsEvent } from 'Utils/insights';

import { useFeatureFlagContext } from './featureFlagContext';

export interface ProfileContextType {
  profileProgressMap: ProgressMap;
  completedSections?: ProfileSection[];
  shouldUpdateIncentives: boolean;
  getSectionFromMap: (key: ProfileSection) => ProgressSection | null;
  getIsUnlocked: (key: ProfileSection) => boolean;
  getIsDirty: (key: ProfileSection) => boolean;
  getIsFormValid: () => boolean;
  getIsFormCompleted: () => boolean;
  handleUpdateProgress: ({
    key,
    isValid,
    isDirty,
    isComplete,
  }: {
    key: ProfileSection;
    isValid: boolean;
    isDirty: boolean;
    isComplete: boolean;
  }) => void;
  handleSectionChange: ({
    key,
    isValid,
    isComplete,
  }: {
    key: ProfileSection;
    isValid: boolean;
    isComplete: boolean;
  }) => void;
  handleHiddenEligibility: () => void;
  setShouldUpdateIncentives: (val: boolean) => void;
  getIsIncentivesValuesValid: () => boolean;
  selectedPublicationInfo: JVPBootstrapPublicationInfo | undefined;
}

export const useProfileContext = () => useContext(ProfileContext);

export const ProfileContext = React.createContext<ProfileContextType>({
  profileProgressMap: {},
  completedSections: [],
  shouldUpdateIncentives: false,
  selectedPublicationInfo: undefined,
  getSectionFromMap: () => null,
  getIsUnlocked: () => false,
  getIsDirty: () => false,
  getIsFormValid: () => false,
  getIsFormCompleted: () => false,
  handleUpdateProgress: () => ({}),
  handleSectionChange: () => null,
  handleHiddenEligibility: () => null,
  setShouldUpdateIncentives: () => null,
  getIsIncentivesValuesValid: () => false,
});

export interface ProgressSection {
  isUnlocked: boolean;
  isValid: boolean;
  isDirty: boolean;
  isDisabled: boolean;
  isComplete: boolean;
}

export type ProgressMap = Record<ProfileSection, ProgressSection> | Record<string, never>;

interface ProfileProviderParentProps {
  children: JSX.Element;
  progressMap?: ProgressMap; // Currently only used for passing in testing overrides
  completed?: ProfileSection[];
  isIntegrationPreFillEnabled: boolean;
}

interface ProfileProviderStoreProps {
  showClientSurvey: boolean;
  canBearChild: boolean;
  hidePregnancyQuestion: boolean;
  survey: Survey;
  activePublicationInfo: JVPBootstrapPublicationInfo | null;
  upcomingPublicationInfo: JVPBootstrapPublicationInfo | null;
  incentiveSurvey: IncentiveSurveyQuestion[];
  selectedPublicationKey: 'active' | 'upcoming';
  incentiveSurveyAPICalled: boolean;
  enrollmentEventId?: string;
  integratedUserPublicationKey: 'active' | 'upcoming' | null;
}

export type ProfileProviderProps = ProfileProviderParentProps & ProfileProviderStoreProps;

export const ProfileProvider = ({
  canBearChild,
  hidePregnancyQuestion,
  showClientSurvey,
  survey,
  children,
  progressMap = {},
  completed = [],
  isIntegrationPreFillEnabled,
  activePublicationInfo,
  upcomingPublicationInfo,
  incentiveSurvey = [],
  selectedPublicationKey,
  incentiveSurveyAPICalled,
  enrollmentEventId,
  integratedUserPublicationKey,
}: ProfileProviderProps) => {
  const [profileProgressMap, setProfileProgressMap] = useState<ProgressMap>(progressMap);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [completedSections, setCompletedSections] = useState<ProfileSection[]>(completed);
  const [showEnrollment, setShowEnrollment] = useState(false);
  const [showIncentives, setShowIncentives] = useState(false);

  // pass boolean to profilePage via hook, handle update in useEffect on profilePage listening for changes to this boolean
  const [shouldUpdateIncentives, setShouldUpdateIncentives] = useState(false);

  const { is_integration_pre_fill_enabled } = useFeatureFlagContext();

  const [publicationInfo, setPublicationInfo] = useState<JVPBootstrapPublicationInfo | undefined>(undefined);

  useEffect(() => {
    // Hide enrollment question if:
    //  1. There is no active publication, or
    //  2. we've determined the publication from the AAPI user
    setShowEnrollment(!!activePublicationInfo && !integratedUserPublicationKey);
  }, [activePublicationInfo, upcomingPublicationInfo]);

  useEffect(() => {
    if (selectedPublicationKey === 'active' && activePublicationInfo) {
      setPublicationInfo(activePublicationInfo);
    }

    if (selectedPublicationKey === 'upcoming' && upcomingPublicationInfo) {
      setPublicationInfo(upcomingPublicationInfo);
    }
  }, [selectedPublicationKey, activePublicationInfo, upcomingPublicationInfo]);

  useEffect(() => {
    if (incentiveSurvey.length > 0) {
      setShowIncentives(true);
    } else {
      setShowIncentives(false);
    }
  }, [incentiveSurvey]);

  useEffect(() => {
    const initialState = PROFILE_SECTIONS.reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          // Update to show eligibility as initial state if enrollment is disabled
          isUnlocked: showEnrollment ? key === 'enrollment' : key === 'eligibility',
          isValid: false,
          isDirty: false,
          isDisabled: checkDisabled(key as ProfileSection),
          isComplete: false,
        },
      }),
      {},
    ) as ProgressMap;

    setProfileProgressMap(initialState);
  }, [showEnrollment]);

  const profileCompleteInsightsEvent = () => {
    const goGuestId = getCookie(GO_GUEST_ID);
    sendInsightsEvent(null, 'profile_complete', { go_guest_id: goGuestId });
    setIsFormComplete(true);
  };

  useEffect(() => {
    // empty check to prevent this logic from running on first tick, before content is initialized
    if (!isEmpty(profileProgressMap)) {
      // Update continue btn state when all sections are valid for the first time
      if (
        !isFormComplete &&
        ((incentiveSurveyAPICalled && getIsFormValid()) ||
          checkFormIsComplete(enrollmentEventId, profileProgressMap))
      ) {
        profileCompleteInsightsEvent();
      }

      // If client survey disabled, set eligibility to valid
      if (!showClientSurvey && !profileProgressMap.eligibility.isDisabled) {
        handleHiddenEligibility();
      }
    }
  }, [profileProgressMap]);

  useEffect(() => {
    // empty check to prevent this logic from running on first tick, before content is initialized
    if (!isEmpty(profileProgressMap)) {
      // Update continue btn state when all sections are valid for the first time
      if (incentiveSurveyAPICalled && incentiveSurvey?.length === 0) {
        profileCompleteInsightsEvent();
      }

      // If client survey disabled, set eligibility to valid
      if (!showClientSurvey && !profileProgressMap.eligibility.isDisabled) {
        handleHiddenEligibility();
      }
    }
  }, [incentiveSurveyAPICalled]);

  const checkDisabled = (section: ProfileSection) => {
    if (section === 'enrollment' && !showEnrollment) {
      return true;
    }
    if (section === 'eligibility' && !showClientSurvey) {
      return true;
    }
    if (section === 'pregnancy' && (hidePregnancyQuestion || !canBearChild)) {
      return true;
    }
    if (section === 'incentives' && !showIncentives) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!checkDisabled('incentives') && profileProgressMap.incentives.isDisabled) {
      const tempProgressMap = { ...profileProgressMap };
      tempProgressMap.incentives.isDisabled = false;
      setProfileProgressMap(tempProgressMap);
    }
  }, [checkDisabled('incentives')]);

  const getSectionFromMap = (key: ProfileSection): ProgressSection => get(profileProgressMap, key);

  const getIsUnlocked = (key: ProfileSection): boolean => getSectionFromMap(key)?.isUnlocked || false;

  const getIsDirty = (key: ProfileSection): boolean => getSectionFromMap(key)?.isDirty || false;

  // return true if every enabled section is valid
  const getIsFormValid = () =>
    Object.values(profileProgressMap).every((section) => section.isValid || section.isDisabled);

  const getIsIncentivesValuesValid = () => {
    // has a field been changed and are all fields valid?
    const progressMapWithoutIncentives = Object.values(
      omit(profileProgressMap, ['incentives', 'enrollment']),
    );

    const isAllFieldsValid = progressMapWithoutIncentives.every((i) => i.isValid || i.isDisabled);

    return isAllFieldsValid;
  };

  const getIsFormCompleted = () => isFormComplete;

  // when eligibility section is hidden either due to !showClientSurvey or client survey includes either only a dropdown with one option
  // or just the zipcode, set eligibility to valid and disabled.
  const handleHiddenEligibility = () => {
    handleUpdateProgress({
      key: 'eligibility',
      isValid: true,
      isDirty: false,
      isComplete: true,
    });
  };

  // called when inputs for a section is changed
  const handleSectionChange = async ({
    key,
    isValid,
    isComplete,
  }: {
    key: ProfileSection;
    isValid: boolean;
    isComplete: boolean;
  }) => {
    if (!isEmpty(profileProgressMap)) {
      if (isValid) {
        handleUpdateProgress({
          key,
          isValid: true,
          isDirty: true,
          isComplete,
        });
      } else if (profileProgressMap[key].isValid) {
        // user action making section valid -> invalid
        handleUpdateProgress({
          key,
          isValid: false,
          isDirty: true,
          isComplete,
        });
      }
    }
  };

  // Determines if we should fire an additional /incentives request,
  // fired when changes to profile are made after initially completing all required fields
  useEffect(() => {
    if (!isEmpty(profileProgressMap)) {
      // is profile complete up to incentives card?
      if (profileProgressMap.incentives.isUnlocked) {
        // has a field been changed and are all fields valid?
        const progressMapWithoutIncentives = Object.values(
          omit(profileProgressMap, ['incentives', 'enrollment']),
        );

        const isAnyFieldDirty = progressMapWithoutIncentives.some((i) => i.isDirty);
        const isAllFieldsValid = progressMapWithoutIncentives.every((i) => i.isValid || i.isDisabled);

        const shouldUpdate = isAnyFieldDirty && isAllFieldsValid;

        // Update progressMap so all sections !isDirty, update condition that triggers incentives request
        if (shouldUpdate && !shouldUpdateIncentives) {
          const newProgressMap = { ...profileProgressMap };
          Object.keys(newProgressMap).forEach((key) => {
            if (key !== 'incentives') {
              newProgressMap[key].isDirty = false;
            }
          });
          setProfileProgressMap(newProgressMap);

          // causes an incentives request to fire in ProfilePage/index
          setShouldUpdateIncentives(true);
        }
      }
    }
  }, [profileProgressMap]);

  // determines if a section will be displayed
  const checkUnlocked = (section: ProfileSection, tempProgressMap: ProgressMap) => {
    let isUnlocked = false;
    if (isEmpty(tempProgressMap)) {
      return false;
    }
    switch (section) {
      case 'enrollment': {
        isUnlocked = showEnrollment;
        break;
      }
      case 'eligibility': {
        isUnlocked =
          (!showEnrollment && showClientSurvey) ||
          (showEnrollment && (tempProgressMap.enrollment.isValid || tempProgressMap.eligibility.isDirty));
        break;
      }
      case 'location': {
        isUnlocked = tempProgressMap.eligibility.isUnlocked ? tempProgressMap.eligibility.isComplete : true;
        break;
      }
      case 'member': {
        isUnlocked = tempProgressMap.location.isValid || tempProgressMap.member.isDirty;
        break;
      }
      case 'pregnancy': {
        isUnlocked =
          canBearChild &&
          (tempProgressMap.member.isValid || tempProgressMap.pregnancy.isDirty) &&
          !hidePregnancyQuestion;
        break;
      }
      case 'income': {
        if (canBearChild) {
          if (tempProgressMap.pregnancy.isValid || hidePregnancyQuestion || tempProgressMap.income.isDirty) {
            isUnlocked = true;
          }
        } else if (tempProgressMap.member.isValid || tempProgressMap.income.isDirty) {
          isUnlocked = true;
        }
        break;
      }
      case 'riskAssessment': {
        isUnlocked = tempProgressMap.income.isValid || tempProgressMap.riskAssessment.isDirty;
        break;
      }
      case 'capacityToPay': {
        isUnlocked = tempProgressMap.riskAssessment.isValid || tempProgressMap.capacityToPay.isDirty;
        break;
      }
      case 'incentives': {
        const { pregnancy, capacityToPay, incentives } = tempProgressMap;
        isUnlocked =
          (pregnancy.isComplete || !pregnancy.isUnlocked) && (capacityToPay.isValid || incentives.isDirty);
        break;
      }
      default: {
        break;
      }
    }
    return isUnlocked;
  };

  // Update isDisabled for pregnancy section based on changes to canBearChild.
  useEffect(() => {
    // APP-1345:
    // Disabling so we don't have the prefill issue with Conduent
    if (!is_integration_pre_fill_enabled) {
      const progressMapIsReady = !isEmpty(profileProgressMap);

      /**
       * Checking profileProgressMap.member.isComplete because if this block
       * is invoked too early, we'll wind up overwriting some necessary data in
       * the progress map. This is something that needs rework.
       */
      const readyToUpdate = !!profileProgressMap?.member?.isComplete;

      // Some customer configs disallow this section from the UX.
      const pregnancyQuestionEnabled = !hidePregnancyQuestion;

      const shouldUpdateProgress = isIntegrationPreFillEnabled
        ? progressMapIsReady && pregnancyQuestionEnabled && readyToUpdate
        : progressMapIsReady && pregnancyQuestionEnabled;

      if (shouldUpdateProgress) {
        const isValidAndComplete = survey.plan_child_question !== '';
        handleUpdateProgress({
          key: 'pregnancy',
          isValid: isValidAndComplete,
          isDirty: true,
          isComplete: isValidAndComplete,
        });
      }
    }
  }, [canBearChild, is_integration_pre_fill_enabled]);

  // update profileProgressMap after running validation on a section
  const handleUpdateProgress = ({
    key,
    isValid,
    isDirty,
    isComplete,
  }: {
    key: ProfileSection;
    isValid: boolean;
    isDirty: boolean;
    isComplete: boolean;
  }) => {
    // todo maybe use this to play audio/CC for given section?
    const sections = Object.keys(profileProgressMap) as ProfileSection[];
    const newProgressMap: ProgressMap = sections.reduce((acc, section: ProfileSection) => {
      const tempProgressMap = merge({}, profileProgressMap, acc);

      let isUnlocked = checkUnlocked(section, tempProgressMap);
      const isDisabled = checkDisabled(section);

      if (section === 'incentives' && key === 'member' && !isValid) {
        isUnlocked = false;
      }

      // unlocked/disabled for all sections, valid/dirty/complete for the section that invoked this function
      const updates =
        section === key
          ? { isValid, isDirty, isComplete, isUnlocked, isDisabled }
          : { isUnlocked, isDisabled };

      // for insights
      const isSectionCompleteForFirstTime = section === key && !completedSections.includes(key) && isComplete;
      if (isSectionCompleteForFirstTime) {
        setCompletedSections([...completedSections, key]);
      }
      return {
        ...tempProgressMap,
        [section]: {
          ...tempProgressMap[section],
          ...updates,
        },
      };
    }, {});
    setProfileProgressMap(newProgressMap);
  };

  return (
    <ProfileContext.Provider
      value={{
        profileProgressMap,
        completedSections,
        shouldUpdateIncentives,
        getSectionFromMap,
        getIsUnlocked,
        getIsDirty,
        getIsFormValid,
        getIsFormCompleted,
        // TODO: Make this a private method.  We only want context updates to be handled with handleSectionChange
        handleUpdateProgress,
        handleSectionChange,
        handleHiddenEligibility,
        setShouldUpdateIncentives,
        getIsIncentivesValuesValid,
        selectedPublicationInfo: publicationInfo,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, ProfileProviderStoreProps>({
  canBearChild: makeGetCanBearChild(),
  hidePregnancyQuestion: makeSelectConfigField('hide_pregnancy_question'),
  activePublicationInfo: makeSelectJvpField('active'),
  upcomingPublicationInfo: makeSelectJvpField('upcoming'),
  showClientSurvey: makeSelectShowClientSurvey(),
  survey: makeSelectSurvey(),
  incentiveSurvey: makeSelectProfileField('incentiveSurvey'),
  selectedPublicationKey: makeGetSelectedPublicationKey(),
  incentiveSurveyAPICalled: makeSelectProfileField('incentiveSurveyAPICalled'),
  enrollmentEventId: makeGetSelectedEnrollmentEventId(),
  integratedUserPublicationKey: makeGetIntegratedUserPublicationKey(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(ProfileProvider);
