import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC } from 'react';

import { TextField } from 'Containers/App/types';
import { ContentfulCustomMessage } from 'ContentfulDefaults/types/_shared';
import { ProfileAudio } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp, Icon, Tooltip } from 'DesignLibrary/atoms/';
import { AudioTooltip } from 'DesignLibrary/atoms/AudioTooltip';
import { H6 } from 'DesignLibrary/atoms/typography';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';

import { QuestionContainer, QuestionContent, SideTab } from './styled';

type ProfileCardNames =
  | 'enrollment'
  | 'eligibility'
  | 'location'
  | 'member'
  | 'pregnancy'
  | 'risk'
  | 'income'
  | 'capacity_to_pay'
  | 'incentives';

export interface QuestionProps {
  error?: boolean;
  isIncomplete?: boolean;
  name: ProfileCardNames;
  audioTooltipsEnabled?: boolean;
  audioTooltipContentfulPath?: TextField;
}

const Question: FC<QuestionProps> = ({
  error = false,
  name,
  children,
  isIncomplete,
  audioTooltipsEnabled = false,
  audioTooltipContentfulPath,
}) => {
  const { retrieveContentfulData } = useTextContext();
  const shouldUseAudioTooltip: boolean = !!(audioTooltipsEnabled && audioTooltipContentfulPath);

  const audioConfig = retrieveContentfulData<ProfileAudio>(audioTooltipContentfulPath, {} as ProfileAudio);

  interface SharedProfileCardProps {
    name: ProfileCardNames;
    tooltips: {
      name: string;
    };
    custom_message?: ContentfulCustomMessage;
  }

  const getProfileCard = () => {
    const fallbackProps = {
      name: 'missing',
      tooltips: {
        name: 'missing tooltip',
      },
      custom_message: null,
    };

    return retrieveContentfulData<SharedProfileCardProps>(`profile_section.${name}`) || fallbackProps;
  };

  const profileCard = getProfileCard();
  const cardName = profileCard.name;
  const tooltipLabel = profileCard.tooltips.name;
  const customMessage = profileCard.custom_message;
  const tooltipId = `tooltip-${_.kebabCase(cardName)}`;
  const audioTooltipId = `audio-${tooltipId}`;

  return (
    <FadeUp isOpen>
      <QuestionContainer error={error} id={name} data-testid="question-card">
        {shouldUseAudioTooltip && (
          <Box pos="absolute" top={4} left={['initial', -5]} right={[4, 'initial']}>
            <AudioTooltip
              data-testid={audioTooltipId}
              urlToAudio={audioConfig.audio_file_url}
              urlToClosedCaptionVtt={audioConfig.vtt_transcript_file_url}
            />
          </Box>
        )}

        <SideTab incomplete={isIncomplete} error={error}>
          <div className="title">
            <H6 as="h2" color="white">
              {profileCard.name}
            </H6>
          </div>

          {!shouldUseAudioTooltip && (
            <Tooltip id={tooltipId} data-testid={tooltipId} label={tooltipLabel}>
              <div className="icon">
                <Icon type="Question" color="--text-black" />
              </div>
            </Tooltip>
          )}
        </SideTab>
        <QuestionContent>
          {customMessage && (
            <InfoBar
              className="client-message"
              text={<RichText field={`profile_section.${name}.custom_message.text` as TextField} />}
              color={customMessage.color}
              header={customMessage.header}
              stretch
              icon={customMessage.icon}
              buttonText={customMessage.url_button_text}
              url={customMessage.url}
            />
          )}
          {children}
        </QuestionContent>
      </QuestionContainer>
    </FadeUp>
  );
};

export default Question;
