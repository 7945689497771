import { fromJS } from 'immutable';

import { parseJvpUserId } from 'Containers/App/utils';
import { GET_RECOMMENDATIONS_SUCCESS } from 'Containers/CommercialRoutes/constants';
import {
  CHANGE_FORM_VALUE,
  CHANGE_SURVEY_VALUE,
  EDIT_DEPENDENT,
  EDIT_POLICYHOLDER,
  EDIT_SPOUSE,
  REMOVE_DEPENDENT,
  REMOVE_SPOUSE,
  RESET_FORM_DIRTY_STATE,
  SEARCH_LOCATIONS_REQUEST,
  SUBMIT_CLIENT_SURVEY,
  SUBMIT_INCENTIVE_SURVEY,
} from 'Containers/ProfilePage/constants';
import { PAY_PERIOD_KEYS, Recommendation } from 'Types/entities';
import {
  ClientConfig,
  EnrollmentEventType,
  GetConfigResponse,
  JVPUserWithDependents,
  PreviousSelectedPlan,
} from 'Utils/apiTypes';
import { SplitFeatureFlagDefaults } from 'Utils/featureFlags';
import { TypedMap } from 'Utils/immutable-types';
import { sendInsightsEvent, updateInsightsDefaults } from 'Utils/insights';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';
import { PROFILE_PATH } from 'Utils/urls';

import {
  CHANGE_STEP,
  CHOOSE_PRODUCT,
  GET_AAPI_USER_FAILURE,
  GET_AAPI_USER_REQUEST,
  GET_AAPI_USER_SUCCESS,
  GET_JVP_BOOTSTRAP,
  GET_JVP_BOOTSTRAP_FAILURE,
  GET_JVP_BOOTSTRAP_SUCCESS,
  GET_JVP_USER_FAILURE,
  GET_JVP_USER_SUCCESS,
  HANDLE_PROFILE_COMPLETE,
  HANDLE_UNLOCK_MAIN_NAV,
  HANDLE_UNLOCK_SUB_NAV,
  LOAD_CONFIG,
  LOAD_CONFIG_FAILURE,
  LOAD_CONFIG_SUCCESS,
  SET_ALEX_ID_UUID,
  SET_BC_URL_HASH,
  SET_DEMO_MODE,
  SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_FAILURE,
  SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_SUCCESS,
  SET_ENROLLMENT_EVENT_TYPE_FAILURE,
  SET_ENROLLMENT_EVENT_TYPE_SUCCESS,
  SET_JVP_SESSION_ID,
  SET_PAGE_VIEWED,
  SET_UNVALIDATED_SEGMENT_SLUG,
  SET_VALIDATED_SEGMENT_SLUG,
  UPDATE_PREVIOUS_SELECTED_PLAN,
} from './constants';
import { AlexIdUser, AlexProducts, GetJVPBootstrapSuccessAction, JVPState } from './types';

export interface GlobalAppReducerState {
  isLoading: boolean;
  error: Error | null;
  path: string;
  pageProps:
    | {
        [path: string]: {
          viewed: boolean;
        };
      }
    | Record<string, never>;
  config: ClientConfig;
  isConfigLoaded: boolean;
  isDirty: boolean;
  unlockedMainPath: string;
  unlockedSubPath: string;
  selectedProduct: AlexProducts | '';
  jvp: JVPState;
  jvpUser: JVPUserWithDependents | null;
  user?: AlexIdUser;
  isDemoMode: boolean;
  unvalidatedSegmentSlug: string;
  validatedSegmentSlug: string | null;
  segmentSlugProcessed: boolean;
  bcUrlHash: string;
  isFetchingAAPIUser?: boolean;
  hasUnsavedProfileChanges: boolean;
  hsaContributionLimitIndividual: number;
  hsaContributionLimitFamily: number;
  previousSelectedPlan: PreviousSelectedPlan[] | [];
  selectedHealthPlan: Recommendation | boolean | Record<string, never>;
}

type GlobalState = TypedMap<GlobalAppReducerState>;

export const initialJvpState: JVPState = {
  isLoading: false,
  userId: '',
  isReturnUser: null,
  employerId: '',
  employerName: '',
  employeeId: '',
  active: null,
  upcoming: null,
  sessionId: '',
  eventType: 'none',
  profileComplete: false,
};

const initialState: GlobalState = fromJS({
  isLoading: false,
  error: null,
  path: '',
  pageProps: {},
  config: {
    client: '',
    // Default feature flags
    is_fbs_enabled: false,
    show_language_toggle: false,
    hide_plan_score: false,
    show_carrier_friendly_score: false,
    is_call_center: false,
    is_integrated: false,
    can_waive_coverage: false,
    collect_tobacco_usage: false,
    collect_tax_inputs: false,
    can_change_pay_period: true,
    default_pay_period: PAY_PERIOD_KEYS.MONTHLY,
    fbs_groups: null,
    hsa_plus_enabled: false,
    is_ply_enabled: false,
    is_ply_modal_enabled: false,
    hsa_forecast_enabled: false,
    provider_search_links: null,
    catchup_contribution: 1000,
    is_plan_comparison_enabled: false,
    is_marketplace: false,
    prepopulate_profile: false,
    disable_household_form: false,
    show_detail_fbs_cards: false,
    is_under_construction: false,
    useContentfulV2: true,
    show_enrollee_select_disclaimer: false,
    split_feature_flags: SplitFeatureFlagDefaults,
  },
  selectedProduct: '',
  isConfigLoaded: false,
  isDirty: true, // used in CommercialV2 to flag whether to resubmit the form or not
  unlockedMainPath: PROFILE_PATH,
  unlockedSubPath: PROFILE_PATH,
  jvp: initialJvpState,
  user: {},
  isDemoMode: false,
  unvalidatedSegmentSlug: '',
  validatedSegmentSlug: '',
  segmentSlugProcessed: false,
  bcUrlHash: '',
  isFetchingAAPIUser: undefined,
  hasUnsavedProfileChanges: false,
  hsaContributionLimitIndividual: 4150,
  hsaContributionLimitFamily: 8300,
});

function globalAppReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_STEP:
      return changeStep(state, action);
    case LOAD_CONFIG:
      return loadConfig(state);
    case LOAD_CONFIG_SUCCESS:
      return loadConfigSuccess(state, action);
    case LOAD_CONFIG_FAILURE:
      return loadConfigFailure(state, action);
    case SEARCH_LOCATIONS_REQUEST:
    case EDIT_POLICYHOLDER:
    case EDIT_SPOUSE:
    case EDIT_DEPENDENT:
    case CHANGE_FORM_VALUE:
    case REMOVE_SPOUSE:
    case REMOVE_DEPENDENT:
    case CHANGE_SURVEY_VALUE:
    case SUBMIT_CLIENT_SURVEY:
    case RESET_FORM_DIRTY_STATE:
      return changeForm(state);
    case SUBMIT_INCENTIVE_SURVEY:
      return changeIncentives(state);
    case GET_RECOMMENDATIONS_SUCCESS:
      return getRecommendationSuccess(state);
    case HANDLE_UNLOCK_MAIN_NAV:
      return handleUnlockMainNav(state, action);
    case HANDLE_UNLOCK_SUB_NAV:
      return handleUnlockSubNav(state, action);
    case SET_PAGE_VIEWED:
      return setPageViewed(state, action);
    case CHOOSE_PRODUCT:
      return setSelectedProduct(state, action);
    // JVP
    case GET_JVP_BOOTSTRAP:
      return getJVPBootstrapRequest(state);
    case GET_JVP_BOOTSTRAP_SUCCESS:
      return getJVPBootstrapSuccess(state, action);
    case GET_JVP_BOOTSTRAP_FAILURE:
      return getJVPBootstrapFailure(state, action);
    case GET_JVP_USER_SUCCESS:
      return getJVPUserSuccess(state, action);
    case GET_JVP_USER_FAILURE:
      return getJVPUserError(state, action);
    case SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_SUCCESS:
      return setEnrollmentEventProfileCompleteSuccess(state, action);
    case SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_FAILURE:
      return setEnrollmentEventProfileCompleteFailure(state, action);
    case SET_ENROLLMENT_EVENT_TYPE_SUCCESS:
      return setEnrollmentEventTypeSuccess(state, action);
    case SET_ENROLLMENT_EVENT_TYPE_FAILURE:
      return setEnrollmentEventTypeFailure(state, action);
    case SET_JVP_SESSION_ID:
      return setJVPSessionId(state, action);
    case SET_DEMO_MODE:
      return setDemoMode(state, action);
    case SET_UNVALIDATED_SEGMENT_SLUG:
      return setUnvalidatedSegmentSlug(state, action);
    case SET_BC_URL_HASH:
      return setBCUrlHash(state, action);
    case GET_AAPI_USER_REQUEST:
      return setIsFetchingAAPIUser(state, true);
    case GET_AAPI_USER_SUCCESS:
      return getAAPIUserSuccess(state, action);
    case GET_AAPI_USER_FAILURE:
      return setIsFetchingAAPIUser(state, false);
    case SET_ALEX_ID_UUID:
      return setAlexIdUuid(state, action);
    case HANDLE_PROFILE_COMPLETE:
      return handleProfileComplete(state);
    case SET_VALIDATED_SEGMENT_SLUG:
      return setValidatedSegmentSlug(state, action);
    case UPDATE_PREVIOUS_SELECTED_PLAN:
      return updatePreviousSelectedPlan(state, action);
    default:
      return state;
  }
}

export function changeStep(
  state: GlobalState,
  action: {
    value: string;
  },
) {
  return state.set('path', action.value);
}

export function loadConfig(state: GlobalState) {
  return state.set('isLoading', true);
}

export function loadConfigSuccess(
  state: GlobalState,
  action: {
    response: GetConfigResponse;
  },
) {
  const config = state.get('config').merge(action.response.config);

  return state.set('isLoading', false).set('isConfigLoaded', true).set('config', fromJS(config));
}

export function loadConfigFailure(
  state: GlobalState,
  action: {
    error: Error;
  },
) {
  return state.set('isLoading', false).set('error', action.error).set('isConfigLoaded', true);
}

export function changeForm(state: GlobalState) {
  const isDirty = state.get('isDirty');

  if (!isDirty) {
    return state
      .set('isDirty', true)
      .set('unlockedMainPath', PROFILE_PATH)
      .set('hasUnsavedProfileChanges', true);
  }

  return state.set('hasUnsavedProfileChanges', true);
}

export function changeIncentives(state: GlobalState) {
  return state.set('hasUnsavedProfileChanges', true);
}

export function getRecommendationSuccess(state: GlobalState) {
  return state.set('isDirty', false);
}

export function handleUnlockMainNav(
  state: GlobalState,
  action: {
    path: string;
  },
) {
  return state.set('unlockedMainPath', action.path);
}

export function handleUnlockSubNav(
  state: GlobalState,
  action: {
    path: string;
  },
) {
  return state.set('unlockedSubPath', action.path);
}

export function setPageViewed(
  state: GlobalState,
  action: {
    path: string;
    viewed: boolean;
  },
) {
  let pageProps = state.get('pageProps').toJS();
  if (pageProps[action.path]) {
    pageProps[action.path].viewed = action.viewed;
  } else {
    pageProps = {
      ...pageProps,
      [action.path]: {
        viewed: action.viewed,
      },
    };
  }
  return state.set('pageProps', fromJS(pageProps));
}

export function setSelectedProduct(
  state: GlobalState,
  action: {
    value: AlexProducts;
  },
) {
  updateInsightsDefaults({ product: action.value }).then(() => {
    sendInsightsEvent(null, 'product_select', { product: action.value });
  });
  return state.set('selectedProduct', action.value);
}

export function getJVPBootstrapRequest(state: GlobalState) {
  const jvp = state.get('jvp').merge({ isLoading: true });

  return state.set('jvp', fromJS(jvp));
}

export function getJVPBootstrapSuccess(state: GlobalState, action: GetJVPBootstrapSuccessAction) {
  let isReturnUser: boolean;

  const isIntegratedConfig: boolean = state.getIn(['config', 'is_integrated']);
  if (isIntegratedConfig) {
    /**
     * For an integrated user, we can't discern if a given field set upstream
     * was set from a previous visit or from their integration. For now, we'll
     * assume if integrated, they're _never_ a return user.
     */
    isReturnUser = false;
  } else {
    /**
     * In the event their non-integrated, we'll assume return user if a ZIP is
     * at least set.
     */
    isReturnUser = !!action.user.zipcode;
  }

  const updatedJvpState = state.get('jvp').merge({
    isLoading: false,
    isReturnUser,
    userId: parseJvpUserId(action.user),
    employerId: action.employerId,
    employerName: action.employerName,
    employeeId: action.employeeId,
    active: action.active,
    upcoming: action.upcoming,
  });

  return state.set('jvp', fromJS(updatedJvpState));
}

export function getJVPBootstrapFailure(state: GlobalState, action: { error: Error }) {
  const jvp = state.get('jvp').merge({ isLoading: false });

  return state.set('jvp', fromJS(jvp)).set('error', action.error);
}

export function getJVPUserSuccess(state: GlobalState, action: { jvpUser: JVPUserWithDependents }) {
  return state.set('jvpUser', fromJS(action.jvpUser));
}

export function getJVPUserError(state: GlobalState, action: { error: Error }) {
  state.set('error', action.error);
}

export function setJVPSessionId(state: GlobalState, action: { sessionId: string }) {
  const jvp = state.get('jvp').merge({ sessionId: action.sessionId });
  return state.set('jvp', fromJS(jvp));
}

export function setEnrollmentEventProfileCompleteSuccess(
  state: GlobalState,
  action: { previousSelectedPlan: PreviousSelectedPlan[] | null },
) {
  return state
    .setIn(['jvp', 'profileComplete'], true)
    .set('previousSelectedPlan', action.previousSelectedPlan);
}

export function setEnrollmentEventProfileCompleteFailure(state: GlobalState, action: { error: Error }) {
  return state.set('error', action.error);
}

export function setEnrollmentEventTypeSuccess(
  state: GlobalState,
  action: { eventType: EnrollmentEventType },
) {
  return state.setIn(['jvp', 'eventType'], action.eventType);
}

export function setEnrollmentEventTypeFailure(state: GlobalState, action: { error: Error }) {
  return state.set('error', action.error);
}

export function setDemoMode(state: GlobalState, action: { isDemoMode: boolean }) {
  return state.set('isDemoMode', action.isDemoMode);
}

export function setUnvalidatedSegmentSlug(state: GlobalState, action: { unvalidatedSegmentSlug: string }) {
  return state.set('unvalidatedSegmentSlug', action.unvalidatedSegmentSlug);
}

export function setBCUrlHash(state: GlobalState, action: { hash: string }) {
  return state.set('bcUrlHash', action.hash);
}

export function setIsFetchingAAPIUser(state: GlobalState, fetching: boolean) {
  return state.set('isFetchingAAPIUser', fetching);
}

export function setValidatedSegmentSlug(state: GlobalState, action: { validatedSegmentSlug: string | null }) {
  return state.set('validatedSegmentSlug', action.validatedSegmentSlug).set('segmentSlugProcessed', true);
}

/**
 * Update user state with AAPI-provided auth0_uuid aka alex_id_uuid.
 *
 * This only applies for integrated users.
 *
 * The equivalent to this for ALEX ID users is setAlexIdUuid.
 */
export function getAAPIUserSuccess(state: GlobalState, action: { user: AlexIdUser }) {
  return state.set('isFetchingAAPIUser', false).set('user', action.user);
}

/**
 * Set alex_id_uuid aka auth0_uuid.
 *
 * This is used for setting the ID for social-logins only.
 *
 * The equivalent to this for integrated users is getAAPIUserSuccess.
 */
export function setAlexIdUuid(state: GlobalState, action: { alex_id_uuid: string }) {
  return state.setIn(['user', 'alex_id_uuid'], action.alex_id_uuid);
}

function handleProfileComplete(state: GlobalState) {
  return state.set('hasUnsavedProfileChanges', false);
}

export function updatePreviousSelectedPlan(
  state: GlobalState,
  action: { previousSelectedPlan: PreviousSelectedPlan[] | [] },
) {
  return state.set('previousSelectedPlan', action.previousSelectedPlan);
}

const pojoGlobalAppReducer = wrapImmutableReducerInPojoTranslation(globalAppReducer);

export default pojoGlobalAppReducer;
