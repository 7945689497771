import { AspectRatio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useAudioContext } from 'Contexts/audioContext';
import { ALEX_STEPS, HasPlayed, OUTROS, Step, useIntroContext } from 'Contexts/introContext';
import { IconTypes } from 'DesignLibrary/atoms';
import Audio from 'Shared/Audio';
import { RenderMediaControls } from 'Shared/RenderMediaControls';
import { MediaWrapper } from 'Shared/RenderMediaControls/styled';
import Video from 'Shared/Video';
import { sendInsightsEvent } from 'Utils/insights';

import { VideoWrapper } from '../styled';

interface RenderMediaProps {
  step: Step;
  stepKey: ALEX_STEPS;
  hasPlayed: HasPlayed;
  updateHasPlayed: (key: ALEX_STEPS, state: HasPlayed, index: number) => void;
  mediaType: 'audio' | 'video';
  url: string;
  audioFileIndex?: number;
  hideControls?: boolean;
  handleNext: () => void;
}

export const RenderMedia = ({
  step,
  stepKey,
  hasPlayed,
  updateHasPlayed,
  mediaType,
  url,
  audioFileIndex = 0,
  hideControls = false,
  handleNext,
}: RenderMediaProps) => {
  const { updateStepState, skipStep } = useIntroContext();
  const { audioState } = useAudioContext();
  const [delay, setDelay] = useState(step.delayIn);

  useEffect(() => {
    let timer: number;

    if (step.delayIn > 0) {
      setDelay(step.delayIn);
      timer = window.setTimeout(() => setDelay(0), step.delayIn);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [stepKey]);

  const isMediaPlaying = () => step.state !== 'paused';

  const handlePlayPauseClick = () => {
    if (step.state === 'playing') {
      sendInsightsEvent(null, 'toggle_play_pause', {
        paused: true,
      });
      updateStepState(stepKey, 'paused');
    } else if (hasPlayed !== true) {
      sendInsightsEvent(null, 'toggle_play_pause', {
        paused: false,
      });
      updateStepState(stepKey, 'playing');
    }
  };

  const handleSkipClick = () => {
    skipStep(stepKey);
    sendInsightsEvent(null, 'skip_media', {
      scene: stepKey,
    });
    if (OUTROS.includes(stepKey)) {
      setTimeout(() => {
        handleNext();
      }, 250);
    }
  };

  const renderMediaControls = () => {
    if (hideControls) {
      return null;
    }

    const playPauseButtonIcon = (): IconTypes => {
      if (
        (step.audioHasPlayed.some((isPlayed) => isPlayed) && step.state === '') ||
        step.state === 'playing'
      ) {
        return 'Pause';
      }
      return 'Play';
    };

    const canBeSkipped = !hasPlayed && stepKey !== ALEX_STEPS.SOUND_OFF_AUDIO;

    return (
      <RenderMediaControls
        handlePlayPauseClick={() => handlePlayPauseClick()}
        playPauseButtonIcon={playPauseButtonIcon()}
        handleSkipClick={() => handleSkipClick()}
        isSkipEnabled={canBeSkipped}
      />
    );
  };

  const title = `${stepKey}-${mediaType}`;
  const testId = title;
  const isPlaying = delay === 0 && isMediaPlaying();
  const onStart = () => updateStepState(stepKey, 'playing');
  const onEnded = () => updateHasPlayed(stepKey, true, audioFileIndex);

  return (
    <MediaWrapper>
      {renderMediaControls()}
      {mediaType === 'video' && (
        <VideoWrapper>
          <AspectRatio ratio={4 / 3} w="100%" maxW={600}>
            <Video
              title={title}
              testId={testId}
              url={url}
              width="100%"
              height="100%"
              playing={isPlaying}
              muted={audioState.isMuted}
              controls={false}
              onStart={onStart}
              onEnded={onEnded}
            />
          </AspectRatio>
        </VideoWrapper>
      )}
      {mediaType === 'audio' && (
        <div style={{ display: 'none' }}>
          <Audio
            title={title}
            testId={testId}
            playing={isPlaying}
            muted={audioState.isMuted}
            onStart={onStart}
            onEnded={onEnded}
            url={url}
          />
        </div>
      )}
    </MediaWrapper>
  );
};
