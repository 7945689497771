import React from 'react';

import { Recommendation } from 'app/types/entities';
import { useCompareContext } from 'Contexts/compareContext';
import { useTextContext } from 'Contexts/textContext';
import { Button } from 'DesignLibrary/atoms/Button';
import Plan from 'Models/plan';
import Text from 'Shared/Text';

import { CompareTableRow } from '../_shared/CompareTableRow';

export interface ChooseButtonsProps {
  selectedPlan: Plan | Record<string, never>;
  choosePlan: (plan: Recommendation) => void;
  deselectPlan: () => void;
}

export const ChooseButtons = ({ selectedPlan, choosePlan, deselectPlan }: ChooseButtonsProps) => {
  const { comparablePlans } = useCompareContext();
  const { retrieveContentfulData } = useTextContext();

  const renderChooseButton = (plan: Plan) => {
    const isSelected = selectedPlan.planId && selectedPlan.planId === plan.planId;
    const buttonLabelField = isSelected
      ? 'health_section.button_text.selectedPlan'
      : 'health_section.button_text.choosePlan';

    return (
      <Button
        aria-label={`
          ${retrieveContentfulData<string>(buttonLabelField)}
          ${plan.carrierName}
          ${plan.planMarketingName}
        `}
        size="small"
        stretch
        buttonType={isSelected ? 'secondary' : 'primary'}
        onClick={() => (isSelected ? deselectPlan() : choosePlan(plan.recommendation))}
        iconRight={isSelected ? 'X' : null}
        iconLeft={!isSelected ? 'PlusCircle' : null}
      >
        <Text field={buttonLabelField} />
      </Button>
    );
  };

  return (
    <CompareTableRow
      asterisk
      columnOne={renderChooseButton(comparablePlans[0])}
      columnTwo={renderChooseButton(comparablePlans[1])}
      columnThree={comparablePlans[2] && renderChooseButton(comparablePlans[2])}
      size="small"
    />
  );
};
