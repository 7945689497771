export interface LegacyFeatureFlags {
  useContentfulV2: boolean;
}

/*
Flags defined here MUST match their definition in backend's SplitFeatureFlags.
See "Feature Flags" section in the README.md for details.
 */
export interface SplitFeatureFlags {
  is_integration_pre_fill_enabled: boolean;
  is_footer_test_enabled: boolean;
  is_improved_recommendation_explanation_enabled: boolean;
  is_planned_procedures_enabled: boolean;
  is_real_cost_before_tax_benefit_enabled: boolean;
  is_skip_income_enabled: boolean;
  is_rue_landing_page_enabled: boolean;
  is_returning_user_feature_enabled: boolean;
  is_hide_eligibility_questions_enabled: boolean;
}

export interface FeatureFlags extends LegacyFeatureFlags, SplitFeatureFlags {}

const LegacyFeatureFlagDefaults: LegacyFeatureFlags = {
  useContentfulV2: true,
};

export const SplitFeatureFlagDefaults: SplitFeatureFlags = {
  is_integration_pre_fill_enabled: false,
  is_footer_test_enabled: false,
  is_improved_recommendation_explanation_enabled: false,
  is_planned_procedures_enabled: false,
  is_real_cost_before_tax_benefit_enabled: false,
  is_skip_income_enabled: false,
  is_rue_landing_page_enabled: false,
  is_returning_user_feature_enabled: true,
  is_hide_eligibility_questions_enabled: false,
};

export const FeatureFlagDefaults = {
  ...LegacyFeatureFlagDefaults,
  ...SplitFeatureFlagDefaults,
};
