import React from 'react';

import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps } from 'DesignLibrary/atoms';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';
import { PAY_PERIOD_KEYS } from 'Types/entities';

import { toDollars } from './helpers';

export const createHsaTooltip = (
  isEmployerMatching: boolean,
  employerHsaContribution: number | null,
  employerHsaContributionLimit: number | null,
  employerHsaPercentageMatch: number | null,
) => {
  const hsaToolTip: FormattedTooltipProps = {
    underline: '',
    title: '',
    text: '',
  };

  const matchVars = {
    matchPercent: employerHsaPercentageMatch,
    matchLimit: toDollars(employerHsaContributionLimit),
  };

  hsaToolTip.underline = isEmployerMatching ? (
    <Text field="health_section.plan_tips.hsaMatching.underline" vars={matchVars} />
  ) : (
    <Text
      field="health_section.plan_tips.hsaContribution.underline"
      vars={{ x: toDollars(employerHsaContribution) }}
    />
  );

  hsaToolTip.title = <Text field="health_section.plan_tips.hsaMatching.title" />;

  if (isEmployerMatching) {
    hsaToolTip.text = (
      <p id="employer-match-text">
        <Text field="health_section.plan_tips.hsaMatching.text" vars={matchVars} />
      </p>
    );
  } else {
    hsaToolTip.text =
      employerHsaContribution && employerHsaContribution > 0 ? (
        <p id="employer-contribution-text">
          <Text
            field="health_section.plan_tips.hsaContribution.text"
            vars={{ x: toDollars(employerHsaContribution) }}
          />
        </p>
      ) : (
        <p id="no-employer-contribution-text">
          <Text field="health_section.no_employer_contribution_text" />
        </p>
      );
  }

  return hsaToolTip;
};

export const createHraTooltip = (employerHRAContribution: number | null): FormattedTooltipProps => ({
  underline: (
    <Text
      field="health_section.plan_tips.hraContribution.underline"
      vars={{ x: toDollars(employerHRAContribution) }}
    />
  ),
  title: <Text field="health_section.plan_tips.hraContribution.title" />,
  text: (
    <p>
      <Text
        field="health_section.plan_tips.hraContribution.text"
        vars={{ x: toDollars(employerHRAContribution) }}
      />
    </p>
  ),
});

export const createSihraTooltip = (employerSIHRAContribution: number): FormattedTooltipProps => ({
  underline: (
    <Text
      field="health_section.plan_tips.sihraContribution.underline"
      vars={{ x: toDollars(employerSIHRAContribution) }}
    />
  ),
  title: <Text field="health_section.plan_tips.sihraContribution.title" />,
  text: (
    <p>
      <Text
        field="health_section.plan_tips.sihraContribution.text"
        vars={{ x: toDollars(employerSIHRAContribution) }}
      />
    </p>
  ),
});

interface YearlyTotalTooltipWithTextProps {
  payPeriod: PAY_PERIOD_KEYS;
  color?: string;
  // All other props
  [x: string]: any;
}
export const YearlyTotalTooltipWithText = ({ color, ...rest }: YearlyTotalTooltipWithTextProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { payPeriodText } = usePremiumContext();

  const tooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.estimated_yearly_total'),
    text: (
      <>
        <Text field="tool_tips.estimated_yearly_total.text.p1" vars={{ x: payPeriodText }} />
        <Text field="tool_tips.estimated_yearly_total.text.p2" />
      </>
    ),
  };
  return (
    <TooltipCaps tooltip={tooltip} color={color} keyHint="yearly_expense_total" {...rest}>
      <Text field="health_section.recommendation.yearly_expense_total" />
    </TooltipCaps>
  );
};

interface PremiumTooltipWithTextProps {
  payPeriod: PAY_PERIOD_KEYS;
  keyHint: string;
  color?: string;
  // All other props
  [x: string]: any;
}
export const PremiumTooltipWithText = ({ color, keyHint, ...rest }: PremiumTooltipWithTextProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { payPeriodText } = usePremiumContext();

  const tooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.premium'),
    text: <Text field="tool_tips.premium.text" vars={{ x: payPeriodText }} />,
  };
  return (
    <TooltipCaps tooltip={tooltip} color={color} keyHint={keyHint} {...rest}>
      <Text field="health_section.recommendation.premium" />
    </TooltipCaps>
  );
};

interface AnnualPremiumTooltipWithTextProps {
  keyHint: string;
  color?: string;
  // All other props
  [x: string]: any;
}
export const AnnualPremiumTooltipWithText = ({
  keyHint,
  color,
  ...rest
}: AnnualPremiumTooltipWithTextProps) => {
  const { retrieveContentfulData } = useTextContext();
  const tooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.annual_premium'),
    text: <Text field="tool_tips.annual_premium.text" />,
  };
  return (
    <TooltipCaps tooltip={tooltip} color={color} keyHint={keyHint} {...rest}>
      <Text field="health_section.recommendation.yearly_premium" />
    </TooltipCaps>
  );
};

interface OopMaxTooltipWithTextProps {
  hasComprehensiveOopMax: boolean;
  keyHint: string;
  medicalOopMax: number;
  drugOopMax: number;
}

export const OopMaxTooltipWithText = ({
  hasComprehensiveOopMax,
  medicalOopMax,
  drugOopMax,
  keyHint,
  ...rest
}: OopMaxTooltipWithTextProps) => {
  const { retrieveContentfulData } = useTextContext();
  const tooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.out_of_pocket_max'),
    note: hasComprehensiveOopMax ? null : (
      <Text
        field="tool_tips.out_of_pocket_max.note"
        vars={{
          medicalMax: toDollars(medicalOopMax),
          drugMax: toDollars(drugOopMax),
        }}
      />
    ),
  };
  return (
    <TooltipCaps tooltip={tooltip} keyHint={keyHint} {...rest}>
      <Text field="health_section.recommendation.out_of_pocket_max" />
    </TooltipCaps>
  );
};

export const OopEstimateTooltipWithTooltip = ({ keyHint, ...rest }) => {
  const { retrieveContentfulData } = useTextContext();
  const tooltip = retrieveContentfulData<FormattedTooltipProps>('tool_tips.out_of_pocket_estimate');
  return (
    <TooltipCaps tooltip={tooltip} keyHint={keyHint} {...rest}>
      <Text field="health_section.recommendation.out_of_pocket_estimate" />
    </TooltipCaps>
  );
};
