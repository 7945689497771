import React, { ChangeEvent, FC } from 'react';

import { ToggleListContainer, ToggleLabel, TogglePseudo, HiddenLegend } from './styled';

export interface ToggleListOption {
  option: string | JSX.Element;
  value: string;
}

export interface ToggleListProps extends React.InputHTMLAttributes<HTMLInputElement> {
  options: ToggleListOption[];
  groupName: string;
  label: string | JSX.Element;
  sizing?: 'normal' | 'small';
  color?: string;
  layout?: 'row' | 'column';
  fit?: boolean;
  grid?: boolean;
  gridMobile?: boolean;
  disabled?: boolean;
  selected?: string;
  stretch?: boolean;
  useAriaLabel?: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>, selectedValue: string) => void;
}

const ToggleList: FC<ToggleListProps> = ({
  options,
  groupName,
  label = 'missing accessible label',
  sizing = 'normal',
  color = '--text-black',
  layout = 'row',
  fit = false,
  grid = false,
  gridMobile = false,
  disabled = false,
  selected,
  stretch = false,
  useAriaLabel = false,
  handleChange,
  ...rest
}): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    const { value } = e.target;
    handleChange(e, value);
  };

  return (
    <ToggleListContainer
      color={color}
      layout={layout}
      grid={grid}
      gridMobile={gridMobile}
      gridRowCount={Math.ceil(options.length / 2)}
      role="radiogroup"
    >
      <HiddenLegend>{label}</HiddenLegend>
      {options.map((option) => (
        <ToggleLabel
          key={option.value}
          data-testid={`pseudo-${option.value}`}
          sizing={sizing}
          stretch={stretch || grid}
          fit={fit}
        >
          <input
            type="radio"
            aria-labelledby={useAriaLabel ? undefined : `${groupName}-${option.value}`}
            aria-label={useAriaLabel ? `${groupName}-${option.option}` : undefined}
            data-testid={option.value}
            disabled={disabled}
            checked={selected === option.value}
            value={option.value}
            name={groupName}
            onChange={onChange}
            {...rest}
          />
          <TogglePseudo
            className="pseudo-toggle"
            sizing={sizing}
            color={color}
            aria-hidden="true"
            disabled={disabled}
            selected={selected === option.value}
            id={`${groupName}-${option.value}`}
            fit={fit}
          >
            {option.option}
          </TogglePseudo>
        </ToggleLabel>
      ))}
    </ToggleListContainer>
  );
};

export default ToggleList;
