import { ContentfulReviewSection } from 'ContentfulDefaults/types/review';

export const review: {
  review_section: ContentfulReviewSection;
} = {
  review_section: {
    contentful_name: 'demo review',
    page_header: {
      text: 'Review Your Plans',
      subtext: "Let's go over what you've chosen and how to enroll.",
    },
    page_title: 'Review',
    info_bar: {
      FBS: 'Great, you’ve selected your supplemental plans!',
      noFBS: 'Great, you’ve selected a healthcare plan!',
      noHealth: 'You have opted to not enroll in a health plan.',
    },
    pdf_link: 'https://picwell.com',
    button_text: {
      PDF: 'Print or Save',
      change: 'Change',
      enroll: "Let's Enroll",
      PDFLink: 'More Info',
      warning: 'Jump to Enroll',
      comments: 'Submit Feedback',
      nextSteps: 'Enroll In Benefits',
    },
    toggle_sections: ['1. Review Plans', "2. Let's Enroll"],
    review_instructions: "Here’s how the plans you've selected add up.",
    enroll_warning: {
      text: "Once you've reviewed your plan, check out your enrollment options below.",
      header: 'Enroll Below!',
    },
    enrollment_title: 'Ready to enroll?',
    enroll_instructions: 'We’ll open this link in a new window.',
    checkout_text: 'Your Total',
    non_premium_benefits: 'Here are all of your benefits without any set premiums or contribution amounts.',
    your_contribution_text: {
      saved: 'Your saved contribution amount',
      notSaved: 'Your recommended contribution amount',
    },
    hsa_contributions: 'Include health savings account contributions?',
    enroll_heading: 'Time to Enroll',
    enrollment_completion_text: {
      complete: 'Complete!',
      incomplete: 'Complete?',
    },
    confirmation: 'Everything looking good? Let’s go enroll you in those plans.',
    pdf_instructions: 'Save to PDF or print these results for future use.',
    next_steps_header: 'Next Steps',
    next_step_instructions: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value:
                "Now that we've found the right plans for you, it's time to enroll. Visit your enrollment platform to complete your benefits enrollment!",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: '\n1.  Access ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              content: [
                {
                  nodeType: 'text',
                  value: 'Workday',
                  marks: [],
                  data: {},
                },
              ],
              data: {
                uri: 'http://www.google.com',
              },
            },
            {
              nodeType: 'text',
              value: ' from work or home',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: '\n(Company ID: Picwell)',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: '\nPlease note: For password resets, enter a ticket or call the help desk',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: '\n2.  Go to your Inbox and click on the\nBenefits Open Enrollment Task',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value:
                '\n3.  Complete all steps, Review/Confirm your elections and\nSave/Print your Confirmation Statement',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    show_enrollment_text: false,
    contact_hr: 'If you have any questions regarding your benefits, contact the HR department:',
    phone_heading: 'If you have any questions regarding your benefits, contact the HR department:',
    phone_number: '+1(717)-968-6737',
    email_heading: 'Email the HR department at:',
    email: 'hr@picwell.com',
    username_hint: 'First Initial/ Last Name/ Last 4 of SSN (e.g., JDoe6789)',
    password_hint: 'Date of birth MMDDYYYY (e.g., 01051965)',
    more_info_heading:
      'If you need more info about your open enrollment, check out this PDF from the HR department.',
    experience_heading: "We'd love to know!",
    experience_question: 'How would you rate your experience using our tool?',
    response_heading: 'Thanks for your response!',
    comment_instructions:
      'Thanks for rating our tool!  If you have any feedback or comments about our experience, please include them below.',
    comment_placeholder_text: 'Add comments or feedback here...',
    ratings: {
      1: {
        icon: '😡',
        text: 'Terrible',
        alt: 'Pouting Face',
      },
      2: {
        icon: '🙁',
        text: 'Bad',
        alt: 'Slightly Frowning Face',
      },
      3: {
        icon: '😐',
        text: 'Okay',
        alt: 'Neutral Face',
      },
      4: {
        icon: '😊',
        text: 'Good',
        alt: 'Smiling Face With Smiling Eyes',
      },
      5: {
        icon: '😃',
        text: 'Great',
        alt: 'Grinning Face With Big Eyes',
      },
    },
    lets_enroll_card_text:
      'Hey, you made it!  We’ve helped you identify the best plans for your needs, but you still have to enroll! ',
    accessibility: {
      rating_submitted_alert: 'Rating submitted',
      comments_submitted_alert: 'Comments submitted',
    },
    response_body: 'Your feedback is really appreciated and will help us improve our experience!',
    next_step_fields: {
      email: 'Email:',
      username: 'Username:',
      password: 'Password:',
      phone: 'Phone:',
    },
    learn_more_text: "Learn more on your employer's HR site.",
    buy_up_text: 'Premiums determined during enrollment.',
    waived_coverage_text: "You've chosen to waive your health coverage.",
    card_header_tooltips: {
      premiums: {
        title: 'Premiums',
        text: 'FIX ME',
      },
      hsa_contributions: {
        title: 'Contributions',
        text: 'FIX ME',
      },
      hra_contributions: {
        title: 'Contributions',
        text: 'FIX ME',
      },
      employer_contributions: {
        title: 'Employer Contributions',
        text: 'FIX ME',
      },
      other_benefits: {
        title: 'Other Benefits',
        text: 'FIX ME',
      },
    },
  },
};
