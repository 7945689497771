import { ContentfulReviewSection } from 'ContentfulDefaults/types/review';

export const review: {
  review_section: ContentfulReviewSection;
} = {
  review_section: {
    contentful_name: 'demo review',
    page_header: {
      text: 'Revise sus beneficios',
      subtext: 'Repasemos lo que ha elegido y cómo inscribirse.',
    },
    page_title: 'Revisar',
    info_bar: {
      FBS: '¡Genial, ha seleccionado sus planes suplementarios!',
      noFBS: '¡Genial, ha seleccionado un plan de atención médica!',
      noHealth: 'Ha optado por no inscribirse en un plan de salud.',
    },
    pdf_link: '',
    button_text: {
      PDF: 'Guardar en PDF / Imprimir',
      change: 'Cambio',
      enroll: 'Inscribámonos',
      PDFLink: 'Más información',
      warning: 'Ir a Inscribirse',
      comments: 'Enviar',
      nextSteps: 'Inscríbase en beneficios',
    },
    toggle_sections: ['1. Revisar planes', '2. Inscribámonos'],
    review_instructions: 'Así es como se suman los planes que ha seleccionado.',
    enroll_warning: {
      text: 'Una vez que haya revisado su plan, consulte las opciones de inscripción a continuación.',
      header: '¡Inscríbase a continuación!',
    },
    enrollment_title: 'Hora de inscribirse',
    enroll_instructions:
      'Veamos cada paso de inscripción a continuación. Los enlaces a continuación lo llevarán a sitios web de inscripción externos.',
    checkout_text: 'Tu total',
    non_premium_benefits:
      'Aquí están todos sus beneficios sin ninguna prima establecida o monto de contribución.',
    your_contribution_text: {
      saved: 'El monto de su contribución guardada',
      notSaved: 'Su monto de contribución recomendado',
    },
    hsa_contributions: '¿Incluye las contribuciones de la cuenta de ahorro para la salud?',
    enroll_heading: 'Hora de inscribirse',
    enrollment_completion_text: {
      complete: '¡Completo!',
      incomplete: '¿Completa?',
    },
    confirmation: '¿Todo se ve bien? Vamos a inscribirte en esos planes.',
    pdf_instructions: 'Guarde en PDF o imprima estos resultados para usarlos en el futuro.',
    next_steps_header: 'Próximos pasos',
    next_step_instructions: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Ahora que hemos encontrado los planes adecuados para usted, es hora de inscribirse. ¡Visite su plataforma de inscripción para completar su inscripción de beneficios!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    show_enrollment_text: false,
    contact_hr: '',
    phone_heading: 'Para obtener más ayuda con su inscripción, llame al:',
    phone_number: '+1(717)-968-6737',
    email_heading: 'Envíe un correo electrónico al departamento de recursos humanos a:',
    email: 'hr@picwell.com',
    username_hint: '',
    password_hint: '',
    more_info_heading:
      'Si necesita más información sobre su inscripción abierta, consulte este PDF del departamento de Recursos Humanos.',
    experience_heading: '¡Nos encantaría saberlo!',
    experience_question: '¿Cómo fue su experiencia general al usar esta herramienta?',
    response_heading: '¡Gracias por su respuesta!',
    comment_instructions: 'Si tiene algún comentario, inclúyalos a continuación.',
    comment_placeholder_text: 'Agregue comentarios o comentarios aquí...',
    ratings: {
      '1': {
        alt: 'Cara de puchero',
        icon: '😡',
        text: 'Terrible',
      },
      '2': {
        alt: 'Cara ligeramente fruncida',
        icon: '🙁',
        text: 'Malo',
      },
      '3': {
        alt: 'Cara neutra',
        icon: '😐',
        text: 'Promedio',
      },
      '4': {
        alt: 'Cara sonriente con ojos sonrientes',
        icon: '😊',
        text: 'Bueno',
      },
      '5': {
        alt: 'Cara sonriente con ojos grandes',
        icon: '😃',
        text: 'Excelente',
      },
    },
    lets_enroll_card_text: '¡Lo logró! ¡Ya decidió a qué se inscribirá, pero aún necesita inscribirse! ',
    accessibility: {
      rating_submitted_alert: 'Calificación enviada',
      comments_submitted_alert: 'Comentarios enviados',
    },
    response_body:
      'Apreciamos mucho sus comentarios y ellos nos ayudarán a mejorar la experiencia de nuestros usuarios!',
    next_step_fields: {
      email: 'Email:',
      phone: 'Phone:',
      password: 'Password:',
      username: 'Username:',
    },
    learn_more_text: 'Aprenda más en el sitio de recursos humanos de su empleador.',
    buy_up_text: 'Las primas se determinan durante la inscripción.',
    waived_coverage_text: 'Las primas se determinan durante la inscripción.',
    card_header_tooltips: {
      premiums: {
        text: 'Este es el costo total de los beneficios que ha seleccionado, por período de pago.',
        title: 'Primas',
      },
      other_benefits: {
        text: "Estos 'Otros beneficios' incluyen beneficios adicionales que su empleador le ofrece, sin cargo, además de cualquier opción de compra adicional que haya seleccionado.",
        title: 'Otros beneficios',
      },
      hra_contributions: {
        text: 'Esta es la cantidad total que usted ha elegido contribuir a cuentas de ahorros o de gastos en cada período de pago.',
        title: 'Contribuciones',
      },
      hsa_contributions: {
        text: 'Esta es la cantidad total que usted ha elegido contribuir a cuentas de ahorros o de gastos en cada período de pago.',
        title: 'Contribuciones',
      },
      employer_contributions: {
        text: 'Esta es la cantidad total que su empleador contribuirá a las cuentas de ahorros o de gastos en cada período de pago.',
        title: 'Contribuciones del empleador',
      },
    },
  },
};

export default review;
