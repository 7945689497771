import React, { FC, ReactElement } from 'react';

import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { H1 } from 'DesignLibrary/atoms/typography';

import { PageHeaderContainer, Subheader } from './styled';

export interface PageHeaderProps extends React.HTMLAttributes<HTMLUListElement> {
  header: string;
  subheader: ReactElement<Text> | string;
  hideSubheader: boolean;
  animate?: boolean;
  welcomeText: string;
}

export const PageHeader: FC<PageHeaderProps> = ({
  header,
  subheader,
  hideSubheader = false,
  animate = true,
  welcomeText,
}): JSX.Element => {
  const headerTokens = header?.split(' ');

  return (
    <PageLayout>
      <PageHeaderContainer numTokens={headerTokens?.length} animate={animate}>
        <H1 color="--text-black">
          {headerTokens?.map((token, index) => (
            <span id={`headerToken-${index}`} key={token}>
              {token}
              &nbsp;
            </span>
          ))}
        </H1>
        <Subheader color="--text-gray" hideSubheader={!hideSubheader} animate={animate}>
          {welcomeText}
        </Subheader>
        <Subheader color="--text-gray" hideSubheader={hideSubheader} animate={animate}>
          {subheader}
        </Subheader>
      </PageHeaderContainer>
    </PageLayout>
  );
};
