import { ContentfulToolTipsSection } from 'ContentfulDefaults/types/toolTips';

export const toolTips: {
  tool_tips: ContentfulToolTipsSection;
} = {
  tool_tips: {
    contentful_title: 'Tool Tips (DEFAULT DO NOT CHANGE)',
    picwell_score: {
      text: 'Su puntaje Picwell califica el valor que proporciona cada plan después de tener en cuenta las primas, los detalles del plan, sus preferencias y el costo y el riesgo de atención médica pronosticados de personas como usted',
      title: 'Tu puntaje Picwell',
      underline: 'Tu puntaje Picwell',
    },
    pay_period: {
      title: 'Período de pago',
      weekly: {
        text: 'Semanalmente: todas las semanas en un día específico de la semana (52 nóminas al año). Ejemplo: todos los viernes.',
        underline: '/sem',
      },
      yearly: {
        text: 'Anual: cantidad total en un año.',
        underline: '/año',
      },
      monthly: {
        text: 'Mensualmente: todos los meses en una fecha específica del mes ({x} nóminas por año). Ejemplo: el 26.',
        underline: '/mens',
      },
      biWeekly: {
        text: 'Bisemanalmente: cada dos semanas en un día específico de la semana (26 nóminas por año). Ejemplo: cada dos viernes.',
        underline: '/bisem',
      },
      semiMonthly: {
        text: 'Quincenalmente: dos veces al mes en dos fechas específicas del mes (24 nóminas por año). Ejemplo: el 15 y el último día del mes.',
        underline: '/qnal',
      },
      perPaycheck: {
        text: 'Por cheque de pago: cada vez que se le paga. {x} nominas por año.',
        underline: '/pago',
      },
    },
    number_with_pay_period: {
      yearly: {
        text: 'Total amount in a year.',
        title: 'Yearly',
        underline: '/yr',
      },
      pay_period: {
        title: 'Pay Period',
        weekly: {
          text: 'Weekly: Every week on a specific day of the week (52 payrolls a year). Example: every Friday.',
          underline: '/wk',
        },
        yearly: {
          text: 'Yearly: Total amount in a year.',
          underline: '/yr',
        },
        monthly: {
          text: 'Monthly: Every month on a specific date of the month ({x} payrolls per year). Example: on the 26th.',
          underline: '/mo',
        },
        biWeekly: {
          text: 'Bi-Weekly: Every two weeks on a specific day of the week (26 payrolls per year). Example: every other Friday.',
          underline: '/bwk',
        },
        semiMonthly: {
          text: 'Semi-Monthly: Twice per month on two specific dates of the month (24 payrolls per year). Example: the 15th and the last day of the month.',
          underline: '/sm',
        },
      },
    },
    hsa: {
      text: "Las HSA son cuentas especiales que le permiten ahorrar e invertir dinero para pagar los gastos de atención médica. Nunca paga impuestos sobre las contribuciones, ganancias o retiros de HSA, y esta 'ventaja de triple impuesto' los convierte en la mejor manera de ahorrar para sus costos de atención médica tanto ahora como en el futuro. Las personas pueden contribuir hasta {x} por año (las personas de 55 años o más pueden ser elegibles para hacer una contribución de recuperación de $ 1,000), y las familias pueden contribuir hasta {x} por año. Una vez que ahorre lo suficiente para alcanzar el requisito de ahorro mínimo de su administrador de HSA, puede invertir cualquier exceso de fondos que acumule.",
      title: 'Cuenta de ahorro de salud',
      underline: 'Cuenta de Ahorros de Salud (HSA)',
    },
    recommended_hsa_contribution: {
      title: 'Recommended HSA Contribution',
      text: 'This is how much we recommend you contribute to your HSA this year.',
      underline: 'Recommended HSA Contribution',
    },
    hra: {
      text: 'Una Cuenta de Reembolso de Salud (HRA) es una cuenta de gastos que su empleador establece para ayudarle a pagar los gastos médicos que su seguro no cubre. Si su empleador estableció una HRA, puede enviar recibos de facturas médicas que ha pagado y su empleador reembolsará esos cargos hasta que haya agotado todos los fondos asignados. Cualquier fondo de la HRA que usted no use regresa a su empleador al final del año, pero su empleador puede permitirle trasladar una parte de su HRA al año siguiente.',
      title: 'Cuenta de reembolso de salud',
      underline: 'Cuenta de reembolso de salud (HRA)',
    },
    remaining_oop: {
      text: 'Esta es la cantidad que ALEX predice que tendrá que gastar de su bolsillo (menos las primas) en gastos de cuidados médicos.',
      title: 'Su gasto de bolsillo restante',
    },
    family_threshold: {
      text: 'Su resumen de beneficios puede contener valores para los umbrales "individual " y "familiar ". Ingrese los valores para el umbral "familia " a continuación.',
      title: 'Umbral familiar',
    },
    estimated_yearly_total: {
      text: {
        p1: 'Primas anuales (primas x {x})',
        p2: '+ Estimación de gastos de bolsillo',
      },
      title: 'Total anual estimado =',
      underline: 'Est. Total anual',
    },
    premium: {
      text: 'El monto que paga {x} por su plan de seguro de salud.',
      title: 'Primo',
      underline: 'Primo',
    },
    annual_premium: {
      text: 'La cantidad que paga durante todo el año para estar en un plan de seguro médico.',
      title: 'Prima anual',
      underline: 'Prima anual',
    },
    out_of_pocket_estimate: {
      text: 'Los costos de atención médica que la compañía de seguros no paga. En otras palabras, cuando recibe una factura médica, esta es la cantidad de la que usted es responsable.',
      title: 'Estimación de gastos de bolsillo',
      underline: 'Fuera de su bolsillo',
    },
    striked_oop_estimate: {
      text: 'Este número tachado es el precio antes de tener en cuenta la contribución de {x} de su empleador',
      underline: '(¿POR QUÉ?)',
    },
    deductible: {
      note: 'Nota: Este valor deducible tiene el siguiente desglose: Deducible médico: {x} y Deducible de medicamentos: {x}',
      text: 'El monto que usted es responsable de pagar antes de que su plan de seguro comience a cubrir algunos de sus costos de atención médica. Una vez que haya alcanzado el deducible, pagará un copago o coseguro por la atención médica posterior que reciba.',
      title: 'Deducible',
      underline: 'Deducible',
    },
    out_of_pocket_max: {
      note: 'Nota: Este valor límite de OOP tiene el siguiente desglose: Límite de OOP médico: {x} y Límite de OOP de medicamentos: {x}',
      text: 'Lo máximo que pagará por la atención médica cubierta en un año. Una vez que haya pagado hasta el límite de OOP, su seguro paga el 100% de los costos adicionales cubiertos de atención médica en los que incurra.',
      title: 'Límite de gastos de bolsillo (máx.)',
      underline: 'OOP Max',
    },
    primary_care_copay: {
      text: 'El monto en dólares que paga cuando acude a su proveedor de atención primaria para tratar una enfermedad o lesión. Tenga en cuenta que las visitas de atención primaria para atención preventiva de rutina pueden tener un copago diferente.',
      title: 'Copago de atención primaria',
      underline: 'Copago de atención primaria',
    },
    primary_care_coinsurance: {
      text: 'El porcentaje del costo total que paga cuando acude a su proveedor de atención primaria para tratar una enfermedad o lesión. Su compañía de seguros paga el monto restante. Tenga en cuenta que las visitas de atención primaria para atención preventiva de rutina pueden tener un copago diferente.',
      title: 'Coseguro de atención primaria',
      underline: 'Coseguro de atención primaria',
    },
    specialist_care_copay: {
      text: 'La cantidad en dólares que paga cuando va a un especialista.',
      title: 'Copago de atención especializada',
      underline: 'Copago de atención especializada',
    },
    specialist_care_coinsurance: {
      text: 'El porcentaje del costo total que paga cuando acude a un especialista. Su compañía de seguros paga el monto restante.',
      title: 'Coseguro de atención especializada',
      underline: 'Coseguro de atención especializada',
    },
    generic_drug_copay: {
      text: 'El monto en dólares que paga por una receta genérica o de Nivel 1. Los copagos generalmente están asociados con una receta de un mes.',
      title: 'Copago genérico de medicamentos',
      underline: 'Copago genérico de medicamentos',
    },
    generic_drug_coinsurance: {
      text: 'El porcentaje del costo total de una receta genérica o de Nivel 1 que paga. Su compañía de seguros paga el monto restante.',
      title: 'Coseguro genérico de medicamentos',
      underline: 'Coseguro genérico de medicamentos',
    },
    brand_drug_copay: {
      text: 'El monto en dólares que paga por una marca o receta médica de Nivel 2. Los copagos generalmente están asociados con una receta de un mes.',
      title: 'Copago de medicamentos de marca',
      underline: 'Copago de medicamentos de marca',
    },
    brand_drug_coinsurance: {
      text: 'El porcentaje del costo total de una marca o receta de Nivel 2 que paga. Su compañía de seguros paga el monto restante.',
      title: 'Coseguro de medicamentos de marca',
      underline: 'Coseguro de medicamentos de marca',
    },
    non_formulary_drug_copay: {
      text: 'La cantidad en dólares que paga por una receta que no está incluida en el formulario de su plan de salud. Los copagos generalmente están asociados con una receta de un mes.',
      title: 'Copago de medicamentos no incluidos en el formulario',
      underline: 'Copago de medicamentos no incluidos en el formulario',
    },
    non_formulary_drug_coinsurance: {
      text: 'El porcentaje del costo total que paga por una receta que no está incluida en el formulario de su plan de salud. Su compañía de seguros paga el monto restante.',
      title: 'Coseguro de medicamentos no incluido en el formulario',
      underline: 'Coseguro de medicamentos no incluido en el formulario',
    },
    specialty_drug_copay: {
      text: 'El monto en dólares que paga por una especialidad o receta de Nivel 3. Los copagos generalmente están asociados con una receta de un mes.',
      title: 'Copago de medicamentos especializados',
      underline: 'Copago de medicamentos especializados',
    },
    specialty_drug_coinsurance: {
      text: 'El porcentaje del costo total de una especialidad o receta de Nivel 3 que paga. Su compañía de seguros paga el monto restante.',
      title: 'Coseguro de medicamentos especializados',
      underline: 'Coseguro de medicamentos especializados',
    },
    tier1_deductible: {
      text: 'The deductible that applies to health care that you receive from Tier 1 providers.',
      title: 'Tier 1 Deductible',
      underline: 'Tier 1 Deductible',
    },
    tier2_deductible: {
      text: 'El deducible que se aplica a los cuidados médicos que recibe de los proveedores del nivel 2.',
      title: 'Deducible de nivel 2',
      underline: 'Deducible de nivel 2',
    },
    in_network_deductible: {
      text: 'El deducible que se aplica a la atención médica que recibe de proveedores de la red.',
      title: 'Deducible en la red',
      underline: 'Deducible en la red',
    },
    out_of_network_deductible: {
      text: 'El deducible que se aplica a la atención médica que recibe de proveedores fuera de la red.',
      title: 'Deducible fuera de la red',
      underline: 'Deducible fuera de la red',
    },
    in_network_out_of_pocket_limit: {
      text: 'Lo máximo que pagará en un año por la atención médica cubierta que reciba de proveedores de la red. Una vez que haya pagado hasta este límite, su seguro paga el 100% de los costos adicionales cubiertos de atención médica dentro de la red en los que incurra.',
      title: 'Límite de gastos de bolsillo dentro de la red',
      underline: 'Límite de gastos de bolsillo dentro de la red',
    },
    out_of_network_out_of_pocket_limit: {
      text: 'Lo máximo que pagará en un año por la atención médica cubierta que reciba de proveedores fuera de la red. Una vez que haya pagado hasta este límite, su seguro paga el 100% de los costos adicionales cubiertos de atención médica fuera de la red en los que incurra.',
      title: 'Límite de gastos fuera de la red',
      underline: 'Límite de gastos fuera de la red',
    },
    yearly_estimate_greater_than_out_of_pocket_limit: {
      text: 'Su gasto de bolsillo estimado los costos son más altos que el desembolso máximo de este plan. Los costos estimados pueden exceder el máximo de gastos de bolsillo cuando es probable que personas como usted utilicen servicios de atención médica o recetas que este plan no cubre. Sus costos no excederán el máximo de desembolso personal siempre y cuando solo obtenga atención médica o recetas que estén dentro de la red y estén cubiertas por este plan.',
      title: 'Nota:',
    },
    account_eligibility: {
      text: 'Algunos planes de salud pueden ser elegibles para cuentas especiales que pueden ayudarle a pagar sus cuidados médicos.',
      title: 'Elegibilidad de la cuenta',
    },
  },
};

export default toolTips;
