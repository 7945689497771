import { Skeleton, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { ContentfulCustomMessage } from 'ContentfulDefaults/types/_shared';
import { ContentfulInfoBar } from 'ContentfulWrappers/ContentfulInfoBar';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';

import { IncentiveQuestion } from './IncentiveQuestion';
import Question from '../_shared/Question';
import {
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  IncentiveSurveyQuestionType,
  IncentiveType,
} from '../types';

export interface IncentiveSectionProps {
  isLoading: boolean;
  incentiveSurvey: IncentiveSurveyQuestion[];
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  isDirty: boolean;
  hasSpouse: boolean;
  submitIncentiveSurveyResponses: (
    incentiveSurveyResponses: Record<string, IncentiveSurveyAnswer | null>,
  ) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
}

export const IncentiveSection = ({
  isLoading,
  incentiveSurvey,
  incentiveSurveyAnswers,
  isDirty,
  hasSpouse,
  submitIncentiveSurveyResponses,
  handleSectionChange,
}: IncentiveSectionProps) => {
  const { getSectionFromMap } = useProfileContext();
  const { retrieveContentfulData } = useTextContext();

  const successMessages = retrieveContentfulData<ContentfulCustomMessage[]>(
    'profile_section.incentives.success_custom_messages',
  );
  const emptyMessages = retrieveContentfulData<ContentfulCustomMessage[]>(
    'profile_section.incentives.empty_custom_messages',
  );

  function validateForm() {
    const isFormValid = Object.values(incentiveSurveyAnswers).every(
      (i) => i !== null && i.expected_incentive_amount !== '',
    );
    return isFormValid;
  }

  const isIncomplete = !getSectionFromMap('incentives')?.isComplete;

  useEffect(() => {
    if (!isEmpty(incentiveSurveyAnswers)) {
      const section = getSectionFromMap('incentives');
      const isComplete = section?.isComplete || validateForm();
      handleSectionChange(validateForm(), isComplete);
    }
  }, [JSON.stringify(incentiveSurveyAnswers)]);

  const handleChange = (
    id: number,
    type: IncentiveSurveyQuestionType,
    incentiveType: IncentiveType,
    val: number | string | null,
  ) => {
    if (type === 'fill_in_the_blank') {
      submitIncentiveSurveyResponses({
        ...incentiveSurveyAnswers,
        [id]: {
          jv_incentive_id: id,
          expected_incentive_amount: val,
          incentive_type: incentiveType,
          choice: null,
        },
      });
    } else {
      submitIncentiveSurveyResponses({
        ...incentiveSurveyAnswers,
        [id]: {
          jv_incentive_id: id,
          choice: val === 'null' ? null : val,
          incentive_type: incentiveType,
          expected_incentive_amount: null,
        },
      });
    }
  };

  if (isLoading) {
    return (
      <Question error={false} name="incentives" isIncomplete>
        <Stack>
          <Skeleton height={16} speed={2} />
          <Skeleton height={16} speed={1.5} />
          <Skeleton height={16} speed={1.75} />
        </Stack>
      </Question>
    );
  }

  return (
    <div id="incentives-section" data-testid="incentives-section">
      <Question error={!validateForm() && isDirty} name="incentives" isIncomplete={isIncomplete}>
        {!isLoading && (
          <Stack>
            {incentiveSurvey.length > 0 ? (
              <>
                {successMessages.map((_, idx) => (
                  <ContentfulInfoBar
                    key={_.contentful_name}
                    field={`profile_section.incentives.success_custom_messages[${idx}]`}
                  />
                ))}
              </>
            ) : (
              <>
                {emptyMessages.map((_, idx) => (
                  <ContentfulInfoBar
                    key={_.contentful_name}
                    field={`profile_section.incentives.empty_custom_messages[${idx}]`}
                  />
                ))}
              </>
            )}
          </Stack>
        )}

        {incentiveSurvey.length > 0 && (
          <Stack gap={2}>
            {incentiveSurvey.map((i) => (
              <IncentiveQuestion
                key={i.id}
                surveyQuestion={i}
                answer={incentiveSurveyAnswers[i.id]}
                handleChange={handleChange}
                hasSpouse={hasSpouse}
              />
            ))}
          </Stack>
        )}
      </Question>
    </div>
  );
};
