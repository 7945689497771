import { Flex, Stack } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { TextField } from 'Containers/App/types';
import { CUSTOM_NAME_PLAN_TYPES } from 'Containers/OverviewPage/constants';
import { ContentfulCustomMessage } from 'ContentfulDefaults/types/_shared';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp, H4, H6, Icon, Paragraph } from 'DesignLibrary/atoms';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { SelectedBadge } from 'DesignLibrary/atoms/SelectedBadge';
import { useViewport } from 'DesignLibrary/context';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { CoveredMember, DollarField, DollarRangeField, SupplementalPlan } from 'Types/entities';
import { sendInsightsEvent } from 'Utils/insights';

import PlanButtons from './PlanButtons';
import PlanContent from './PlanContent/planContent';
import { ButtonContainer, CardHeader, PlanCard } from './styled';
import { CONTENT_MAP } from '../constants';
import { BuilderBenefit, InsuranceType } from '../types';

export interface ExpandablePlanCardProps {
  plan: SupplementalPlan;
  builderBenefit?: BuilderBenefit;
  isSelected: boolean;
  insuranceType: InsuranceType;
  idx: number;
  isNonSelectableBenefit: boolean;
  coveredMembers: {
    [memberId: string]: CoveredMember;
  };
  showFbsCardDetails: boolean;
  planWithFullHouseholdSelected: SupplementalPlan | Record<string, never>;
  selectablePlanCount: number;
  addCarrier?: string;
  builderCustomerKey: string | null;
  employerName: string;
  selectPlan: (plan: SupplementalPlan) => void;
  removePlan: () => void;
  selectedHospitalIndenmintyPlan?: SupplementalPlan | null;
}

const ExpandablePlanCard = ({
  plan,
  builderBenefit,
  isSelected,
  insuranceType,
  idx,
  isNonSelectableBenefit,
  coveredMembers,
  showFbsCardDetails,
  planWithFullHouseholdSelected,
  selectablePlanCount,
  addCarrier,
  builderCustomerKey,
  employerName,
  selectPlan,
  removePlan,
  selectedHospitalIndenmintyPlan,
}: ExpandablePlanCardProps) => {
  const { device } = useViewport();
  const [warningText, setWarningText] = useState<TextField | ''>('');
  const { retrieveContentfulData } = useTextContext();

  const isMoreSelectablePlans = selectablePlanCount > 0;

  const isAutoEnrolled = plan.auto_enrolled;
  const isEligible =
    isUndefined(plan.ineligible_members) || isUndefined(coveredMembers)
      ? true
      : plan.ineligible_members && plan.ineligible_members.length === 0;

  const isEmployerPaid = builderBenefit?.is_employer_paid;

  // Get custom name from plan, or generic name from contentful, or fall back to plan type.
  const benefitTitle =
    (CUSTOM_NAME_PLAN_TYPES.includes(insuranceType)
      ? plan.name
      : retrieveContentfulData<string>(`${CONTENT_MAP[insuranceType].field}.card_title`)) || insuranceType;

  useEffect(() => {
    if (!isNonSelectableBenefit) {
      updateWarningText();
    }
  }, [planWithFullHouseholdSelected]);

  const updateWarningText = () => {
    let hasSpouse = false;
    let hasDependents = false;
    let ineligibleDependents = false;
    let ineligibleSpouse = false;

    const eligibleMemberIds = planWithFullHouseholdSelected?.eligible_members || [];
    Object.entries(coveredMembers).forEach(([key, value]) => {
      if (value.dependant) {
        hasDependents = true;
        if (!eligibleMemberIds.includes(key)) {
          ineligibleDependents = true;
        }
      } else if (!value.policyholder) {
        hasSpouse = true;
        if (!eligibleMemberIds.includes(key)) {
          ineligibleSpouse = true;
        }
      }
    });
    let warning: TextField | '' = ``;
    if (hasSpouse && hasDependents) {
      if (ineligibleSpouse && ineligibleDependents) {
        warning = `benefits_section.plan_card_text.notEligibleWarning.yourself`;
      } else if (ineligibleSpouse) {
        warning = `benefits_section.plan_card_text.notEligibleWarning.youAndDependents`;
      } else if (ineligibleDependents) {
        warning = `benefits_section.plan_card_text.notEligibleWarning.youAndSpouse`;
      }
    } else if (hasSpouse) {
      if (ineligibleSpouse) {
        warning = `benefits_section.plan_card_text.notEligibleWarning.yourself`;
      }
    } else if (hasDependents) {
      if (ineligibleDependents) {
        warning = `benefits_section.plan_card_text.notEligibleWarning.yourself`;
      }
    }
    setWarningText(warning);
  };

  const handleSelect = (e) => {
    const coveredMembersIds = Object.keys(coveredMembers)
      .map((externalId) => coveredMembers[externalId])
      .filter((member) => member.covered === true)
      .map((member) => member.memberId);

    sendInsightsEvent(e, 'choose_supplemental_plan', {
      covered_members: coveredMembersIds,
      plan_type: insuranceType,
      plan,
    });

    selectPlan(plan);
  };

  const handleTrackPdfView = (e) => {
    sendInsightsEvent(e, 'view_supplemental_plan_pdf', {
      plan_type: insuranceType,
      plan,
    });
  };

  const renderCustomMessage = () => {
    const contentfulSupplementalPlans = retrieveContentfulData<ContentfulSupplementalPlan[]>(
      'benefits_section_additional.supplemental_plans',
      [],
    );

    const customMessageIdx = contentfulSupplementalPlans.findIndex((i) => i.plan_id === plan.external_id);
    if (customMessageIdx >= 0) {
      const customMessage = contentfulSupplementalPlans[customMessageIdx]
        .custom_message as ContentfulCustomMessage;

      if (!customMessage) {
        return null;
      }
      return (
        <InfoBar
          className={`${plan.external_id}-customMessage`}
          text={
            <RichText
              field={
                `benefits_section_additional.supplemental_plans[${customMessageIdx}].custom_message.text` as TextField
              }
            />
          }
          color={customMessage?.color}
          header={customMessage?.header}
          stretch
          icon={customMessage?.icon}
          buttonText={customMessage?.url_button_text}
          url={customMessage?.url}
        />
      );
    }
    return null;
  };

  const parsePremiumFromPlan = (planPremium: DollarRangeField | DollarField): number => {
    if (typeof planPremium === 'object') {
      if (planPremium.type === 'USD') {
        return planPremium.data;
      }

      return planPremium.data.lower;
    }

    return planPremium;
  };

  const getPremiumComponent = () => {
    if (plan.monthly_premium) {
      const planPremium = parsePremiumFromPlan(plan.monthly_premium);
      return (
        <PremiumPerDeduction
          annualPremium={planPremium * 12}
          deductions={plan.deductions_per_year}
          currency
          precision={2}
        />
      );
    }

    return undefined;
  };

  const showBundledWithMedical = () => {
    if (plan.benefits.items.find((i) => i.item.data === 'plan_in_medical')?.description.data === 'true') {
      return (
        <InfoBar
          text={<Text field="benefits_section_vision.bundled_with_medical" />}
          showAccent={false}
          size="sm"
        />
      );
    }
    return null;
  };

  const setCarrierName = () => {
    if (insuranceType === 'add') {
      return addCarrier;
    }
    return builderBenefit?.carrier || plan.carrier_name;
  };

  return (
    <PlanCard className="expandable-plan-card" isSelected={isSelected} isAutoEnrolled={isAutoEnrolled}>
      <div className="badge-container">
        <div className="badge">
          <FadeUp translateX="1rem" translateY="0" timeout={500} isOpen={device === 'desktop' && isSelected}>
            <SelectedBadge type={isAutoEnrolled ? 'auto_enrolled' : 'selected'} />
          </FadeUp>
        </div>
      </div>

      <CardHeader className="plan-head">
        <div className="header">
          <FadeUp isOpen={device !== 'desktop' && isSelected}>
            <SelectedBadge
              type={isAutoEnrolled ? 'auto_enrolled' : 'selected'}
              sizeOverride={device === 'mobile' ? 'small' : 'large'}
            />
          </FadeUp>
          <div className={`header-text ${isSelected ? 'scoot' : null}`} id={`${plan.external_id}-title`}>
            <Paragraph size="small" className="carrier-name">
              {setCarrierName()}
            </Paragraph>
            <H4 as="h3" className="plan-name">
              {benefitTitle}
            </H4>
          </div>
        </div>
        <Stack gap={2} align="flex-end">
          {showBundledWithMedical()}
          <div className="premium">{getPremiumComponent()}</div>
        </Stack>
      </CardHeader>

      {(!isEligible || isAutoEnrolled || isEmployerPaid) && (
        <section className="messages">
          {!isEligible && !isAutoEnrolled && (
            <InfoBar text={<Text data-testid="ineligible-warning" field={warningText as TextField} />} />
          )}
          {isEmployerPaid && (
            <InfoBar
              text={<RichText field="benefits_section_additional.fbs_plan_auto_enrolled_info_bar.text" />}
              color="blue"
              iconOverride={<Icon type="CheckCircle" color="--primary-blue" />}
              showAccent={false}
            />
          )}
          {isAutoEnrolled && isMoreSelectablePlans && (
            <InfoBar
              text={<RichText field="benefits_section_additional.fbs_plan_buy_up_info_bar.text" />}
              color="blue"
              iconOverride={<Icon type="PlusCircle" color="--primary-blue" />}
              showAccent={false}
            />
          )}
        </section>
      )}

      {renderCustomMessage()}

      <section className="plan-content">
        {insuranceType === 'dental' && (
          <Flex mb={2}>
            <H6 className="plan-name">
              <Text field="benefits_section_additional.in_network" />
            </H6>
          </Flex>
        )}

        <PlanContent
          plan={plan}
          builderBenefit={builderBenefit}
          insuranceType={insuranceType}
          showFbsCardDetails={showFbsCardDetails}
          employerName={employerName}
          builderCustomerKey={builderCustomerKey}
          selectedHospitalIndenmintyPlan={selectedHospitalIndenmintyPlan}
        />
      </section>

      {(!isNonSelectableBenefit || plan.pdf_url) && (
        <ButtonContainer className="button-container">
          <PlanButtons
            plan={plan}
            idx={idx}
            insuranceType={insuranceType}
            isSelected={isSelected}
            isAutoEnrolled={isAutoEnrolled}
            isEligible={isEligible}
            hasNoSelection={isNonSelectableBenefit}
            handleSelect={handleSelect}
            handleTrackPdfView={handleTrackPdfView}
            handleRemovePlan={removePlan}
          />
        </ButtonContainer>
      )}
    </PlanCard>
  );
};

export default ExpandablePlanCard;
