import { ContentfulProfileSection, PlannedProceduresSection } from 'ContentfulDefaults/types/profile';

const plannedProcedures: PlannedProceduresSection = {
  header_policyholder: 'Para los próximos 12 meses, ¿tiene alguna necesidad médica conocida?',
  header_household_member: 'Para los próximos 12 meses, ¿tienen alguna necesidad médica conocida?',
  instructions_policyholder:
    'Agregue las necesidades de cuidados médicos que contempla tener en los  próximos 12 meses.',
  instructions_household_member:
    'Agregue las necesidades de cuidados médicos que se contemplan tener en  los próximos 12 meses.',
  no_procedures_button_policyholder: 'No tengo necesidades conocidas para el próximo año.',
  no_procedures_button_household_member: 'No se tienen necesidades conocidas para el próximo año.',
  tooltips: {
    header_policyholder: {
      text: 'Esta información le ayuda a ALEX a incluir el costo de cualquier cuidado que contemple necesitar en el próximo año y a brindar recomendaciones más personalizadas.',
      title: 'Procedimientos planeados',
      underline: 'Procedimientos planeados',
    },
    header_household_member: {
      text: 'Esta información le ayuda a ALEX a incluir el costo de cualquier cuidado que su familia contemple necesitar en el próximo año y a brindarle recomendaciones más personalizadas.',
      title: 'Procedimientos planeados',
      underline: 'Procedimientos planeados',
    },
    inpatient_procedures: {
      text: 'Una cirugía con hospitalización requiere al menos una noche de estancia en el hospital después del procedimiento.',
      title: 'Cirugías para pacientes hospitalizados',
      underline: 'Cirugías para pacientes hospitalizados',
    },
    outpatient_procedures: {
      text: 'Una cirugía ambulatoria es un procedimiento que se realiza en un día, sin necesidad de pasar la noche en el hospital.',
      title: 'Cirugías ambulatorias',
      underline: 'Cirugías ambulatorias',
    },
    routine_therapy_visits_policyholder: {
      text: '¿Cuántas consultas de fisioterapia espera necesitar en los próximos 12 meses? Incluya cualquier consulta con el quiropráctico.',
      title: 'Fisioterapia (incluyendo cuidados quiroprácticos)',
      underline: 'Fisioterapia (incluyendo cuidados quiroprácticos)',
    },
    routine_therapy_visits_household_member: {
      text: '¿Cuántas consultas de fisioterapia espera necesitar en los próximos 12 meses? Incluya cualquier consulta con el quiropráctico.',
      title: 'Fisioterapia (incluyendo cuidados quiroprácticos)',
      underline: 'Fisioterapia (incluyendo cuidados quiroprácticos)',
    },
    mental_health_visits_policyholder: {
      text: '¿Cuántas veces espera tener consultas con un proveedor de salud mental (por ejemplo, terapeuta, psiquiatra o psicólogo) en los próximos 12 meses?',
      title: 'Consultas de salud mental',
      underline: 'Consultas de salud mental',
    },
    mental_health_visits_household_member: {
      text: '¿Cuántas veces espera tener consultas con un proveedor de salud mental (por ejemplo, terapeuta, psiquiatra o psicólogo) en los próximos 12 meses?',
      title: 'Consultas de salud mental',
      underline: 'Consultas de salud mental',
    },
  },
};

export const profile: {
  profile_section: ContentfulProfileSection;
} = {
  profile_section: {
    contentful_name: 'Profile Section (DEFAULT DO NOT CHANGE)',
    navigation: {
      risk: 'Riesgo',
      income: 'Ingresos',
      member: 'Usted y familia',
      pregnancy: 'El embarazo',
      medication: 'Recetas',
      pillContent: {
        risk: ['Escaso', 'Media', 'Alto'],
        pregnant: 'Bebé',
      },
      utilization: 'Utilización',
      capacityToPay: 'Capacidad de pago',
    },
    page_header: {
      text: 'Complete su perfil',
      text_return_user: 'Update Your Profile',
      subtext: 'Aquí hay un par de preguntas rápidas para que podamos llegar a conocerte.',
      subtext_return_user:
        'Revisa tus respuestas y realiza cualquier actualización para encontrar tus mejores beneficios.',
    },
    button_text: {
      risk: 'Seguir',
      income: 'Seguir',
      member: {
        myself: ' El seguro es solo para mí',
        meAndChild: 'El seguro es para mí y mi hijo(a)',
        meAndOthers: 'El seguro es para mí y otras {x} personas',
        meAndSpouse: 'El seguro es para mí y mi cónyuge',
        meAndOthersMobile: 'El seguro es para mí y otras {x} personas',
      },
      capacity: 'Seguir',
      pregnancy: {
        N: 'No',
        Y: 'Si',
      },
      scroll_up: 'Corregir un error anterior: {x}',
      memberCard: 'Salvar',
      scroll_down: 'Desplazarse hacia abajo',
      utilization: 'Muéstrame mis resultados',
      prescriptions: {
        soloOne: 'Tomo 1 medicamento prescrito',
        soloMany: 'Tomo {x} medicamentos prescritos',
        soloNone: 'No tomo ningún medicamento prescrito',
        familyOne: 'Mi familia toma 1 medicamento prescrito',
        familyMany: 'Mi familia toma {x} medicamentos prescritos',
        familyNone: 'Mi familia no toma ningún medicamento prescrito',
      },
      people_like_you: 'Seguir',
      close_button: 'Cerrar',
    },
    location_heading: ' ¿Dónde vives?',
    member_headings: {
      others: '¿Quién estará cubierto en su plan?',
      single: '¿Quién estará cubierto en su plan?',
    },
    member_description:
      'Usaremos esta información para identificar los planes de salud y las primas que están disponibles para usted, y también comenzaremos a obtener un estimado de cuánto costará cada uno de sus planes de seguro médico.',
    member_card_data: {
      person: {
        spouse: 'Cónyuge / pareja',
        dependent: 'Dependiente',
        policyholder: 'Tu',
        policyholder_enter: 'Agrega tu info',
      },
      ageTitle: 'Años',
      sexTitle: 'Sexo',
      zipTitle: 'Código postal',
      sexOptions: {
        F: 'Hembra',
        M: 'Varón',
      },
      stateTitle: 'Estado',
      tobaccoUse: 'Usa tabaco',
      tobaccoInfo: 'Puede estar sujeto a un recargo por tabaco en algunos de sus beneficios elegibles.',
    },
    individual_only:
      'Nota: En base a sus respuestas a las preguntas de elegibilidad, los planes disponibles para usted no cubren a ningún dependiente.',
    pregnancy_heading: '¿Su familia está planeando un embarazo para el próximo año?',
    pregnancy_description:
      'Los embarazos son caros. Tendremos esta información en cuenta para ofrecerle recomendaciones más precisas para las necesidades médicas de su familia del próximo año.',
    pregnancy_illustration:
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/walking-baby.svg',
    income_heading: '¿Cuál es el ingreso familiar combinado de su familia?',
    income_description:
      'Algunos de sus beneficios vienen con exenciones fiscales. Usaremos sus ingresos para calcular el valor de esas exenciones fiscales.',
    tax_heading:
      'Estas siguientes preguntas nos ayudan a calcular los beneficios fiscales relacionados con el seguro médico.',
    tax_description:
      'Algunos de sus beneficios vienen con exenciones fiscales. Usaremos su estado de declaración de impuestos e ingresos familiares para calcular el valor de esas exenciones fiscales.',
    tax_questions_text: {
      filingStatus: {
        title: '¿Cuál es su estado civil?',
        options: {
          single: 'Soltero',
          default: 'Select Filing Status',
          married: 'Casado',
          headOfHousehold: 'Cabeza de familia',
          marriedFilingSeparate: 'Casado declarando por separado',
        },
      },
      spouseIncome: {
        title: '¿Cuál es el ingreso anual de su cónyuge antes de impuestos?',
      },
      policyholderIncome: {
        title: '¿Cuál es su ingreso anual antes de impuestos?',
      },
    },
    income_illustration:
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/income-illustration.svg',
    prescriptions_heading: '¿Qué medicamentos toman usted o su familia?',
    prescriptions_headings: {
      self: '¿Qué recetas tomas?',
      family: '¿Qué recetas toma su familia?',
      prescription_warning_text:
        '{x} receta(s) agregada. Asegúrese de agregar todas las recetas antes de continuar.',
    },
    prescriptions_description:
      'Al informarnos sobre los medicamentos prescritos que toma habitualmente, podemos usar esos datos para proporcionarle recomendaciones más personalizadas que tomen en cuenta los costos y riesgos de las personas que toman medicamentos similares.',
    prescriptions_illustration:
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/medication.svg',
    drug_search_text: {
      isLoading: 'Cargando...',
      mobileAdd: '+ Añadir medicamento prescritos',
      placeholder: ' Búsqueda para agregar medicamentos prescritos...',
      noResultsFound: 'No se encontraron resultados.',
      noPrescriptions: 'No se encontraron medicamentos prescritos.',
    },
    risk_heading: 'Díganos qué tipo de plan de salud prefiere en el escenario siguiente.',
    risk_description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Usaremos su respuesta para tener una idea de su aversión al riesgo. La aversión al riesgo describe la comodidad con la que se enfrenta al riesgo, y tendremos en cuenta estas preferencias cuando evaluemos qué beneficios son mejores para usted.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    questions_text: {
      questionOne: {
        title:
          'Digamos que usted tiene una baja probabilidad de necesitar atención médica costosa en el próximo año. ¿Cuál de estos planes elegiría?',
        optionOne: {
          footer: 'Luego pagar menos de mi bolsillo si necesito atención médica costosa e inesperada.',
          heading: 'Pagar más cada mes por mi cobertura médica.',
        },
        optionTwo: {
          footer: 'Luego pagar más de mi bolsillo si necesito atención médica costosa e inesperada.',
          heading: 'Pagar menos cada mes por mi cobertura médica.',
        },
      },
      questionTwo: {
        title:
          'Ahora, qué sucede si su probabilidad de lesionarse y requerir atención médica costosa aumenta al 50% (posibilidad media). ¿Cuál de estos planes elegiría?',
        optionOne: {
          footer: 'Luego pagar menos de mi bolsillo si necesito atención médica costosa e inesperada.',
          heading: 'Pagar más cada mes por mi cobertura médica.',
        },
        optionTwo: {
          footer: 'Luego pagar más de mi bolsillo si necesito atención médica costosa e inesperada.',
          heading: 'Pagar menos cada mes por mi cobertura médica.',
        },
      },
    },
    risk_aversion_info: {
      low: {
        text: 'Parece que tiene un BAJO nivel de aversión al riesgo. Esto significa que usted está dispuesto a enfrentar más riesgo si se le brinda la mejor oportunidad de ahorrar dinero.',
        header: 'Aversión de bajo riesgo',
      },
      high: {
        text: ' Parece que tiene un ALTO nivel de aversión al riesgo. Esto significa que usted está dispuesto a pagar más por un plan que ofrece más cobertura.',
        header: 'Aversión de alto riesgo',
      },
      medium: {
        text: 'Parece que tiene un nivel MEDIO de aversión al riesgo. Esto significa que usted está buscando un equilibrio entre la protección de riesgos y el ahorro.',
        header: 'Aversión a riesgo medio',
      },
    },
    capacity_heading:
      'Si necesita recibir atención médica inesperada, ¿cuál es el monto más grande que puede pagar?',
    capacity_description:
      'Usaremos esta información para ajustar las recomendaciones de su plan en función de la probabilidad de que cada plan lo exponga a facturas médicas que no pueda pagar.',
    cost_thresholds: {
      lt_500: 'Menos de $ 500',
      gt_6000: '$ 6,000 o más',
      '500_1000': '$500 - $999',
      '1000_2000': '$1,000 - $1,999',
      '2000_3000': '$2,000 - $2,999',
      '3000_4000': '$3,000 - $3,999',
      '4000_6000': '$4,000 - $5,999',
    },
    utilization_heading:
      'Ahora le pediremos que responda algunas preguntas sobre su uso de atención médica en los últimos 12 meses.',
    utilization_description:
      'Usaremos esto para refinar aún más nuestras predicciones de los costos de atención médica que es probable que enfrente el próximo año. Si bien, a menudo sucede que las necesidades de atención médica de una persona varían de un año a otro, conocer su uso anterior de atención médica nos ayuda a proporcionar recomendaciones más personalizadas.',
    util_questions: {
      pcpVisit:
        ' ¿Cuántas veces usted {x} visitaron a su proveedor de atención primaria (excluyendo su examen físico de rutina) en los últimos 12 meses?',
      specialVisit: '¿Cuántas veces usted {x} visitaron a un especialista en los últimos 12 meses?',
      hospitalVisit:
        '¿Cuántos días estuvieron usted {x} ingresados en un hospital (excluyendo los ingresos hospitalarios por parto) en los últimos 12 meses?',
      indvAndFamily: 'o su familia',
      mentalHealthVisit: '',
    },
    error_text: {
      invalidZipCodeInput: 'Incorrecto',
      invalidEligibilityInput: '"{x}" no es una entrada válida para esta pregunta.',
    },
    card_names: {
      risk: 'tolerancia al riesgo',
      income: 'ingresos',
      capacity: 'Asequibilidad',
      location: 'residencia',
      household: 'Hogar',
      pregnancy: 'embarazo',
      eligibility: 'elegibilidad',
      utilization: 'utilización',
      prescription: 'recetas médicas',
      enrollment: '',
      incentives: '',
    },
    eligibility_heading: '',
    eligibility: {
      contentful_name: '[DEFAULT] Eligibility Card',
      name: 'Elegibilidad',
      is_full_time_or_part_time: 'Are you a full-time or part-time employee?',
      annual_policyholder_salary: 'What is your employee salary?',
      occupation: 'Which best describes you?',
      tooltips: {
        name: {
          text: 'Utilizamos estas preguntas para determinar qué planes son elegibles para usted',
          title: 'Elegibilidad',
          underline: 'Elegibilidad',
        },
      },
    },
    enrollment: {
      contentful_name: '[DEFAULT] Enrollment Card',
      name: 'Enrollment',
      enrollment_reason_question: 'What best describes your situation?',
      enrollment_reason_open_enrollment: "I'm here for open enrollment.",
      enrollment_reason_new_hire: "I'm a new hire.",
      enrollment_reason_qle: 'I had a qualifying life event.',
      enrollment_reason_info_current_year: "I need info about this year's benefits.",
      enrollment_reason_info_next_year: "I need info about next year's benefits.",
      tooltips: {
        name: {
          text: 'A qualifying life event is a major life change that allows you to make adjustments to your benefits outside of the regular enrollment period, including getting married or divorced, adding a child to your family, or changes in residence.',
          title: 'Enrollment',
          underline: 'Enrollment',
        },
      },
    },
    location: {
      contentful_name: '[DEFAULT] Location Card',
      name: 'Residencia',
      header: '¿Dónde reside?',
      zipcode_label: 'Código postal',
      zipcode_placeholder: '11111',
      state_label: 'Estado',
      state_placeholder: 'Choose...',
      tooltips: {
        name: {
          text: 'Tomamos en cuenta su ubicación a la hora de predecir sus gastos de bolsillo. En algunos casos, podemos necesitar esta información para determinar qué planes son elegibles para usted.',
          title: 'Residencia',
          underline: 'Residencia',
        },
      },
    },
    member: {
      contentful_name: '[DEFAULT] Member Card',
      name: 'Hogar',
      policyholder_card_header: 'Usted',
      spouse_card_header: 'Cónyuge / pareja',
      dependent_card_header: 'Dependiente',
      domestic_partner_card_header: 'Cónyuge / pareja',
      age_label: 'Años',
      tobacco_label: 'Usa tabaco',
      tobacco_header: 'Do {x} use any tobacco products?',
      spouse_label: 'Cónyuge',
      domestic_partner_label: 'Pareja doméstico',
      domestic_partner_question_label: '¿Tiene un cónyuge o una pareja doméstica?',
      prescription_header: 'Agregue las recetas de rutina. Omita si no se toma ninguna.',
      no_prescription_button: '{x} no toma ningún medicamento recetado',
      utilization_header: 'Agregue el uso de cuidados médicos durante los últimos 12 meses.',
      planned_procedures: plannedProcedures,
      no_utilization_button: '{x} no tuvo ninguna consulta/admisiones',
      drug_search_placeholder: 'Search to add prescriptions',
      utilization_instructions: 'Agregue el uso de cuidados médicos durante los últimos 12 meses.',
      pcp_exclusions: 'Excluyendo los exámenes físicos de rutina.',
      hospital_exclusions: 'Excluyendo las admisiones por parto.',
      coverage_text: {
        error: 'Inválido {x}',
        myself: 'Tiene cobertura sólo para usted',
        incomplete: 'Campos faltantes para {x}',
        meAndChild: 'Sólo se está cubriendo a si mismo(a) y a un hijo',
        meAndOthers: 'Cobertura para usted mismo y para {x} miembros de su familia.',
        meAndSpouse: 'Cobertura para usted mismo y para su cónyuge/pareja',
        meAndOthersMobile: 'Estoy cubriendo a {x} personas',
        meAndDomesticPartner: 'Cubriendo a usted y a su pareja',
        meAndSpouseDomesticPartner: 'Cubriendo a usted y a su cónyuge/pareja',
      },
      tooltips: {
        sex: {
          text: 'Sus necesidades personales de cuidados médicos pueden estar relacionadas con su sexo al nacer. Responder a esta pregunta nos permite ofrecer recomendaciones personalizadas que tengan en cuenta este factor.',
          title: 'Sexo al nacer',
          underline: 'Sexo al nacer',
        },
        name: {
          text: 'Utilizaremos esta información para identificar los planes de salud y las primas que están disponibles para usted, y también empezaremos a tener una idea de lo que costará cada uno de sus planes de seguro médico.',
          title: 'Hogar',
          underline: 'Hogar',
        },
        tobacco: {
          text: 'Puede estar sujeto a un recargo por tabaco en algunos de sus beneficios elegibles.',
          title: 'Usa tabaco',
          underline: 'Usa tabaco',
        },
        pcp_visits: {
          text: '¿Cuántas veces ha visitado a su médico de cabecera (excluyendo el examen físico de rutina) en los últimos 12 meses?',
          title: 'Visitas al PCP',
          underline: 'Visitas al PCP',
        },
        utilization: {
          text: 'Usaremos esto para refinar aún más nuestras predicciones de los costos de atención médica que es probable que enfrente el próximo año. Si bien, a menudo sucede que las necesidades de atención médica de una persona varían de un año a otro, conocer su uso anterior de atención médica nos ayuda a proporcionar recomendaciones más personalizadas.',
          title: 'Utilización',
          underline: 'Utilización',
        },
        prescriptions: {
          text: 'Si nos informa sobre los medicamentos recetados que toma habitualmente, podemos utilizar esa información para ofrecerle recomendaciones más personalizadas que tengan en cuenta los gastos y los riesgos de las personas que toman recetas similares.',
          title: 'Recetas',
          underline: 'Recetas',
        },
        hospital_visits: {
          text: '¿Cuántos días permaneció en un hospital (excluyendo las admisiones por parto) en los últimos 12 meses?',
          title: 'Admisiones hospitalarias',
          underline: 'Días en el hospital',
        },
        specialist_visits: {
          text: '¿Cuántas veces visitó a un especialista en los últimos 12 meses?',
          title: 'Consultas con especialistas',
          underline: 'Consultas con especialistas',
        },
      },
      add_policyholder_button: 'Agréguese usted mismo',
      add_spouse_button: 'Agregue cónyuge / pareja',
      add_spouse_or_domestic_partner_button: 'Agregue cónyuge / pareja',
      add_dependent_button: 'Agregue dependiente',
      accessibility: {
        pcp_counter: 'Número de visitas al PCP (Médico de cabecera)',
        remove_spouse: 'Eliminar cónyuge',
        add_drug_alert: 'Se agregó {x}',
        remove_dependent: 'Eliminar dependiente',
        inpatient_counter: 'Número de días de hospitalización',
        remove_drug_alert: 'Eliminado {x]',
        remove_drug_button: 'Eliminar {x}',
        specialist_counter: 'Número de visitas a especialistas',
        remove_spouse_alert: 'Cónyuge eliminado',
        remove_dependent_alert: 'Dependiente eliminado',
        drug_search_results_alert: '{x] resultados',
        mental_health_counter: '(TODO: Spanish)Number of mental health provider visits',
      },
      button_options: {
        no: 'No',
        yes: 'Si',
        male: 'Masculino',
        female: 'Femenina',
        prefer_not_to_say: 'Prefiero no decir',
      },
      add_member_disclaimer: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Ingrese a todos los miembros del hogar que desea cubrir con cualquiera de sus beneficios. Tendrá la oportunidad de elegir a quién cubrirá en cada plan más adelante.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
      drug_search_label: 'Agregar prescripciones',
      pcp_visit_labels: {
        contentful_name: '',
        zero_visits_label: '0 visitas',
        one_visit_label: '1 visita',
        two_to_four_visits_label: '2 a 4 visitas',
        five_or_more_visits_label: '5 o más visitas',
      },
      inpatient_labels: {
        contentful_name: '',
        zero_days_label: '0 días',
        one_to_two_days_label: '1 a 2 días',
        three_or_more_days_label: '3 o más días',
      },
      specialist_visit_labels: {
        contentful_name: '',
        zero_visits_label: '0 visitas',
        one_to_six_visits_label: '1 a 6 visitas',
        seven_to_twelve_visits_label: '7 a 12 visitas',
        thirteen_or_more_visits_label: '13 o más visitas',
      },
      text_note: {
        plan_year: 2024,
        short_text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value: 'We can include a custom text note here up to 140 characters (2024)!',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
        long_text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              content: [
                {
                  nodeType: 'text',
                  value: 'Additional information can go here - up to 5000 characters!',
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
        button_text: 'Read more',
      },
      text_notes: [
        {
          short_text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'text note short text',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          long_text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'long text text note',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          button_text: 'Read More',
        },
      ],
    },
    pregnancy: {
      contentful_name: '[DEFAULT] Pregnancy Card',
      name: 'El embarazo',
      header: '¿Su familia está planeando un embarazo para el próximo año?',
      button_options: {
        no: 'No',
        yes: 'Si',
      },
      tooltips: {
        name: {
          text: 'Los embarazos son caros. Tendremos esta información en cuenta para ofrecerle recomendaciones más precisas para las necesidades médicas de su familia del próximo año.',
          title: 'El embarazo',
          underline: 'El embarazo',
        },
      },
    },
    risk: {
      contentful_name: '[Default] Risk Card',
      name: 'Aversión al riesgo',
      tooltips: {
        name: {
          text: 'Utilizaremos su respuesta para tener una idea de su grado de aversión al riesgo. La aversión al riesgo describe lo cómodo que se siente ante el riesgo, y tendremos en cuenta estas preferencias cuando evaluemos qué beneficios son los mejores para usted.',
          title: 'Aversión al riesgo',
        },
      },
      header_question_a:
        'Digamos que usted tiene una baja probabilidad de necesitar atención médica costosa en el próximo año. ¿Cuál de estos planes elegiría?',
      header_question_b:
        'Ahora, qué sucede si su probabilidad de lesionarse y requerir atención médica costosa aumenta al 50% (posibilidad media). ¿Cuál de estos planes elegiría?',
      option_one_header: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: 'Pagar más cada mes por mi cobertura médica.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      option_one_text: 'Luego pagar menos de mi bolsillo si necesito atención médica costosa e inesperada.',
      option_two_header: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Pagar menos cada mes por mi cobertura médica.',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                data: {},
              },
            ],
          },
        ],
      },
      option_two_text: 'Luego pagar más de mi bolsillo si necesito atención médica costosa e inesperada.',
      risk_aversion_info: {
        low: {
          text: 'Parece que tiene un BAJO nivel de aversión al riesgo. Esto significa que usted está dispuesto a enfrentar más riesgo si se le brinda la mejor oportunidad de ahorrar dinero.',
          header: 'Aversión de bajo riesgo',
        },
        high: {
          text: ' Parece que tiene un ALTO nivel de aversión al riesgo. Esto significa que usted está dispuesto a pagar más por un plan que ofrece más cobertura.',
          header: 'Aversión de alto riesgo',
        },
        medium: {
          text: 'Parece que tiene un nivel MEDIO de aversión al riesgo. Esto significa que usted está buscando un equilibrio entre la protección de riesgos y el ahorro.',
          header: 'Aversión a riesgo medio',
        },
      },
    },
    incentives: {
      contentful_name: '[DEFAULT] Incentives Card',
      name: 'Incentives',
      tooltips: {
        name: {
          text: 'TODO - FIX THIS',
          title: 'TODO - FIX THIS',
          underline: 'TODO - FIX THIS',
        },
      },
      success_custom_messages: [
        {
          contentful_name: '[DEFAULT] Incentives Success Message',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Based on your profile answers above, your employer offers the following incentives which apply to you. Please answer these additional questions to determine if you’re eligible.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'blue',
          icon: 'Info',
        },
        {
          contentful_name: '[DEFAULT] Incentives Profile Change Notice',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Changing your profile answers above may cause these questions to reset.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'yellow',
          icon: 'Info',
        },
      ],
      empty_custom_messages: [
        {
          contentful_name: '[DEFAULT] No Incentives Eligible',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      "Based on answers to the profile questions above, it doesn't look like you are eligible for any incentives at this time.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'yellow',
          icon: 'Info',
        },
      ],
      incentives: [
        {
          contentful_name: '[test-dan-1] Incentive - 10000',
          plan_id: '10000',
          title: 'HSA or HRA Incentive 1',
          question: 'How much will you contribute?',
          question_spouse: 'How much will you and your spouse contribute?',
          description: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Single. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          description_spouse: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'With Spouse. Mi sit amet mauris commodo quis imperdiet massa. Elementum curabitur vitae nunc sed velit dignissim sodales. Elit sed vulputate mi sit amet mauris. Massa tincidunt nunc pulvinar sapien et ligula. Aliquam ut porttitor leo a.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        {
          contentful_name: '[test-dan-1] Incentive - 12345',
          plan_id: '12345',
          title: 'HSA or HRA Incentive 2',
          question: 'Will you participate in this program?',
          question_spouse: 'Will you and your spouse participate in this program?',
          description: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Single. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          description_spouse: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value:
                      'With Spouse. Mi sit amet mauris commodo quis imperdiet massa. Elementum curabitur vitae nunc sed velit dignissim sodales. Elit sed vulputate mi sit amet mauris. Massa tincidunt nunc pulvinar sapien et ligula. Aliquam ut porttitor leo a.',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
        {
          contentful_name: '[test-dan-1] Incentive - 67890',
          plan_id: '67890',
          title: 'HSA or HRA Incentive 3',
          question: 'Will you or your spouse participate in this program?',
          question_spouse: 'Will you or your spouse participate in this program?',
          description: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Single. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          description_spouse: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value:
                      'With Spouse. Mi sit amet mauris commodo quis imperdiet massa. Elementum curabitur vitae nunc sed velit dignissim sodales. Elit sed vulputate mi sit amet mauris. Massa tincidunt nunc pulvinar sapien et ligula. Aliquam ut porttitor leo a.',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    income: {
      contentful_name: '[DEFAULT] Income Card',
      name: 'Ingresos',
      tax_inputs_header:
        'Estas siguientes preguntas nos ayudan a calcular los beneficios fiscales relacionados con el seguro médico.',
      tooltips: {
        name: {
          text: 'Algunos de sus beneficios vienen acompañados de reducciones de impuestos. Utilizaremos sus ingresos para calcular el valor de esas reducciones de impuestos.',
          title: 'Ingresos',
          underline: 'Ingresos',
        },
      },
      income_header: '¿Cuál es el ingreso familiar combinado de su familia?',
      income_header_policyholder: '¿Cuál es su ingreso anual antes de impuestos?',
      income_placeholder: '50,000',
      income_header_spouse: '¿Cuál es el ingreso anual de su cónyuge antes de impuestos?',
      income_placeholder_spouse: '50,000',
      filing_status_header: '¿Cuál es su estado civil?',
      filing_status_options: {
        default: 'Seleccionar estado civil',
        options: ['Solterro', 'Casado', 'Casado declarando por separado', 'Cabeza de familia'],
      },
      skip_income_button: 'Prefiero no decir',
    },
    capacity_to_pay: {
      contentful_name: '[DEFAULT] Capacity to Pay Card',
      name: 'Asequibilidad',
      header: 'Si necesita recibir atención médica inesperada, ¿cuál es el monto más grande que puede pagar?',
      button_options: {
        '500_1000': '$500 - $999',
        '1000_2000': '$1,000 - $1,999',
        '2000_3000': '$2,000 - $2,999',
        '3000_4000': '$3,000 - $3,999',
        '4000_6000': '$4,000 - $5,999',
        lt_500: 'Menos de $ 500',
        gt_6000: '$ 6,000 o más',
      },
      tooltips: {
        name: {
          text: 'Utilizaremos esta información para ajustar sus recomendaciones de planes en función de la probabilidad de que cada plan le exponga a facturas médicas que no pueda pagar.',
          title: 'Asequibilidad',
          underline: 'Asequibilidad',
        },
      },
    },
    profile_complete_header: '¡Perfil completo!',
    profile_complete_body:
      'Asegúrese de que sus respuestas son correctas para que podamos ofrecerle las mejores recomendaciones. Vuelva a esta página para actualizar su perfil.',
    accessibility: {
      instructions:
        'Parece que está usando un lector de pantalla. A medida que responda las preguntas, habrá más contenido disponible.',
      unlocked_section: '{x} sección desbloqueada',
    },
    domestic_partner_disclaimer: {},
    audio_configuration: {
      audio_configuration_title: 'Default Audio Configuration',
      profile_start_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      profile_complete_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      residence_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      household_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      income_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      risk_aversion_section_audio: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      enrollment_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      residence_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      household_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      sex_at_birth_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      prescription_drugs_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      utilization_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      pcp_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      specialist_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      hospital_stays_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      pregnancy_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      income_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      risk_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      affordability_section_audio_tooltip: {
        audio_moment_title: '',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7y6P3MDD5CgykXQcHqJ2rb/948a4f7a1b0a64ed2f64ddf7ac0b9b02/quite-well.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3s5afVjEt6K9aKlEJuQqKH/e2b5e915935eb25517c6620062d7729c/quite-well.vtt',
      },
      planned_procedures_question_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Question',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3xbHI4BwRckidkg5OyxsLj/9ef58638020c9ccec4e35ebd0465d31a/Polished_Operations.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/2GiXdymvL5JIJrw7panh9v/66e8e3fa500288f1d31f822061b8afd0/1_P.vtt',
      },
      planned_procedures_inpatient_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Inpatient Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/2ZEFMDun7NlhITOYc4w2sf/be740490eb1ccd24649905660ae342ce/Polished_Operations__1_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/3NGJVbGjPyUBU3SlEwUf9z/7f1715cf3bd035af2a546efd64ec874d/2_P.vtt',
      },
      planned_procedures_outpatient_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Outpatient Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/7JKIjDVMCOmqLzo86nqbdt/4f16f1f7d2061a087f5a286f663f22a9/Polished_Operations__2_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/jN5EccMBkI8dYx0w5iB5o/0e221efcde8c4a75cf589e2cf7292d7f/3_P.vtt',
      },
      planned_procedures_routine_therapy_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Therapy Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/5Umm9xPNlbxPX7oGyxak30/e71b2d08f41e742304c6d450e106c9b5/Polished_Operations__3_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/5g2VFqk8FVx6tlwEMyvWaF/444a2aaa0a7f8e6ac5b23be094b9997c/4_P.vtt',
      },
      planned_procedures_mental_health_audio_tooltip: {
        audio_moment_title: '[Default] Planned Procedures Mental Health Tooltip',
        audio_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/63O47rHjKfSQuJnzoG0qiO/b0e8812b34eb8ffdad38a0d8cfa62a9d/Polished_Operations__4_.wav',
        vtt_transcript_file_url:
          'https://assets.ctfassets.net/z0ugtcgrmslx/5cQ2HwxPkLbydBlw1dAU8B/ad66f4e4d2357f7d465b18d0f889df13/5_P.vtt',
      },
    },
  },
};

export default profile;
