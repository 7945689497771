import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { HEAD_OF_HOUSEHOLD, MARRIED, MARRIED_FILING_SEPARATELY, SINGLE } from 'Containers/App/constants';
import { Member, UpdateMemberFields } from 'Containers/ProfilePage/types';
import { IncomeCard } from 'ContentfulDefaults/types/profile';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import Checkbox from 'DesignLibrary/atoms/inputs/Checkbox';
import SelectInput from 'DesignLibrary/atoms/inputs/SelectInput';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import LabelInput from 'DesignLibrary/molecules/LabelInput';

import Question from '../_shared/Question';
import { QuestionWrapper } from '../_shared/Question/styled';
import { AudioConfigTooltipContentfulPaths } from '../ProfileAudio';

// Private Interfaces
const filingStatusValues = ['', 'single', 'married', 'married_separately', 'head_of_household'];
export type FilingStatus = (typeof filingStatusValues)[number];

export interface IncomeSectionProps {
  policyholder: Member;
  spouse: Member;
  filingStatus: FilingStatus;
  collectTaxInputs: boolean;
  isDirty: boolean;
  isProfileAudioEnabled: boolean;
  hiddenQuestionIds: string[];
  handleSaveFilingStatus: (value: FilingStatus) => void;
  handleEditPolicyholder: (update: UpdateMemberFields) => void;
  handleEditSpouse: (update: UpdateMemberFields) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
  isSkipIncomeEnabled: boolean;
}

const FILING_STATUSES = [
  {
    key: 'single',
    value: SINGLE,
  },
  {
    key: 'married',
    value: MARRIED,
  },
  {
    key: 'marriedFilingSeparate',
    value: MARRIED_FILING_SEPARATELY,
  },
  {
    key: 'headOfHousehold',
    value: HEAD_OF_HOUSEHOLD,
  },
];

const IncomeSection = ({
  policyholder,
  spouse,
  filingStatus,
  collectTaxInputs,
  isDirty,
  isProfileAudioEnabled,
  hiddenQuestionIds,
  handleSaveFilingStatus,
  handleEditPolicyholder,
  handleEditSpouse,
  handleSectionChange,
  setHiddenAlert,
  isSkipIncomeEnabled,
}: IncomeSectionProps) => {
  const [state, setState] = useState({
    policyholderIsDirty: false,
    spouseIsDirty: false,
  });

  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();
  const { is_hide_eligibility_questions_enabled } = useFeatureFlagContext();

  const profileCard = retrieveContentfulData<IncomeCard>('profile_section.income');

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, profileCard.name));
  }, []);

  useEffect(() => {
    const section = getSectionFromMap('income');
    const isComplete = section?.isComplete || validateForm();
    handleSectionChange(validateForm(), isComplete);

    return () => {
      // Reset spouse income if filing status is changed to single or HoH
      if ([SINGLE, HEAD_OF_HOUSEHOLD].includes(filingStatus) && spouse.income !== '') {
        handleEditSpouse({ income: '' });
      }
    };
  }, [policyholder.income, policyholder.isSkipIncome, spouse.income, filingStatus]);

  const handleIncomeChange = (e, relationship) => {
    const value = e.target.value.replace(/,/g, ''); // revert comma separated value

    if (Number.isInteger(parseInt(value, 10)) || value === '') {
      // only change value if value can be parsed as an integer
      if (relationship === 'policyholder') {
        handleEditPolicyholder({ income: value });

        setState({
          ...state,
          policyholderIsDirty: true,
        });
      } else {
        handleEditSpouse({ income: value });

        setState({
          ...state,
          spouseIsDirty: true,
        });
      }
    }
  };

  const handleFilingStatus = (e) => {
    const value = e.target.value;
    handleSaveFilingStatus(value);
  };

  function validateForm() {
    let isValid = (policyholder.income !== '0' && policyholder.income !== '') || policyholder.isSkipIncome;

    if ([MARRIED, MARRIED_FILING_SEPARATELY].includes(filingStatus)) {
      isValid =
        (policyholder.income !== '' || policyholder.isSkipIncome) &&
        (spouse.income !== '' || spouse.isSkipIncome);
    }

    return isValid;
  }

  const isIncomplete = !getSectionFromMap('income')?.isComplete;

  const policyholderLabelId = 'policyholder-income-input-label';
  const spouseLabelId = 'spouse-income-input-label';
  const filingStatusLabelId = 'select-filing-status';

  const handleSkipIncome = () => {
    if (!policyholder.isSkipIncome) {
      handleEditPolicyholder({ isSkipIncome: true, income: '0' });
    } else {
      handleEditPolicyholder({ isSkipIncome: false });
    }
  };

  if (hiddenQuestionIds.includes('income') && is_hide_eligibility_questions_enabled) {
    return null;
  }

  return (
    <div id="income-section" data-testid="income-section">
      <Question
        error={!validateForm() && isDirty}
        name="income"
        isIncomplete={isIncomplete}
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.IncomeSectionAudioTooltip}
      >
        <QuestionWrapper>
          {!collectTaxInputs ? (
            <>
              <Paragraph id={policyholderLabelId}>{profileCard?.income_header}</Paragraph>
              <Flex flexDir={['column', 'row']} gap={[4, 8]} align={['flex-start', 'center']}>
                <TextInput
                  error={!validateForm() && isDirty}
                  labelId={policyholderLabelId}
                  data-testid="policyholder-income-input"
                  className="pingdom-income-input"
                  inputType="currency"
                  inputMode="numeric"
                  value={policyholder.isSkipIncome || policyholder.income === '0' ? '' : policyholder.income}
                  iconType="Search"
                  handleChange={(e) => handleIncomeChange(e, 'policyholder')}
                  type="tel"
                  lang="en-150"
                  name="policyholderIncome"
                  placeholder={profileCard?.income_placeholder}
                  disabled={policyholder.isSkipIncome}
                />
                {isSkipIncomeEnabled && (
                  <Checkbox
                    sizing="small"
                    testId="skip-income"
                    checked={policyholder.isSkipIncome}
                    handleChange={() => handleSkipIncome()}
                  >
                    {profileCard.skip_income_button}
                  </Checkbox>
                )}
              </Flex>
            </>
          ) : (
            <>
              <Paragraph>{profileCard?.tax_inputs_header}</Paragraph>
              <LabelInput label={profileCard?.filing_status_header} id={filingStatusLabelId}>
                <SelectInput
                  name="filing"
                  data-testid="select-filing-status"
                  required
                  handleChange={handleFilingStatus}
                  placeholder={profileCard?.filing_status_options.default}
                  options={FILING_STATUSES.map((i, index) => ({
                    option: profileCard?.filing_status_options.options[index],
                    value: i.value,
                  }))}
                  selected={filingStatus}
                  labelId={filingStatusLabelId}
                />
              </LabelInput>
            </>
          )}
          {filingStatus && (
            <LabelInput id={policyholderLabelId} label={profileCard?.income_header_policyholder}>
              <TextInput
                labelId={policyholderLabelId}
                data-testid="policyholder-income-input"
                className="pingdom-income-input"
                inputType="currency"
                inputMode="numeric"
                stretch
                iconType="Search"
                handleChange={(e) => handleIncomeChange(e, 'policyholder')}
                type="tel"
                lang="en-150"
                name="policyholderIncome"
                placeholder={profileCard?.income_placeholder}
                value={policyholder.income}
              />
            </LabelInput>
          )}

          {[MARRIED, MARRIED_FILING_SEPARATELY].includes(filingStatus) && (
            <LabelInput id={spouseLabelId} label={profileCard?.income_header_spouse}>
              <TextInput
                labelId={spouseLabelId}
                data-testid="spouse-income-input"
                inputType="currency"
                inputMode="numeric"
                stretch
                iconType="Search"
                handleChange={(e) => handleIncomeChange(e, 'spouse')}
                type="tel"
                lang="en-150"
                name="spouseIncome"
                placeholder={profileCard?.income_placeholder_spouse}
                value={spouse.income}
              />
            </LabelInput>
          )}
        </QuestionWrapper>
      </Question>
    </div>
  );
};

export default IncomeSection;
