import { AlexIdUser } from 'Containers/App/types';
import { getCookie } from 'Containers/App/utils';
import { Election, EnteredPlan } from 'Containers/CommercialRoutes/types';
import { IncentiveSurveyAnswer } from 'Containers/ProfilePage/types';
import { Household, Recommendation } from 'Types/entities';

import * as API_TYPES from './apiTypes';
import { checkStatus, parseJSON } from './request';
import { BACKEND_HOST, BASE_PATH } from './urls';

const get_unified_session_id = () => getCookie('unified_session_id') || null;

function getJSON<T>(url: string): Promise<T> {
  return fetch(url).then(checkStatus).then(parseJSON) as Promise<T>;
}

export function postJSON<T>(url: string, body: any): Promise<T> {
  return fetch(url, {
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  })
    .then(checkStatus)
    .then(parseJSON) as Promise<T>;
}

export function getConfig(): Promise<API_TYPES.GetConfigResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/config/`;
  return getJSON(requestUrl);
}

export function getSupplementalPlansRequest(
  householdId: string,
  memberIds?: string[],
  healthPlanExternalId?: string,
  enrollmentEventId?: string,
): Promise<API_TYPES.GetSupplementalPlansResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/supplemental_plans/?household_id=${householdId}&unified_session_id=${get_unified_session_id()}`;

  if (memberIds && memberIds?.length > 0) {
    requestUrl += `&member_ids=${memberIds}`;
  }

  if (healthPlanExternalId) {
    requestUrl += `&health_plan_external_id=${healthPlanExternalId}`;
  }

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return getJSON(requestUrl);
}

export function enrollSupplemental(
  supplementalEnrollments: any,
  householdId: string,
  enrollmentEventId: string,
): Promise<API_TYPES.UpdateHouseholdResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/enroll/?unified_session_id=${get_unified_session_id()}`;
  const body: any = { supplemental_enrollments: supplementalEnrollments };

  if (householdId) {
    requestUrl += `&household_id=${householdId}`;
  }

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, body);
}

export function enroll(
  planId?: string,
  householdId?: string,
  enrollmentEventId?: string,
): Promise<API_TYPES.UpdateHouseholdResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/enroll/?unified_session_id=${get_unified_session_id()}`;
  const body: any = { enrollment: null };

  if (planId) {
    body.enrollment = { plan_id: planId };
  }

  if (householdId) {
    requestUrl += `&household_id=${householdId}`;
  }

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, body);
}

/**
 *
 * @param householdId (we're actually passing it the user.alex_id_uuid)
 * @param enrollmentEventId
 * @returns
 */
export function getHousehold(householdId: string, enrollmentEventId?: string): Promise<Household> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/get_household/?household_id=${householdId}&unified_session_id=${get_unified_session_id()}`;

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return getJSON(requestUrl);
}

export function getIntegratedUserProfile(user: AlexIdUser, enrollmentEventId: string) {
  if (user?.alex_id_uuid) {
    return getHousehold(user.alex_id_uuid, enrollmentEventId);
  }
  return undefined;
}

export function getHouseholdIdByExternalId(
  externalId: string,
  enrollmentEventId: string,
): Promise<Household> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/get_household_id_by_external_id/?unified_session_id=${externalId}&session_id=${get_unified_session_id()}`;

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return getJSON(requestUrl);
}

export function getLocationsByZip(zipcode: number): Promise<API_TYPES.GetLocationsResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/location_search/?zip_prefix=${zipcode}`;
  return getJSON(requestUrl);
}

export function getDrugs(query: string): Promise<API_TYPES.GetDrugsResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/drugs/?q=${query}`;
  return getJSON(requestUrl);
}

export function postIncentiveSurvey(
  householdId: string,
  enrollmentId: string,
  answers: Record<string, IncentiveSurveyAnswer>,
) {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/incentive_survey/?household_id=${householdId}&enrollment_event_id=${enrollmentId}`;
  const body = {
    incentive_answers: Object.values(answers).filter((i) => i.choice || i.expected_incentive_amount !== null),
  };
  return postJSON(requestUrl, body);
}

export function postContributions(
  enrollmentId: string,
  contributions: Record<string, API_TYPES.Contribution[]>,
) {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/contributions/?enrollment_event_id=${enrollmentId}`;

  return postJSON(requestUrl, contributions);
}

export function getRecommendations(
  householdId: string,
  coveredMemberIds: string[],
  ephemeralPlanIdAndPlan?: {
    ephemeralPlanId: string;
    ephemeralPlan: EnteredPlan;
  },
  planSelectionId?: string,
  enrollmentEventId?: string,
  publicationId?: string,
): Promise<API_TYPES.GetRecommendationsResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/recommendations/?household_id=${householdId}&unified_session_id=${get_unified_session_id()}`;
  let body = {};
  if (ephemeralPlanIdAndPlan) {
    const { ephemeralPlanId, ephemeralPlan } = ephemeralPlanIdAndPlan;
    body = ephemeralPlan;
    requestUrl += `&ephemeral_plan_id=${ephemeralPlanId}`;
  }

  if (planSelectionId) {
    requestUrl += `&plan_selection_id=${planSelectionId}`;
  }

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  if (publicationId) {
    requestUrl += `&publication_id=${publicationId}`;
  }

  requestUrl += `&member_ids=${coveredMemberIds.join(',')}`;
  return postJSON(requestUrl, body);
}

export function getMarketplaceRecommendations(
  household: Household,
): Promise<API_TYPES.GetRecommendationsResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/marketplace/recommendations/?unified_session_id=${get_unified_session_id()}`;
  return postJSON(requestUrl, household);
}

export function getHsaRecommendations(
  selectedPlan: Recommendation,
  householdId: string,
  enrollmentEventId?: string,
): Promise<API_TYPES.GetHsaRecommendationResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/hsa/?unified_session_id=${get_unified_session_id()}`;
  const body: any = { external_id: selectedPlan.plan.external_id };

  if (householdId) {
    requestUrl += `&household_id=${householdId}`;
  }

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, body);
}

export function getMarketplaceHsaRecommendations(
  selectedPlan: Recommendation,
  household: Household,
): Promise<API_TYPES.GetHsaRecommendationResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/marketplace/hsa/`;
  const body: any = { household, hsa: selectedPlan.plan.external_id };
  return postJSON(requestUrl, body);
}

export function getRetirementForecasts(
  payload: API_TYPES.GetForecastRequestPayload,
  enrollmentEventId?: string,
): Promise<API_TYPES.GetForecastsResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/forecast_hsa/`;

  if (enrollmentEventId) {
    requestUrl += `?enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, payload);
}

export function getHsaForecasts(
  payload: API_TYPES.GetForecastRequestPayload,
  enrollmentEventId?: string,
): Promise<API_TYPES.GetForecastsResponse> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/forecast_hsa/`;

  if (enrollmentEventId) {
    requestUrl += `?enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, payload);
}

export function getFsaTaxSavings(
  householdId: string,
  payload: any,
  enrollmentEventId?: string,
): Promise<any> {
  let requestUrl = `${BACKEND_HOST}/commercial/tax_savings/?household_id=${householdId}&unified_session_id=${get_unified_session_id()}`;

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, payload);
}

export function getClientSurvey(enrollmentEventId?: string): Promise<API_TYPES.ClientSurveySchema> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/client_survey/?unified_session_id=${get_unified_session_id()}`;
  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return getJSON(requestUrl);
}

export function getClientSurveyMetadata(enrollmentEventId?: string): Promise<API_TYPES.ClientSurveySchema> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/client_survey/metadata/?session_id=${get_unified_session_id()}`;
  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return getJSON(requestUrl);
}

export function getPeopleLikeYou(
  householdId: string,
  enrollmentEventId?: string,
): Promise<API_TYPES.PeopleLikeYou> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/people_like_you/?household_id=${householdId}&unified_session_id=${get_unified_session_id()}`;

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return getJSON(requestUrl);
}

export function getMarketplacePeopleLikeYou(household: Household): Promise<API_TYPES.PeopleLikeYou> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/marketplace/people_like_you/`;
  return postJSON(requestUrl, household);
}

export function getHealthFundIncentiveSurvey(
  householdId: string,
  enrollmentId: string,
): Promise<API_TYPES.IncentiveSurveyResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/get_incentive_survey/?household_id=${householdId}&enrollment_event_id=${enrollmentId}&unified_session_id=${get_unified_session_id()}`;

  return getJSON(requestUrl);
}

export function getIncentiveSurveyQuestion(
  household_id: string,
  enrollment_event_id: string,
  activeMembers?: string[],
): Promise<API_TYPES.IncentiveSurveyResponse> {
  const unified_session_id = get_unified_session_id();

  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/get_incentive_survey/?household_id=${household_id}&enrollment_event_id=${enrollment_event_id}&unified_session_id=${unified_session_id}`;

  if (activeMembers && activeMembers.length > 0) {
    const members = activeMembers.map((member_id) => `member_ids=${member_id}`).join('&');
    requestUrl += `&members=${encodeURIComponent(members)}`;
  }

  return getJSON(requestUrl);
}

export function apiCreateOrUpdateHousehold(
  household: Household,
  householdId: string,
  enrollmentEventId?: string,
): Promise<API_TYPES.ItemReference> {
  let requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/household/?unified_session_id=${get_unified_session_id()}`;
  if (householdId) {
    requestUrl += `&household_id=${householdId}`;
  }

  if (enrollmentEventId) {
    requestUrl += `&enrollment_event_id=${enrollmentEventId}`;
  }

  return postJSON(requestUrl, household);
}

export function apiCreateEphemeralPlan(plan: EnteredPlan): Promise<API_TYPES.EphemeralPlanResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/ephemeral_plan/?unified_session_id=${get_unified_session_id()}`;
  return postJSON(requestUrl, plan);
}

export function apiJVPCreateUser(user_id: string): Promise<API_TYPES.JVPUser> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/create_user/?user_id=${user_id}`;
  return postJSON(requestUrl, {});
}

export function apiJVPGetBootstrap(
  user_id: string,
  employer_slug: string,
  target: string,
  active_plan_date: string,
  segment_slug?: string,
): Promise<API_TYPES.JVPBootstrap> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/get_bootstrap/?user_id=${user_id}&employer_slug=${employer_slug}&active_plan_date=${active_plan_date}&target=${target}${
    segment_slug ? `&segment_slug=${segment_slug}` : ''
  }`;
  return postJSON(requestUrl, {});
}

export function apiJVPPutElections(enrollment_id: string, elections_data: Election[]): Promise<unknown> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/put_elections/?enrollment_id=${enrollment_id}`;
  return postJSON(requestUrl, elections_data);
}

export function apiJVPCreateOrUpdateEnrollmentEvent(
  employee_id: string,
  publication_id: string,
  event_type: API_TYPES.EnrollmentEventType,
  profile_complete: boolean,
  validated_segment_slug?: string,
): Promise<API_TYPES.JVPEnrollmentEvent> {
  const segmentQueryParam = validated_segment_slug ? `&segment_slug=${validated_segment_slug}` : '';
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/create_or_update_enrollment_event/?employee_id=${employee_id}&publication_id=${publication_id}&event_type=${event_type}&profile_complete=${profile_complete}${segmentQueryParam}`;
  return postJSON(requestUrl, {});
}

export function apiJVPPulseSession(session_id?: string): Promise<API_TYPES.JVPPulseSessionResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/pulse_session/?session_id=${session_id}`;
  return getJSON(requestUrl);
}

export function apiJVPGetUser(user_id: string): Promise<API_TYPES.JVPUserWithDependents> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/get_user/?user_id=${user_id}`;
  return getJSON(requestUrl);
}

export function apiAAPIGetUserBySessionId(session_id?: string) {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/get_user_by_session_id/?session_id=${session_id}`;
  return getJSON(requestUrl);
}

export function getDrugByNdc(ndc: string): Promise<API_TYPES.GetDrugDetailsResponse> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/commercial/drug_details/?ndc=${ndc}`;
  return getJSON(requestUrl);
}

export function getPreviousSelectedPlan(id: string): Promise<API_TYPES.PreviousSelectedPlan> {
  const requestUrl = `${BACKEND_HOST}${BASE_PATH}/jvp/get_elections/?enrollment_id=${id}`;
  return getJSON(requestUrl);
}

export function getEligibilityQuestions(
  publicationId: string,
): Promise<API_TYPES.GetJVPEligibilityQuestionsResponse> {
  const requestURL = `${BACKEND_HOST}${BASE_PATH}/jvp/get_eligibility_questions/?publication_id=${publicationId}`;
  return getJSON(requestURL);
}
