import React from 'react';

import { TextField } from 'Containers/App/types';
import {
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  IncentiveSurveyQuestionType,
  IncentiveType,
} from 'Containers/ProfilePage/types';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { Incentive } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { H5, Paragraph } from 'DesignLibrary/atoms';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { getIsRichTextEmpty } from 'Utils/helpers';

import { IncentiveQuestionWrapper } from './styled';

export interface IncentiveQuestionProps {
  surveyQuestion: IncentiveSurveyQuestion;
  answer: IncentiveSurveyAnswer | null;
  hasSpouse: boolean;
  handleChange: (
    id: number,
    type: IncentiveSurveyQuestionType,
    incentiveType: IncentiveType,
    val: string | number,
  ) => void;
}
export const IncentiveQuestion = ({
  surveyQuestion,
  answer,
  hasSpouse,
  handleChange,
}: IncentiveQuestionProps) => {
  const { retrieveContentfulData } = useTextContext();

  const renderContent = () => {
    const { id, question_type, question, incentive_type } = surveyQuestion;
    if (question_type === 'fill_in_the_blank') {
      return (
        <TextInput
          data-testid={`incentive-input-${id}`}
          labelId={incentiveQuestionId}
          inputType="currency"
          inputMode="numeric"
          stretch
          placeholder="100"
          handleChange={(e) => handleChange(id, question_type, incentive_type, e.target.value)}
          value={answer?.expected_incentive_amount?.toString()}
        />
      );
    }
    if (question_type === 'yes_or_no') {
      return (
        <ToggleList
          layout="column"
          groupName={incentiveQuestionId}
          label={question}
          options={[
            { option: 'Yes', value: 'employee' },
            { option: 'No', value: 'null' },
          ]}
          required
          color="--colors-gray-black"
          handleChange={(e) => handleChange(id, question_type, incentive_type, e.target.value)}
          selected={answer?.choice === null ? 'null' : answer?.choice || ''}
          stretch
        />
      );
    }
    if (question_type === 'yes_or_no_include_spouse') {
      return (
        <ToggleList
          layout="column"
          groupName={incentiveQuestionId}
          label={question}
          options={[
            { option: 'Employee', value: 'employee' },
            { option: 'Employee & Spouse', value: 'both' },
            { option: 'Spouse', value: 'spouse' },
            { option: 'Neither', value: 'null' },
          ]}
          required
          color="--colors-gray-black"
          handleChange={(e) => handleChange(id, question_type, incentive_type, e.target.value)}
          selected={answer?.choice === null ? 'null' : answer?.choice || ''}
          stretch
        />
      );
    }
    return <>Invalid Question Type</>;
  };

  const incentiveText = retrieveContentfulData<Incentive[]>('profile_section.incentives.incentives');
  const incentiveIdx = incentiveText.findIndex((i) => i.plan_id === surveyQuestion.id.toString());

  const titleField = `profile_section.incentives.incentives[${incentiveIdx}].title` as TextField;

  const spouseDescription = retrieveContentfulData<RichTextContent>(
    `profile_section.incentives.incentives[${incentiveIdx}].description_spouse`,
  );

  const descriptionField = `profile_section.incentives.incentives[${incentiveIdx}].${
    hasSpouse && !getIsRichTextEmpty(spouseDescription) ? 'description_spouse' : 'description'
  }` as TextField;

  const hasSpouseQuestionConfigured = Boolean(
    retrieveContentfulData<string>(`profile_section.incentives.incentives[${incentiveIdx}].question_spouse`),
  );

  const questionField = `profile_section.incentives.incentives[${incentiveIdx}].${
    hasSpouse && hasSpouseQuestionConfigured ? 'question_spouse' : 'question'
  }` as TextField;

  const incentiveQuestionId = `incentive-question-id-${incentiveIdx}`;

  return (
    <IncentiveQuestionWrapper>
      <div className="description">
        <H5>
          <Text field={titleField} />
        </H5>
        <Paragraph size="small">
          <RichText field={descriptionField} />
        </Paragraph>
      </div>
      <div className="question">
        <Paragraph id={incentiveQuestionId} size="normal" color="--text-black" weight="bold">
          <Text field={questionField} />
        </Paragraph>
        {renderContent()}
      </div>
    </IncentiveQuestionWrapper>
  );
};
