import { ContentfulEducationalModalContent } from 'ContentfulDefaults/types/health';

export const hsaModal: ContentfulEducationalModalContent = {
  contentful_name: '[DEFAULT] HSA Educational Modal',
  title: 'Health Savings Account',
  icon: 'CurrencyCircleDollar',
  color: 'blue',
  header: {
    text: 'What is an HSA?',
    subtext:
      'While health insurance helps you cover the cost of medical care, it does not cover everything. A Health Savings Account, or HSA for short, is a great way to save for those expenses.',
  },
  eligible_plan_message: {
    contentful_name: '[DEFAULT] HSA Educational Modal - Eligible Plan',
    header: "You've chosen an HSA eligible plan!",
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "We'll help you choose a contribution amount on the HSA page.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'blue',
    icon: 'CheckCircle',
  },
  non_eligible_plan_message: {
    contentful_name: '[DEFAULT] HSA Educational Modal - Non-Eligible Plan',
    header: "You haven't chosen an HSA eligible plan yet.",
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "If you do, we'll help you figure out how much to contribute.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    icon: 'Circle',
  },
  employer_contribution_selected_plan_message: {
    contentful_name: '[DEFAULT] HSA Educational Modal - Employer Contribution Plan selected',
    header: 'Your employer will contribute {x} to your HSA',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'You can use these funds to pay for eligible expenses listed below.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'blue',
    icon: 'CheckCircle',
  },
  employer_contribution_no_selected_plan_message: {
    contentful_name: '[DEFAULT] HSA Educational Modal - Employer Contribution No Plan selected',
    header: 'Your employer will contribute up to {x} to your HSA',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'You can use these funds to pay for eligible expenses listed below.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'blue',
    icon: 'CheckCircle',
  },
  video_url: 'https://vimeo.com/499286061',
  how_does_it_work_header_text: 'How does it work?',
  how_does_it_work_content: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Select an HSA-eligible health plan.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Open up a HSA (more about this on the review page).',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Choose how much to save in your HSA (we’ll help you with that).',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'You and your employer combined can contribute up to the federal limit each year.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [
                              {
                                type: 'code',
                              },
                              {
                                type: 'bold',
                              },
                            ],
                            value: '{x}',
                            nodeType: 'text',
                          },
                          {
                            data: {},
                            marks: [],
                            value: ' for individuals',
                            nodeType: 'text',
                          },
                        ],
                        nodeType: 'paragraph',
                      },
                    ],
                    nodeType: 'list-item',
                  },
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [
                              {
                                type: 'code',
                              },
                              {
                                type: 'bold',
                              },
                            ],
                            value: '{x}',
                            nodeType: 'text',
                          },
                          {
                            data: {},
                            marks: [],
                            value: ' for families',
                            nodeType: 'text',
                          },
                        ],
                        nodeType: 'paragraph',
                      },
                    ],
                    nodeType: 'list-item',
                  },
                ],
                nodeType: 'unordered-list',
              },
            ],
            nodeType: 'list-item',
          },
        ],
        nodeType: 'unordered-list',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  what_can_it_do_header_text: 'What can it do?',
  what_can_it_do_content: [
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Content Block - Lower Your Taxes',
      title: 'Lower your taxes',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Contributions that you make to an HSA are tax free. This means that you can lower your tax bill by putting money in an HSA.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Content Block - Pay for qualified medical expenses',
      title: 'Pay for qualified medical expenses',
      content: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Use your tax-free HSA contributions to cover qualified medical expenses. View the full list of eligible expenses.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      button_text: 'Eligible Expenses',
      button_link: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - Invest For Retirement',
      title: 'Invest for retirement',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Invest your HSA dollars to get ready for health care costs in retirement. \n\nInvestments earnings and withdrawals to cover qualified medical expenses are tax free.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - Contributions',
      title: 'Contributions are yours to keep',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Any contributions to your HSA are yours to keep. You never have to worry about losing your HSA money at the end of the year.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
  faq_header_text: 'Frequently Asked Questions',
  faq_content: [
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Open HSA',
      title: 'How do I open an HSA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'You can open an HSA through your benefit administration platform. You can find a link to your HSA admin platform on the review page.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Contribute to HSA',
      title: 'How do I contribute to my HSA?',
      content: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  '(1) Payroll deduction - your annual contribution will be divided into equal amounts and deducted from your payroll before taxes (2) Electronic transfer from your checking or savings account using your HSA admin platform (link on review page).',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Use HSA',
      title: 'How do I use my HSA funds?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'When you open up an HSA, you will receive a debit card that you can use to pay for eligible medical expenses. You can use this debit card to pay health care providers directly, and you may also be able to submit these payments through your insurance company’s website.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - HSA, FSA, HRA Difference',
      title: "HSA, FSA, HRA: What's the difference?",
      cells: [
        ['', 'HSA', 'FSA', 'HRA'],
        ['The money in the account belongs to you', 'X', '', ''],
        ['The money in the account belongs to your employer', '', 'X', 'X'],
        ['You can contribute', 'X', 'X', ''],
        ['Your employer can contribute', 'X', 'X', 'X'],
        ['Must had high deductible plan to contribute', 'X', '', ''],
        ['You can invest funds', 'X', '', ''],
      ],
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - HSA, 401(k), IRA Difference',
      title: "HSA, 401(k), IRA: What's the difference?",
      cells: [
        ['', 'HSA', '401(k)', 'IRA'],
        [
          'Contributions are tax free',
          'X',
          '401(k) contributions are not excluded from Social Security and Medicare taxes.',
          '',
        ],
        ['Capital gains are tax free', 'X', 'Tax Deferred.', 'Roth IRA only.'],
        [
          'Withdrawals are tax free',
          'If used for qualified medical expenses.',
          'Roth 401k only.',
          'Roth IRA only.',
        ],
        ['Minimum distribution requirements', '', 'X', 'Roth IRAs excluded.'],
      ],
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - No Future Enrollment',
      title: "What if I don't enroll in an HSA eligible plan in the future?",
      content: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Once you deposit the funds into an HSA, the account can be used to pay for qualified medical expenses tax-free, even if you no longer have HDHP coverage. However, you won’t be able to make new contributions.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Laid Off',
      title: 'What happens to my HSA/funds if I switch jobs or get laid off?',
      content: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "The money in your HSA remains yours even after you lose or leave your job. You can usually keep that money in your former employer's HSA, or you can roll it over to another HSA administrator.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: '\n',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Retired',
      title: 'What happens to my HSA after I retire?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Once you turn 65, regardless of whether you are enrolled in Medicare, there is no penalty to withdraw funds from the HSA. If you withdraw funds for qualified medical expenses, you won’t pay any taxes. HSA funds used for other purposes are taxed as normal income, just like a 401(k) withdrawal.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - On Medicare',
      title: "Can I use an HSA if I'm on Medicare?",
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Once you’re enrolled in any part of Medicare, you can’t make contributions to your HSA and neither can your employer.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'However, you can still withdraw money tax-free for qualified medical expenses. After you turn 65, you can even use HSA funds to pay your Medicare premiums (except for Medigap). If you’re already investing funds in your HSA, you can continue to do so. Any gains, interest, or earnings are still tax-free.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'You should stop all contributions to your HSA before your Medicare coverage starts, or you may pay a tax penalty. If you decide to sign up for Part A late, your coverage can start retroactively for up to six months, so you should plan to stop contributions before your retroactive coverage kicks in, or you’ll pay the same tax penalty.  ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '\n',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
};

export const hraModal: ContentfulEducationalModalContent = {
  contentful_name: '[DEFAULT] HRA Educational Modal',
  title: 'Health Reimbursement Account',
  icon: 'CurrencyCircleDollar',
  color: 'blue',
  header: {
    text: 'What is an HRA?',
    subtext:
      'A Health Reimbursement Arrangement, or HRA, is an account that your employer sets up to help you cover your medical costs. Your employer contributes money to this fund, and you can use these funds to cover qualified medical expenses.',
  },
  eligible_plan_message: {
    contentful_name: '[DEFAULT] HRA Educational Modal - Eligible Plan',
    header: "You've chosen an HSA eligible plan!",
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "We'll help you choose a contribution amount on the HSA page.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'blue',
    icon: 'CheckCircle',
  },
  non_eligible_plan_message: {
    contentful_name: '[DEFAULT] HRA Educational Modal - Non-Eligible Plan',
    header: "You haven't chosen an HRA eligible plan yet.",
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "If you do, we'll help you figure out how much to contribute.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    icon: 'Circle',
  },
  employer_contribution_selected_plan_message: {
    contentful_name: '[DEFAULT] HRA Educational Modal - Employer Contribution Plan selected',
    header: 'Your employer will contribute {x} to your HRA',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'You can use these funds to pay for eligible expenses listed below.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'blue',
    icon: 'CheckCircle',
  },
  employer_contribution_no_selected_plan_message: {
    contentful_name: '[DEFAULT] HRA Educational Modal - Employer Contribution No Plan selected',
    header: 'Your employer will contribute up to {x} to your HRA',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'You can use these funds to pay for eligible expenses listed below.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'blue',
    icon: 'CheckCircle',
  },
  video_url: 'https://vimeo.com/499286770',
  how_does_it_work_header_text: 'How does it work?',
  how_does_it_work_content: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Once you select an HRA-eligible health plan, your employer will have access to up to ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'code',
                      },
                      {
                        type: 'bold',
                      },
                    ],
                    value: '{x}',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [],
                    value: ' that you can use to offset your medical expenses.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'When you receive medical care or purchase an item that is eligible for HRA reimbursement, submit a receipt to your employer. If your employer provides you with an HRA debit card, you can also use this to pay for eligible expenses.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Your employer will reimburse you from the HRA funds up until you reach the HRA funding limit. Your employer keeps any funds that you do not use by the end of the health insurance plan year.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
        ],
        nodeType: 'unordered-list',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  what_can_it_do_header_text: 'What can it do?',
  what_can_it_do_content: [
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Content Block - Qualified Medical Expenses',
      title: 'Pay for qualified medical expenses',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Use your employer-provided HRA funds to cover eligible medical expenses. Your employer determines the specific list of eligible expenses, but they typically include costs that your health insurance plan does not cover, such as copayments and deductible payments, as well as vision and dental care, over the counter prescriptions, and many common home health items.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
  faq_header_text: 'Frequently Asked Questions',
  faq_content: [
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Can I Contribute',
      title: 'Can I contribute to an HRA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'No.  Only your employer can contribute funds to an HRA.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: "[DEFAULT] HRA Educational Modal Custom Block - FAQ - I Didn't Use My Funds",
      title: 'What happens if I don’t use all of my HRA funds?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Unused funds usually go back to your employer, but check with your employer for details. In some cases, your employer may allow you to roll over some unused funds for use next year.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Access Funds',
      title: 'How do I access my HRA funds?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'If your employer provides you with an HRA debit card, you can use that to pay for HRA eligible expenses. If you do not have an HRA debit card, you can submit receipts to your employer for reimbursement.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Left Company',
      title: 'What happens if I leave my company part way through the year?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'If you leave your company part way through the year, you will no longer have access to unused funds.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Expense Types',
      title: 'What type of expenses can be reimbursed from an HRA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'You can use HRA funds to pay for qualified medical expenses, including copayments and deductible payments for your health insurance, dental and vision care, and many other health care goods and services. ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'HSA Expense Sheet',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '\n',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Combine HRA and HSA',
      title: 'Can I combine an HRA and an HSA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'No. You cannot have both an HRA and an HSA.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Combine HRA and FSA',
      title: 'Can I combine an HRA and an FSA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Yes. You can have both an FSA (Flexible Spending Account) and an HRA.  If you have both types of accounts, you cannot reimburse the same expense from both accounts. Typically, you cannot use FSA funds until you have used up your full HRA amount. However, this varies by employer, so check with your employer’s plan documents for more information.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '\n',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
};

export const fsaModal: ContentfulEducationalModalContent = {
  contentful_name: '[DEFAULT] FSA Educational Modal',
  title: 'Flexible Spending Account',
  icon: 'CurrencyCircleDollar',
  color: 'green',
  header: {
    text: 'What is an FSA?',
    subtext:
      'A Medical Flexible Spending Account, or Medical FSA, is a pre-tax benefit account that you can use to pay for qualified medical, dental, and vision care expenses that aren’t covered by your health insurance plan.',
  },
  eligible_plan_message: {
    contentful_name: '[DEFAULT] FSA Educational Modal - Eligible Plan',
    header: 'You’re eligible to use a Medical FSA.',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    color: 'green',
    icon: 'CheckCircle',
  },
  non_eligible_plan_message: {
    contentful_name: '[DEFAULT] FSA Educational Modal - Non-Eligible Plan',
    header: 'Because you chose an HSA eligible plan, you’re eligible to use a LPFSA.',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    icon: 'CheckCircle',
    color: 'green',
  },
  video_url: 'https://vimeo.com/418250339',
  how_does_it_work_header_text: 'How does it work?',
  how_does_it_work_content: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Open up an account and decide how much to contribute each year. Your employer will calculate how much to withhold from each paycheck to get you to your total annual contribution.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'You must decide how much to contribute when you open your account. You will have access to the entire annual amount immediately, even though you will be making those contributions gradually with each paycheck.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      "Once that's done, you can use your account to help pay for hundreds of eligible healthcare expenses, like co-payments for doctor visits, prescription drugs, and new eyeglasses or contact lenses.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      "If you don't use all of your FSA funds, you might be able to roll over some unused funds to the next plan year, but you will lose any left-over contributions above that roll-over limit. ",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'Since you can lose unused FSA funds, you should only contribute amounts that you know you will use.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'If you are enrolled in an HSA eligible plan, your Medical FSA turns into Limited Purpose FSA. Limited Purpose FSAs can only be used to pay for Dental, Vision, and Orthodontia expenses.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'list-item',
          },
        ],
        nodeType: 'unordered-list',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  what_can_it_do_header_text: 'What can it do?',
  what_can_it_do_content: [
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Content Block - Lower Your Taxes',
      title: 'Lower your taxes',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Contributions that you make to an FSA are tax free. This means that you can lower your tax bill by putting money in an FSA.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Content Block - Qualified Medical Expenses',
      title: 'Pay for qualified medical expenses',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Use your FSA funds to cover eligible medical expenses. ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'Eligible Expenses',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
  custom_alert_message: {
    contentful_name: '[DEFAULT] FSA Custom Message',
    header: 'This is a custom client message',
    text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'this is the subheader',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  faq_header_text: 'Frequently Asked Questions',
  faq_content: [
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Can I Contribute',
      title: 'Can I contribute to an FSA?',
      content: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Yes. You can contribute money to an FSA. Because FSA contributions are tax free, this is a good way to get a tax break on qualifying expenses that you know that you will incur.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - How Much Can I Contribute',
      title: 'How much can I contribute to an FSA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'In 2022, you can contribute up to $2,850 to an FSA. Just be sure to only contribute funds that you know that you will use, since you can lose unused FSA funds at the end of the year.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Can I Change My Mind',
      title: 'Can I change my mind about how much I contribute to an FSA?',
      content: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value:
                  'No. Once you set your contribution amount at the beginning of your benefit plan year, you cannot change the contribution amount unless you have a specific qualifying event.  Qualifying events include things like changes to marital status, changes to the number of dependents, or changes to your employment status.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '\n',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    {
      contentful_name: "[DEFAULT] FSA Educational Modal Custom Block - FAQ - Didn't Use Funds",
      title: 'What happens if I don’t use all of my FSA funds?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Unused funds usually go back to your employer, but check with your employer for details. The IRS permits employers to allow up to $570 in unused funds to roll over to the next year.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Access Funds',
      title: 'How do I access my FSA funds?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Your employer may provide you with an FSA debit card that you can use to pay for eligible expenses. You can also request to be reimbursed for eligible expenses from your account.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Leave Job',
      title: 'What happens if I leave my company part way through the year?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'If you leave your company part way through the year, you may have a “run out period” during which you can still use FSA funds after you leave your employer.  You lose any funds that you do not use during that run out period.  Check with you employer for more details about run our periods.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Expense Types',
      title: 'What type of expenses can I use my FSA for?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'You can use FSA funds to pay for qualified medical expenses, including copayments and deductible payments for your health insurance, dental and vision care, and many other health care goods and services. ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'HSA Expense List',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - FSA and HRA',
      title: 'Can I combine an FSA and an HRA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Yes.  You can have both an FSA (Flexible Spending Account) and an HRA.  If you have both types of accounts, you cannot reimburse the same expense from both accounts. Typically, you cannot use FSA funds until you have used up your full HRA amount. However, this varies by employer, so check with your employer’s plan documents for more information.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - FSA and HSA',
      title: 'Can I combine an FSA and an HSA?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Sort of.  If you have an HSA, you can combine that with a limited purpose FSA or a post-deductible FSA. Limited purpose FSA can be used to pay for vision and dental care, while a post-deductible FSA can be used to pay for medical cost, like coinsurance, after the HSA deductible has been met.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
};
