import React from 'react';

import { FormattedTooltipProps, Paragraph } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';

import { Cell, Dash, RowContainer } from './styled';

interface CompareTableRowProps {
  rowHeader?: string;
  rowHeaderTooltip?: string | FormattedTooltipProps;
  columnOne: JSX.Element | number | null;
  columnTwo: JSX.Element | number | null;
  columnThree?: JSX.Element | number | null;
  asterisk?: boolean;
  size?: 'small' | 'medium' | 'large';
  hideTooltip?: boolean;
}

export const CompareTableRow = ({
  rowHeader,
  rowHeaderTooltip,
  columnOne,
  columnTwo,
  columnThree,
  asterisk = false,
  size = 'large',
  hideTooltip = false,
}: CompareTableRowProps) => (
  <RowContainer role="row">
    <Cell className="row-header" hideRowHeader={!rowHeader} role="rowheader" gray={!rowHeader} size={size}>
      <div>
        {asterisk && (
          <div className="asterisk">
            <Paragraph size="mini">
              * <Text field="health_section.compare_plan_text.compareModal.afterDeductibleAsterisk" />
            </Paragraph>
          </div>
        )}
        {rowHeader && !hideTooltip && (
          <TooltipPlanDetails keyHint={rowHeader} tooltip={rowHeaderTooltip || ''}>
            {rowHeader}
          </TooltipPlanDetails>
        )}
        {hideTooltip && <Paragraph color="--colors-gray-600">{rowHeader}</Paragraph>}
      </div>
    </Cell>
    <Cell role="cell" size={size}>
      <div>{columnOne || <Dash />}</div>
    </Cell>
    <Cell role="cell" size={size}>
      <div>{columnTwo || <Dash />}</div>
    </Cell>
    <Cell role="cell" size={size}>
      <div>{columnThree || <Dash />}</div>
    </Cell>
  </RowContainer>
);
