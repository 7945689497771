import styled from 'styled-components';

export const EnrollmentContainer = styled.section`
  max-width: 32rem;

  .pseudo-toggle {
    text-align: left;
    justify-content: flex-start;
    padding-left: var(--sizing-sm);
  }
`;
