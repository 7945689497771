import React from 'react';

import { useCompareContext } from 'Contexts/compareContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, FadeUp, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { PicwellScore } from 'DesignLibrary/atoms/PicwellScore';
import { useViewport } from 'DesignLibrary/context';
import PlanT from 'Models/plan';
import { PlanFit } from 'Shared/PlanFit';
import Text from 'Shared/Text';

import { BarContainer, PlanWrapper, Plan, ButtonWrapper, Placeholder, BarPosition } from './styled';

interface CompareBarProps {
  hidePlanScore: boolean;
  isImprovedRecExplanationEnabled: boolean;
  handleShowCompareModal: () => void;
}

const CompareBar = ({
  hidePlanScore,
  isImprovedRecExplanationEnabled,
  handleShowCompareModal,
}: CompareBarProps) => {
  const { comparablePlans, togglePlan } = useCompareContext();
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();

  const showModal = () => {
    handleShowCompareModal();
  };

  let showButton = true;
  if (device === 'mobile' && comparablePlans.length === 1) showButton = false;

  const removePlanLabel = retrieveContentfulData<string>(
    'health_section.secondary_content.accessibility.compare_bar_remove_plan_button',
  );

  const renderPlanScore = (plan: PlanT) => {
    if (hidePlanScore) {
      return null;
    }

    if (isImprovedRecExplanationEnabled) {
      return <PlanFit variant="icon" score={plan.picwellScore} />;
    }

    return <PicwellScore size="small" tier={plan.tier} score={plan.picwellScore} />;
  };

  return (
    <BarPosition isOpen={comparablePlans.length > 0}>
      <FadeUp isOpen={comparablePlans.length > 0}>
        <PageLayout page="compare-bar">
          <BarContainer data-testid={comparablePlans.length > 0 ? 'show-compare-bar' : 'hide-compare-bar'}>
            <PlanWrapper>
              {comparablePlans.map((plan) => (
                <Plan key={plan.planId}>
                  <div>
                    {renderPlanScore(plan)}
                    <Paragraph size="small" weight="bold" color="--text-black">
                      {plan.planMarketingName}
                    </Paragraph>
                  </div>
                  <Button
                    aria-label={`${removePlanLabel} ${plan.planMarketingName}`}
                    buttonType="transparent"
                    size="xsmall"
                    iconLeft="X"
                    iconProps={{ size: 'small', color: '--text-gray' }}
                    onClick={() => togglePlan(plan)}
                  />
                </Plan>
              ))}
              {comparablePlans.length === 1 && (
                <Placeholder empty className="select-another-plan">
                  <Paragraph size="small" color="--text-gray-light">
                    <Text field="health_section.compare_plan_text.compareBar.secondPlan" />
                  </Paragraph>
                </Placeholder>
              )}
              {device !== 'mobile' && comparablePlans.length === 2 && (
                <Placeholder empty className="select-third-plan">
                  <Paragraph size="small" color="--text-gray-light">
                    <Text field="health_section.compare_plan_text.compareBar.thirdPlan" />
                  </Paragraph>
                </Placeholder>
              )}
            </PlanWrapper>
            {showButton && (
              <ButtonWrapper>
                <Button
                  tabindex={0}
                  size="small"
                  buttonType={device === 'mobile' ? 'transparent' : 'secondary'}
                  stretch
                  isDisabled={comparablePlans.length < 2}
                  onClick={showModal}
                  className="compare-button"
                >
                  <Text field="health_section.button_text.compare" />
                </Button>
              </ButtonWrapper>
            )}
          </BarContainer>
        </PageLayout>
      </FadeUp>
    </BarPosition>
  );
};

export default CompareBar;
