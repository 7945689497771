import React, { useEffect, useState } from 'react';

import { ALEX_STEPS, HasPlayed, Step, useIntroContext } from 'Contexts/introContext';

import { RenderMedia } from '../RenderMedia';

interface RenderStepProps {
  step: Step;
  stepKey: ALEX_STEPS;
  mediaType: 'audio' | 'video';
  audioUrls: string[];
  videoUrl?: string;
  handleNext: () => void;
}

export const RenderStep = ({
  step,
  stepKey,
  mediaType,
  audioUrls,
  videoUrl,
  handleNext,
}: RenderStepProps) => {
  const { updateVideoHasPlayed, updateAudioHasPlayed } = useIntroContext();
  const [audioFileIndex, setAudioFileIndex] = useState(0);

  // Reset file index when step changes
  useEffect(() => {
    setAudioFileIndex(0);
  }, [stepKey]);

  // Increment file index when file finishes playing
  useEffect(() => {
    if (step.audioHasPlayed[audioFileIndex] === true && audioFileIndex < audioUrls.length) {
      setAudioFileIndex(audioFileIndex + 1);
    }
  }, [step]);
  return (
    <>
      {videoUrl && (
        <RenderMedia // video
          step={step}
          stepKey={stepKey}
          hasPlayed={step.videoHasPlayed as HasPlayed}
          updateHasPlayed={updateVideoHasPlayed}
          mediaType={mediaType}
          url={videoUrl as unknown as string}
          handleNext={handleNext}
        />
      )}
      <RenderMedia // audio
        step={step}
        stepKey={stepKey}
        hasPlayed={step.audioHasPlayed?.[audioFileIndex]}
        updateHasPlayed={updateAudioHasPlayed}
        audioFileIndex={audioFileIndex}
        mediaType="audio"
        url={audioUrls?.[audioFileIndex]}
        handleNext={handleNext}
        hideControls={videoUrl !== '' || audioUrls?.length === 0}
      />
    </>
  );
};
