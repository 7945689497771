import React from 'react';

import { Spinner } from 'DesignLibrary/atoms';
import Checkbox from 'DesignLibrary/atoms/inputs/Checkbox';
import Text from 'Shared/Text';
import { CoveredMember, CoveredMembers } from 'Types/entities';

import { CoveredMembersContainer, LoadingIndicator } from './styled';
import { InsuranceType } from '../types';

function getMemberType(member) {
  if (member.policyholder) {
    return 'policyholder';
  }

  if (member.dependant) {
    return 'dependent';
  }

  return 'spouse';
}

export interface CoverageOptionsProps {
  coveredMembers: CoveredMembers;
  isLoading: boolean;
  insuranceType: InsuranceType;
  handleMemberSelect: (externalId: string) => void;
}

export const CoverageOptions = ({
  coveredMembers,
  isLoading,
  insuranceType,
  handleMemberSelect,
}: CoverageOptionsProps) => {
  const renderOnlyPolicyholder = (policyholder: CoveredMember) => (
    <CoveredMembersContainer>
      <div className="coverage-group" role="group" aria-labelledby="coverage-options-label">
        <Checkbox
          key={`${policyholder.memberId}-checkbox`}
          color="--text-black"
          className="member-checkbox policyholder-checkbox"
          checked
          disabled
          handleChange={() => null}
          testId={`${policyholder.memberId}-checkbox`}
        >
          <Text field="profile_section.member.policyholder_card_header" />
        </Checkbox>
      </div>
    </CoveredMembersContainer>
  );

  if (['std', 'ltd'].includes(insuranceType)) {
    const policyholder = Object.values(coveredMembers).find((i) => i.policyholder) as CoveredMember;

    return renderOnlyPolicyholder(policyholder);
  }

  const getDependantIdentifier = (member: CoveredMember) => {
    if (member.gender) {
      return `(${member.age}, ${member.gender.toUpperCase().charAt(0)})`;
    }

    return `(${member.age})`;
  };

  return (
    <CoveredMembersContainer>
      {isLoading && (
        <LoadingIndicator>
          <Spinner color="--border-gray" />
        </LoadingIndicator>
      )}
      <div className="coverage-group" role="group" aria-labelledby="coverage-options-label">
        {Object.entries(coveredMembers).map(([externalId, member]) => (
          <Checkbox
            key={`${member.memberId}-checkbox`}
            color="--text-black"
            className={`member-checkbox ${getMemberType(member)}-checkbox`}
            checked={coveredMembers[externalId].covered}
            disabled={member.policyholder}
            handleChange={() => handleMemberSelect(externalId)}
            testId={`${externalId}-checkbox`}
          >
            {member.policyholder && <Text field="profile_section.member.policyholder_card_header" />}
            {!member.policyholder && !member.dependant && (
              <Text field="profile_section.member.domestic_partner_card_header" />
            )}
            {member.dependant && <Text field="profile_section.member.dependent_card_header" />}

            {member.dependant && (
              <span className="age-gender">
                &nbsp;
                {getDependantIdentifier(member)}
              </span>
            )}
          </Checkbox>
        ))}
      </div>
    </CoveredMembersContainer>
  );
};
