import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';

import { HsaBullet } from 'Containers/HsaPage/HsaBullet';
import { useTextContext } from 'Contexts/textContext';
import { Button, H3, Paragraph } from 'DesignLibrary/atoms';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { IconBlock } from 'DesignLibrary/molecules/IconBlock';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { BulletStyle, Container } from './styled';

export interface YourContributionsProps {
  employerHsaContribution: number;
  recommendedHsaContribution: number;
  isEmployerMatching: boolean;
  employerHsaPercentageMatch: number | null;
  catchupEligible: boolean;
  deductionsPerYear?: number;
  vendorName?: string | null;
  vendorUrl?: string | null;
}

export const YourContributions: FC<YourContributionsProps> = ({
  employerHsaContribution = 0,
  recommendedHsaContribution,
  isEmployerMatching,
  employerHsaPercentageMatch,
  catchupEligible,
  deductionsPerYear = 12,
  vendorName,
  vendorUrl,
}) => {
  const { retrieveContentfulData } = useTextContext();

  const richTextHeader = (
    <RichText
      noWrapper
      field="hsa.contribution_explainer.header_per_paycheck"
      vars={[
        <PremiumPerDeduction
          key="contribution-per-deduction"
          annualPremium={recommendedHsaContribution}
          deductions={deductionsPerYear}
          currency
          hideTooltip
        />,
        <PremiumPerDeduction
          key="contribution-annual"
          annualPremium={recommendedHsaContribution}
          deductions={1}
          currency
          hideTooltip
        />,
      ]}
    />
  );

  let vendorBullet: JSX.Element | null = null;
  if (vendorName && vendorUrl) {
    const hsaAdminWebsiteLabel = retrieveContentfulData<string>(
      'hsa.contribution_explainer.hsa_administrator_website',
    );
    const vendorAnchor = (
      <Box display="inline" as="span" sx={{ a: { display: 'inline', fontSize: 'inherit' } }}>
        <Button buttonType="link" url={vendorUrl} onClick={() => null}>
          {hsaAdminWebsiteLabel}
        </Button>
      </Box>
    );

    vendorBullet = (
      <BulletStyle data-testid="vendor-bullet">
        <IconBlock color="blue" shade={100} icon="Globe" />
        <Paragraph color="--text-black">
          <Text
            field="hsa.contribution_explainer.hsa_administrator"
            vars={{ name: vendorName, link: vendorAnchor }}
          />
        </Paragraph>
      </BulletStyle>
    );
  }

  return (
    <Container>
      <div className="title">
        <H3 as="p" weight="normal" lineHeight={1.5}>
          {richTextHeader}
        </H3>
      </div>
      <div className="bullets">
        <HsaBullet icon="Cursor" field="hsa.contribution_explainer.slider_cta" />
        {employerHsaContribution > 0 && (
          <HsaBullet
            icon="CurrencyDollar"
            field={
              isEmployerMatching
                ? 'hsa.contribution_explainer.employer_match'
                : 'hsa.contribution_explainer.employer_contribution'
            }
            employerHsaContribution={employerHsaContribution}
            employerHsaPercentageMatch={employerHsaPercentageMatch || 0}
          />
        )}
        <HsaBullet icon="ChartLineUp" field="hsa.contribution_explainer.retirement_savings_cta" />
        {catchupEligible && (
          <HsaBullet icon="CurrencyDollar" field="hsa.catchup_info" dataTestId="bullet-catchup-info" />
        )}
        {vendorBullet}
      </div>
    </Container>
  );
};
