import React from 'react';

import { FadeIn, H5, Paragraph } from 'DesignLibrary/atoms';
import Plan from 'Models/plan';
import Text from 'Shared/Text';

import { EmptyPlan, PlanHeaderContainer, SmallPlanTitle } from './styled';
import { CompareTableRow } from '../_shared/CompareTableRow';

export interface PlanHeaderProps {
  plans: Plan[];
  displaySmallTitle: boolean;
}

export const PlanHeader = ({ plans, displaySmallTitle }: PlanHeaderProps) => {
  const firstPlan = plans[0];
  const secondPlan = plans[1];
  const thirdPlan = plans[2] || null;

  const renderPlanHeader = (plan: Plan) => (
    <PlanHeaderContainer>
      <Paragraph size="small" className="carrier-name">
        {plan.carrierName}
      </Paragraph>
      <H5 as="h3" className="plan-name" title={plan.planMarketingName}>
        {plan.planMarketingName}
      </H5>
      <FadeIn isOpen={displaySmallTitle}>
        <SmallPlanTitle>
          <Paragraph weight="bold" color="--text-black">
            {plan.planMarketingName}
          </Paragraph>
        </SmallPlanTitle>
      </FadeIn>
    </PlanHeaderContainer>
  );

  const renderEmptyPlan = () => (
    <FadeIn isOpen={!displaySmallTitle}>
      <EmptyPlan className="no-third-plan">
        <Paragraph size="mini">
          <Text field="health_section.compare_plan_text.compareModal.selectThirdPlan" />
        </Paragraph>
      </EmptyPlan>
    </FadeIn>
  );

  const renderPlan = (plan: Plan) => (plan ? renderPlanHeader(plan) : renderEmptyPlan());

  return (
    <div aria-hidden>
      <CompareTableRow
        columnOne={renderPlan(firstPlan)}
        columnTwo={renderPlan(secondPlan)}
        columnThree={renderPlan(thirdPlan)}
        size="medium"
      />
    </div>
  );
};
