import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import HsaIllustration from 'Assets/banner/hsa-illustration.png';
import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { BannerCard } from 'DesignLibrary/molecules/BannerCard';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';

import { Container } from './styled';

interface EducationProps {
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  employerContribution: number;
  selectedPlan: Recommendation;
}

export const Education = ({
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  employerContribution,
  selectedPlan,
}: EducationProps) => {
  const { isOpen: hsaOpen, onOpen: hsaOnOpen, onClose: hsaOnClose } = useDisclosure();
  return (
    <Container>
      <BannerCard imgUrl={HsaIllustration} background="--colors-blue-50">
        <div className="banner-content">
          <Paragraph>
            <Text field="hsa.learn_more_modal_info" />
          </Paragraph>
          <Button size="small" stretch onClick={hsaOnOpen}>
            <Text field="hsa.learn_more_modal_button_text" />
          </Button>
        </div>
      </BannerCard>
      <EducationalDrawer
        handleClose={hsaOnClose}
        isOpen={hsaOpen}
        infoType="hsa"
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        employerContribution={employerContribution}
        selectedPlan={selectedPlan}
      />
    </Container>
  );
};
