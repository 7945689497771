import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { AlexIdUser } from 'Containers/App/types';
import { makeNewCommExternalId } from 'Containers/App/utils';
import {
  getClientSurveyFailure,
  getClientSurveySuccess,
  getJvpClientSurveyFailure,
  getJvpClientSurveySuccess,
  getJvpEligibilityQuestionsFailure,
  getJvpEligibilityQuestionsSuccess,
} from 'Containers/ProfilePage/actions';
import { SANITIZE_PROFILE_INPUTS } from 'Containers/ProfilePage/constants';
import {
  apiAAPIGetUserBySessionId,
  apiJVPCreateOrUpdateEnrollmentEvent,
  apiJVPCreateUser,
  apiJVPGetBootstrap,
  apiJVPGetUser,
  apiJVPPulseSession,
  getClientSurvey,
  getClientSurveyMetadata,
  getConfig,
  getEligibilityQuestions,
  getPreviousSelectedPlan,
} from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';
import {
  GetJVPEligibilityQuestionsResponse,
  JVPBootstrapPublicationInfo,
  JVPEligibilityQuestion,
  JVPUser,
  PreviousSelectedPlan,
} from 'Utils/apiTypes';
import Logger from 'Utils/logger';
import { ERROR_PATH, RESULT_PATH } from 'Utils/urls';

import {
  getAAPIUserFailure,
  getAAPIUserSuccess,
  getJVPBootstrapFailure,
  getJVPBootstrapSuccess,
  getJVPUserFailure,
  getJVPUserSuccess,
  handleProfileComplete,
  loadConfigFailure,
  loadConfigSuccess,
  setEnrollmentEventProfileCompleteFailure,
  setEnrollmentEventProfileCompleteSuccess,
  setEnrollmentEventTypeFailure,
  setEnrollmentEventTypeSuccess,
  setValidatedSegmentSlug,
} from './actions';
import {
  GET_AAPI_USER_REQUEST,
  GET_JVP_BOOTSTRAP,
  GET_JVP_BOOTSTRAP_SUCCESS,
  LOAD_CONFIG,
  LOAD_CONFIG_SUCCESS,
  PULSE_JVP_SESSION,
  SET_ENROLLMENT_EVENT_PROFILE_COMPLETE,
  SET_ENROLLMENT_EVENT_TYPE,
} from './constants';
import { makeSelectConfigField, makeSelectGlobalField, makeSelectJvpField } from './selectors';

export function* getXhrConfig() {
  try {
    const resp: API_TYPES.GetConfigResponse | null = yield call(getConfig);
    if (resp && Object.keys(resp.config).length > 0) {
      yield put(loadConfigSuccess(resp));
    } else {
      yield put(loadConfigFailure(new Error('client not found')));
    }
  } catch (err) {
    yield put(loadConfigFailure(err as Error));
  }
}

const getTarget = () => {
  const { DEPLOY_ENV } = window._env_;
  if (DEPLOY_ENV === 'uat') {
    return 'preview';
  }
  if (DEPLOY_ENV === 'prod') {
    return 'live';
  }
  return 'internal_preview';
};

export function* getXhrJVPBootstrap(action) {
  let { userId } = action;
  const { employerSlug } = action;

  try {
    if (!userId) {
      userId = makeNewCommExternalId();
    }

    yield call(() => apiJVPCreateUser(userId));

    // Fetch (full) JVP User (with dependents) while we're here
    try {
      const jvpUser = yield call(() => apiJVPGetUser(userId));
      // Put it in the store
      yield put(getJVPUserSuccess(jvpUser));
    } catch (userError) {
      yield put(getJVPUserFailure(new Error('Failed to fetch JVP User With Dependents')));
      Logger.log(`JVP user request failed: ${userError}`);
    }

    const unvalidatedSegmentSlug: string = yield select(makeSelectGlobalField('unvalidatedSegmentSlug'));

    let bootstrap: API_TYPES.JVPBootstrap | undefined;
    try {
      bootstrap = yield call(() =>
        apiJVPGetBootstrap(
          userId,
          employerSlug,
          getTarget(),
          new Date().toISOString().slice(0, 10), // YYYY-MM-DD
          unvalidatedSegmentSlug,
        ),
      );
    } catch (err) {
      Logger.log(`Bootstrap request failed: ${err}`);
    }

    // Filter publications that aren't set to use comm-api
    const active = bootstrap?.active?.publication?.use_comm_api ? bootstrap?.active : null;
    const upcoming = bootstrap?.upcoming?.publication?.use_comm_api ? bootstrap?.upcoming : null;

    let validatedSegmentSlug: string | null = null;

    if (active && active.segment) {
      if (active.segment.slug === unvalidatedSegmentSlug) {
        validatedSegmentSlug = unvalidatedSegmentSlug;
      }
    }

    if (!validatedSegmentSlug && upcoming && upcoming.segment) {
      if (upcoming.segment.slug === unvalidatedSegmentSlug) {
        validatedSegmentSlug = unvalidatedSegmentSlug;
      }
    }

    if (!active && !upcoming) {
      yield put(getJVPBootstrapFailure(new Error('No active configurations found')));
    } else {
      yield put(setValidatedSegmentSlug(validatedSegmentSlug));
      yield put(
        getJVPBootstrapSuccess({
          user: bootstrap?.user as JVPUser,
          employerId: bootstrap?.employer?.jv_employer_uuid || '',
          employerName: bootstrap?.employer?.employer_name || '',
          employeeId: bootstrap?.employee?.id || '',
          active,
          upcoming,
          validatedSegmentSlug,
        }),
      );
    }
  } catch (err) {
    Logger.log(`Unexpected error: ${err}`);
    yield put(getJVPBootstrapFailure(err as unknown as Error));
  }
}

export function* postXhrSetEnrollmentEventProfileComplete(action) {
  try {
    const validatedSegmentSlug: string = yield select(makeSelectGlobalField('validatedSegmentSlug'));
    const feature_flag = yield select(makeSelectConfigField('split_feature_flags'));
    let previousSelectedPlan: PreviousSelectedPlan[] = [];

    const response: API_TYPES.JVPEnrollmentEvent = yield call(() =>
      apiJVPCreateOrUpdateEnrollmentEvent(
        action.employeeId,
        action.publicationId,
        action.eventType,
        action.profileComplete,
        validatedSegmentSlug,
      ),
    );
    if (feature_flag.is_returning_user_feature_enabled && response.id) {
      // Fetch previous year selected plans
      const respPrevPlan = yield call(() => getPreviousSelectedPlan(response.id));
      previousSelectedPlan = respPrevPlan?.elections;
    }
    yield put(setEnrollmentEventProfileCompleteSuccess(response, previousSelectedPlan));
  } catch (err) {
    yield put(setEnrollmentEventProfileCompleteFailure(err as unknown as Error));
  }
}

export function* postXhrSetEnrollmentEventType(action) {
  try {
    const validatedSegmentSlug: string = yield select(makeSelectGlobalField('validatedSegmentSlug'));

    const response: API_TYPES.JVPEnrollmentEvent = yield call(() =>
      apiJVPCreateOrUpdateEnrollmentEvent(
        action.employeeId,
        action.publicationId,
        action.eventType,
        false,
        validatedSegmentSlug,
      ),
    );
    yield put(setEnrollmentEventTypeSuccess(response));
  } catch (err) {
    yield put(setEnrollmentEventTypeFailure(err as unknown as Error));
  }
}

export function* postXhrJVPPulseSession(action) {
  yield call(() => apiJVPPulseSession(action.sessionId));
}

/**
 * Given a session-id AAPI (should) know about, try-get an auth0_uuid in return.
 */
export function* postXhrAAPIGetUserBySessionId(action) {
  try {
    const response: AlexIdUser = yield call(() => apiAAPIGetUserBySessionId(action.sessionId));
    yield put(getAAPIUserSuccess(response));
  } catch (err) {
    yield put(getAAPIUserFailure(err as unknown as Error));
  }
}

export function* getXhrClientSurvey() {
  try {
    const resp: API_TYPES.ClientSurveySchema = yield call(getClientSurvey);

    yield put(getClientSurveySuccess({ upcoming: resp }));
  } catch (err) {
    yield put(getClientSurveyFailure(err));
    yield put(push(ERROR_PATH));
  }
}

function* retrieveClientSurveyWithMetadata(enrollmentEventId: string) {
  const survey: API_TYPES.ClientSurveySchema = yield call(() => getClientSurvey(enrollmentEventId));
  const metadata: API_TYPES.ClientSurveyMetadataResponse = yield call(() =>
    getClientSurveyMetadata(enrollmentEventId),
  );

  if (!survey) {
    return null;
  }

  survey.properties = Object.entries(survey.properties).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: {
        ...val,
        hideChoices: metadata?.data?.find((data) => key === data.client_survey_id)?.hide_choices || false,
      },
    }),
    {},
  );
  return survey;
}

export function* getXhrJvpClientSurvey() {
  const active: API_TYPES.JVPBootstrapPublicationInfo | null = yield select(makeSelectJvpField('active'));
  const upcoming: API_TYPES.JVPBootstrapPublicationInfo | null = yield select(makeSelectJvpField('upcoming'));

  const result = {
    active: null,
    upcoming: null,
  };

  try {
    if (active?.enrollment_event.id) {
      result.active = yield call(retrieveClientSurveyWithMetadata, active?.enrollment_event.id);
    }

    if (upcoming?.enrollment_event.id) {
      result.upcoming = yield call(retrieveClientSurveyWithMetadata, upcoming?.enrollment_event.id);
    }

    yield put(getJvpClientSurveySuccess(result));
  } catch (err) {
    yield put(getJvpClientSurveyFailure(err));
  }
}

export function* getJvpEligibilityQuestions() {
  const activePublicationInfo: JVPBootstrapPublicationInfo | null = yield select(
    makeSelectJvpField('active'),
  );

  const upcomingPublicationInfo: JVPBootstrapPublicationInfo | null = yield select(
    makeSelectJvpField('upcoming'),
  );

  const eligibilityQuestions: Record<'active' | 'upcoming', JVPEligibilityQuestion[] | null> = {
    active: null,
    upcoming: null,
  };
  if (activePublicationInfo) {
    try {
      const publicationId = activePublicationInfo.publication.jv_publication_uuid;
      const result: GetJVPEligibilityQuestionsResponse = yield call(() =>
        getEligibilityQuestions(publicationId),
      );
      eligibilityQuestions.active = result.eligibility_questions;
    } catch (err) {
      yield put(getJvpEligibilityQuestionsFailure(err));
    }
  }

  if (upcomingPublicationInfo) {
    try {
      const publicationId = upcomingPublicationInfo.publication.jv_publication_uuid;
      const result: GetJVPEligibilityQuestionsResponse = yield call(() =>
        getEligibilityQuestions(publicationId),
      );
      eligibilityQuestions.upcoming = result.eligibility_questions;
    } catch (err) {
      yield put(getJvpEligibilityQuestionsFailure(err));
    }
  }

  yield put(getJvpEligibilityQuestionsSuccess(eligibilityQuestions));
}

export function* getLegacyClientSurvey() {
  const config: API_TYPES.ClientConfig = yield select(makeSelectGlobalField('config'));

  // if builder customer, perform no-op.
  if (config?.builder_customer_key) {
    return;
  }

  // legacy picwell client
  if (config && !config.builder_customer_key) {
    yield call(getXhrClientSurvey);
  }
  // user entered the wrong client
  else {
    Logger.log('Invalid client id');
  }
}

function* handleCompleteProfile() {
  yield put(handleProfileComplete());
  yield put(push(RESULT_PATH));
}

// Individual exports for testing
export default function* getAppSaga() {
  yield all([
    takeLatest(LOAD_CONFIG, getXhrConfig),
    takeLatest(LOAD_CONFIG_SUCCESS, getLegacyClientSurvey),
    takeLatest(GET_JVP_BOOTSTRAP_SUCCESS, getXhrJvpClientSurvey),
    // JVP ACTIONS
    takeLatest(GET_JVP_BOOTSTRAP, getXhrJVPBootstrap),
    takeLatest(SET_ENROLLMENT_EVENT_TYPE, postXhrSetEnrollmentEventType),
    takeLatest(GET_JVP_BOOTSTRAP_SUCCESS, getJvpEligibilityQuestions),
    takeLatest(SET_ENROLLMENT_EVENT_PROFILE_COMPLETE, postXhrSetEnrollmentEventProfileComplete),
    takeLatest(PULSE_JVP_SESSION, postXhrJVPPulseSession),
    takeLatest(GET_AAPI_USER_REQUEST, postXhrAAPIGetUserBySessionId),
    takeLatest(SANITIZE_PROFILE_INPUTS, handleCompleteProfile),
  ]);
}
