import {
  ArrowCounterClockwise,
  ArrowDown,
  ArrowElbowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
  Browsers,
  CaretDoubleUp,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChartBar,
  ChartLineUp,
  ChartPie,
  Check,
  CheckCircle,
  Circle,
  Clock,
  CrownSimple,
  CurrencyCircleDollar,
  CurrencyDollar,
  Cursor,
  EnvelopeSimple,
  FilePlus,
  FirstAidKit,
  Flag,
  Globe,
  IconContext,
  List,
  ListBullets,
  LockSimple,
  MagnifyingGlass,
  Minus,
  Pause,
  Pencil,
  PencilSimple,
  Phone,
  Play,
  Plus,
  PlusCircle,
  Prescription,
  Printer,
  Prohibit,
  Question,
  Receipt,
  ShoppingCart,
  Sparkle,
  Star,
  Trash,
  User,
  Users,
  Warning,
  WarningCircle,
  X,
  XCircle,
  SpeakerHigh,
  ListChecks,
  ClosedCaptioning,
  FileText,
  Lightning,
  Scales,
  AsteriskSimple,
  Info,
  Binoculars,
  Coins,
  Scroll,
  Stethoscope,
  Smiley,
  SmileyMeh,
  SmileySad,
  MapPin,
} from '@phosphor-icons/react';
import React, { FC } from 'react';

import { SPACING } from '../../vars';

export type IconTypes =
  | 'Check'
  | 'Search'
  | 'Question'
  | 'QuestionOutline'
  | 'Plus'
  | 'PlusCircle'
  | 'Minus'
  | 'LockSimple'
  | 'Printer'
  | 'FilePlus'
  | 'Prohibit'
  | 'ArrowRight'
  | 'ArrowLeft'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowUpRight'
  | 'CaretRight'
  | 'CaretUp'
  | 'CaretDown'
  | 'CaretLeft'
  | 'X'
  | 'List'
  | 'ListBullets'
  | 'Trash'
  | 'CheckCircle'
  | 'Circle'
  | 'XCircle'
  | 'WarningCircle'
  | 'Warning'
  | 'ArrowElbowDownRight'
  | 'ChartLineUp'
  | 'ChartBar'
  | 'ChartPie'
  | 'CurrencyDollar'
  | 'CurrencyCircleDollar'
  | 'Pencil'
  | 'ArrowCounterClockwise'
  | 'CaretDoubleUp'
  | 'Receipt'
  | 'User'
  | 'Users'
  | 'Browsers'
  | 'Phone'
  | 'EnvelopeSimple'
  | 'Globe'
  | 'Cursor'
  | 'Sparkle'
  | 'ShoppingCart'
  | 'Prescription'
  | 'Pause'
  | 'Play'
  | 'FirstAidKit'
  | 'CrownSimple'
  | 'Star'
  | 'SpeakerHigh'
  | 'ListChecks'
  | 'ClosedCaptioning'
  | 'FileText'
  | 'Lightning'
  | 'Flag'
  | 'Clock'
  | 'Scales'
  | 'AsteriskSimple'
  | 'Info'
  | 'Binoculars'
  | 'Coins'
  | 'Scroll'
  | 'Stethoscope'
  | 'Smiley'
  | 'SmileyMeh'
  | 'SmileySad'
  | 'MapPin'
  | 'PencilSimple';

export interface IconProps {
  type?: IconTypes;
  size?: 'small' | 'normal' | 'large' | number;
  color?: string;
  weight?: 'regular' | 'fill' | 'duotone' | 'bold';
}

export const ICON_MAP = {
  Check: <Check data-testid="Check" />,
  Search: <MagnifyingGlass />,
  Question: <Question weight="fill" data-testid="Question" />,
  QuestionOutline: <Question />,
  Plus: <Plus />,
  PlusCircle: <PlusCircle weight="fill" />,
  Minus: <Minus data-testid="Minus" />,
  LockSimple: <LockSimple />,
  Printer: <Printer />,
  FilePlus: <FilePlus />,
  Prohibit: <Prohibit />,
  ArrowRight: <ArrowRight />,
  ArrowLeft: <ArrowLeft />,
  ArrowUp: <ArrowUp />,
  ArrowDown: <ArrowDown />,
  ArrowUpRight: <ArrowUpRight aria-hidden />,
  CaretRight: <CaretRight />,
  CaretUp: <CaretUp />,
  CaretDown: <CaretDown />,
  CaretLeft: <CaretLeft />,
  X: <X data-testid="X" />,
  List: <List />,
  ListBullets: <ListBullets />,
  Trash: <Trash />,
  CheckCircle: <CheckCircle weight="fill" />,
  Circle: <Circle />,
  XCircle: <XCircle weight="fill" />,
  WarningCircle: <WarningCircle weight="fill" />,
  Warning: <Warning weight="fill" />,
  ArrowElbowDownRight: <ArrowElbowDownRight />,
  ChartLineUp: <ChartLineUp />,
  ChartBar: <ChartBar data-testid="Chartbar" />,
  ChartPie: <ChartPie />,
  CurrencyDollar: <CurrencyDollar />,
  CurrencyCircleDollar: <CurrencyCircleDollar weight="fill" />,
  Pencil: <Pencil />,
  PencilSimple: <PencilSimple />,
  ArrowCounterClockwise: <ArrowCounterClockwise />,
  CaretDoubleUp: <CaretDoubleUp />,
  Receipt: <Receipt />,
  User: <User />,
  Users: <Users />,
  Browsers: <Browsers />,
  Phone: <Phone />,
  EnvelopeSimple: <EnvelopeSimple />,
  Globe: <Globe />,
  Cursor: <Cursor />,
  Sparkle: <Sparkle />,
  ShoppingCart: <ShoppingCart />,
  Prescription: <Prescription />,
  FirstAidKit: <FirstAidKit />,
  Pause: <Pause />,
  Play: <Play />,
  CrownSimple: <CrownSimple />,
  Star: <Star />,
  SpeakerHigh: <SpeakerHigh />,
  ListChecks: <ListChecks />,
  ClosedCaptioning: <ClosedCaptioning />,
  FileText: <FileText />,
  Lightning: <Lightning />,
  Flag: <Flag />,
  Clock: <Clock />,
  Scales: <Scales />,
  AsteriskSimple: <AsteriskSimple />,
  Info: <Info />,
  Binoculars: <Binoculars />,
  Coins: <Coins />,
  Scroll: <Scroll />,
  Stethoscope: <Stethoscope />,
  Smiley: <Smiley />,
  SmileyMeh: <SmileyMeh />,
  SmileySad: <SmileySad />,
  MapPin: <MapPin />,
};

const SIZES = {
  small: SPACING.sm,
  normal: SPACING.md,
  large: SPACING.lg,
};

export const Icon: FC<IconProps> = ({
  type = 'Check',
  size = 'normal',
  color = '--text-black',
  weight = 'regular',
}) => (
  <IconContext.Provider
    value={{
      color: `var(${color})`,
      size: typeof size === 'number' ? `${size}px` : SIZES[size],
      weight,
      mirrored: false,
    }}
  >
    <span
      role="img"
      aria-label={type === 'ArrowUpRight' ? 'External' : type}
      style={{ color: `var(${color})`, display: 'flex' }}
    >
      {ICON_MAP[type]}
    </span>
  </IconContext.Provider>
);
