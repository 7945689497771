import styled, { css } from 'styled-components';

import { Paragraph } from 'DesignLibrary/atoms';

import { media } from '../../global';
import { FadeInKeyframes, FadeInUpKeyframes } from '../../keyframes';

interface PageHeaderContainerProps {
  numTokens: number;
  animate: boolean;
}

const fadeInStagger = (numTokens: number) => {
  let delay = '';
  const initialDelay = 0.5;
  const delayStep = 0.2;

  for (let i = 0; i < numTokens; i += 1) {
    delay += `
      #headerToken-${i} { animation-delay: ${initialDelay + i * delayStep}s; }
    `;
  }
  return css`
    ${delay}
  `;
};

export const PageHeaderContainer = styled.div<PageHeaderContainerProps>`
  padding: var(--sizing-sm) 0 0;
  /* text-align: center; */

  ${media.TABLET`
    padding: var(--sizing-lg) 0 0;
    text-align: left;
  `}

  h1 {
    margin: 0;
    margin-bottom: var(--sizing-xs);

    > span {
      display: inline-block;
      ${({ animate }) =>
        animate &&
        css`
          transform: translateY(1.5rem);
          opacity: 0;
          animation: ${FadeInUpKeyframes} 1s 0s forwards;
        `}
    }

    ${({ numTokens, animate }) => animate && fadeInStagger(numTokens)}
  }
`;

interface SubheaderProps {
  hideSubheader: boolean;
  animate?: boolean;
}

export const Subheader = styled(Paragraph)<SubheaderProps>`
  // when hideSubheader is true the subheader should not be visible
  // when animate is true we want the subheader to start hidden and animate into view via opacity
  opacity: ${({ animate, hideSubheader }) => (animate || hideSubheader ? 0 : 1)};
  animation: ${({ animate, hideSubheader }) => (animate && !hideSubheader ? FadeInKeyframes : 'none')} 0.75s
    1.5s forwards;
`;
