import React, { useContext, useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

import { TextField } from 'Containers/App/types';
import { setCookie } from 'Containers/App/utils';
import { ProfileAudio } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { sendInsightsEvent } from 'Utils/insights';

export interface AudioState {
  urlToAudio: string;
  urlToClosedCaptionVtt: string;
  delayIn: number;
  currentRef?: HTMLButtonElement; // TODO: maybe this doesn't belong in AudioState?
  isPlaying: boolean;
  isMuted: boolean;
  areClosedCaptionsEnabled: boolean;
}

export interface AudioContextProps {
  audioState: AudioState;
  setAudioState: (state: AudioState) => void;
  playAudio: (isDesktop: boolean, contentfulField: TextField) => boolean;
  toggleMute: () => void;
  toggleClosedCaptions: () => void;
}

export const useAudioContext = () => useContext(AudioContext);

export const defaultAudioState: AudioState = {
  urlToAudio: '',
  urlToClosedCaptionVtt: '',
  delayIn: 0,
  isPlaying: true,
  isMuted: false,
  areClosedCaptionsEnabled: false,
};

export const AudioContext = React.createContext<AudioContextProps>({
  audioState: defaultAudioState,
  setAudioState: () => {},
  playAudio: () => false,
  toggleMute: () => {},
  toggleClosedCaptions: () => {},
});

interface AudioProviderProps {
  children: JSX.Element;
  overrides?: Partial<AudioContextProps>;
}

export const AudioProvider = ({ children, overrides }: AudioProviderProps) => {
  let audioState: AudioState;
  let setAudioState;

  if (overrides?.audioState && overrides?.setAudioState) {
    ({ audioState, setAudioState } = overrides);
  } else {
    [audioState, setAudioState] = useState<AudioState>(defaultAudioState);
  }

  const { browser, device } = UAParser();
  const { retrieveContentfulData } = useTextContext();

  // Apple products allow autoplaying video but they must be muted until interaction w/ the video element
  const isAppleEdgeCase = browser.name === 'Safari' || ['iPad', 'iPhone'].includes(device?.model || '');

  useEffect(() => {
    const isDevMode = !!localStorage.getItem('dev');
    const audioState: AudioState = {
      ...defaultAudioState,
      isMuted: isDevMode || isAppleEdgeCase,
      areClosedCaptionsEnabled: isDevMode || isAppleEdgeCase,
    };
    setAudioState(audioState);
  }, []);

  const playAudio = (isDesktop: boolean, contentfulField: TextField): boolean => {
    const audio = retrieveContentfulData<ProfileAudio>(contentfulField);
    if (!audio) {
      // No audio content from contentful
      return false;
    }

    let urlToAudio: string;
    let urlToClosedCaptionVtt: string;

    if (isDesktop) {
      urlToAudio = audio.audio_file_url;
      urlToClosedCaptionVtt = audio.vtt_transcript_file_url;
    } else {
      urlToAudio = audio.audio_file_url_for_mobile || audio.audio_file_url;
      urlToClosedCaptionVtt = audio.vtt_transcript_file_url_for_mobile || audio.vtt_transcript_file_url;
    }

    setAudioState({
      ...audioState,
      currentRef: undefined,
      urlToAudio,
      urlToClosedCaptionVtt,
      isPlaying: true, // Need this to unset onEnded()'s isPlaying: false state
    });

    return true;
  };

  const toggleMute = () => {
    const newMuteState = !audioState.isMuted;
    const newClosedCaptionState = newMuteState ? true : audioState.areClosedCaptionsEnabled;

    setAudioState((prevState: AudioState) => ({
      ...prevState,
      isMuted: newMuteState,
      areClosedCaptionsEnabled: newClosedCaptionState,
    }));

    saveClosedCaptionStateToCookie(newClosedCaptionState);
    saveMuteStateToCookie(newMuteState);

    sendInsightsEvent(null, 'toggle_mute', { muted: newMuteState });
  };

  const toggleClosedCaptions = () => {
    const newClosedCaptionState = !audioState.areClosedCaptionsEnabled;

    setAudioState((prevState: AudioState) => ({
      ...prevState,
      areClosedCaptionsEnabled: newClosedCaptionState,
    }));

    saveClosedCaptionStateToCookie(newClosedCaptionState);

    sendInsightsEvent(null, 'toggle_cc', {
      cc: !audioState.areClosedCaptionsEnabled,
    });
  };

  const saveClosedCaptionStateToCookie = (state: boolean) => {
    setStateCookie('go_cc_enabled', state);
  };

  const saveMuteStateToCookie = (state: boolean) => {
    setStateCookie('go_cc_enabled', state);
  };

  const setStateCookie = (key: string, state: boolean) => {
    setCookie(key, state);
    // TODO: Maybe use the following as a fix for subdomains domains not matching
    //       when redirecting to BC to carry over audio prefs (assumes same path)
    // setCookie(key, state, `.${window.location.hostname}`, window.location.pathname);
  };

  return (
    <AudioContext.Provider
      value={{
        audioState,
        setAudioState,
        playAudio,
        toggleMute,
        toggleClosedCaptions,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
