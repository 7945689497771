import React, { FC } from 'react';

import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { PageHeader } from 'DesignLibrary/molecules/PageHeader';
import Text from 'Shared/Text';

export interface ContentfulHeaderWrapperProps {
  sectionKey: 'profile_section' | 'health_section' | 'hsa' | 'benefits_section_additional' | 'review_section';
  subheaderVars?: Record<string, unknown>;
  hideSubheader?: boolean;
  useHeaderAlternateText?: boolean;
  useSubheaderAlternateText?: boolean;
  useIntegratedCustomerSubheaderText?: boolean;
  isReturnUser?: boolean;
  animate?: boolean;
  showPreviousSelectedPlanMessage?: boolean;
}

export type SubheaderType =
  | 'subtext'
  | 'subtext_integrated'
  | 'subtext_return_user'
  | 'subtext_alternate'
  | 'previous_selected_plan_text'
  | 'previous_selected_plan_text_alternate';

export const ContentfulHeaderWrapper: FC<ContentfulHeaderWrapperProps> = ({
  sectionKey,
  subheaderVars,
  hideSubheader = false,
  useHeaderAlternateText = false,
  useSubheaderAlternateText = false,
  useIntegratedCustomerSubheaderText = false,
  isReturnUser = false,
  animate = true,
  showPreviousSelectedPlanMessage = false,
}) => {
  const { retrieveContentfulData } = useTextContext();
  const { is_rue_landing_page_enabled, is_returning_user_feature_enabled } = useFeatureFlagContext();

  const isDevMode = localStorage.getItem('dev');
  const shouldAnimate = !isDevMode && animate;

  let header: string = retrieveContentfulData<string>(`${sectionKey}.page_header.text`);

  if (isReturnUser && is_rue_landing_page_enabled && sectionKey === 'profile_section') {
    header = retrieveContentfulData<string>(`${sectionKey}.page_header.text_return_user`);
  } else if (useHeaderAlternateText) {
    header = retrieveContentfulData<string>(`${sectionKey}.page_header.text_alternate`);
  }

  let subheaderType: SubheaderType = 'subtext';
  if (useIntegratedCustomerSubheaderText) {
    subheaderType = 'subtext_integrated';
  } else if (sectionKey === 'health_section' && showPreviousSelectedPlanMessage) {
    if (parseInt(subheaderVars?.x as string, 10) > 1) subheaderType = 'previous_selected_plan_text';
    else subheaderType = 'previous_selected_plan_text_alternate';
  } else if (isReturnUser && is_rue_landing_page_enabled && sectionKey === 'profile_section') {
    subheaderType = 'subtext_return_user';
  } else if (useSubheaderAlternateText) {
    subheaderType = 'subtext_alternate';
  }
  let welcomeText;
  if (is_returning_user_feature_enabled && sectionKey === 'health_section' && isReturnUser) {
    welcomeText = <Text field="health_section.page_header.text_welcome" />;
  }
  const subheader = <Text field={`${sectionKey}.page_header.${subheaderType}`} vars={subheaderVars} />;

  return (
    <PageHeader
      hideSubheader={hideSubheader}
      header={header}
      subheader={subheader}
      animate={shouldAnimate}
      welcomeText={welcomeText}
    />
  );
};
