import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { invalidPlanMessageType } from 'Types/entities';
import { JVPUser, PreviousSelectedPlan } from 'Utils/apiTypes';

import { PAGE_SLUGS } from './constants';

export const getCookie = (key: string): string => {
  const cookieKeyValue = document.cookie.split('; ').find((cookie) => cookie.startsWith(`${key}`)) || '';
  const [, value] = cookieKeyValue.split('=');
  return value;
};

export const setCookie = (
  key: string,
  value: string | boolean,
  domain?: string,
  path?: string,
  expirationInMinutes?: number,
  secure?: boolean,
) => {
  let cookieContents = `${key}=${value}`;
  if (domain) {
    cookieContents += `;domain=${domain}`;
  }
  if (path) {
    cookieContents += `;path=${path}`;
  }
  if (expirationInMinutes) {
    const exp = new Date();
    const expireTime = exp.getTime() + expirationInMinutes * 60 * 1000;
    exp.setTime(expireTime);
    cookieContents += `;expires=${exp.toUTCString()}`;
  }
  if (secure === true) {
    cookieContents += `;secure`;
  }
  document.cookie = cookieContents;
};

export const defaultDomain = process.env.NODE_ENV === 'development' ? undefined : 'myalex.com';

export const deleteCookie = (key: string, domain?: string, path?: string) => {
  let cookieContents = `${key}=`;
  if (domain) {
    cookieContents += `;domain=${domain}`;
  }
  if (path) {
    cookieContents += `;path=${path}`;
  }

  cookieContents += '; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = cookieContents;
};

const sourcePrefixForJvp = 'guest__';
export const stripSourceComponent = (jvpId: string) => jvpId.replace(sourcePrefixForJvp, '');

// NOTE: AAPI requires identifiers that are exactly 32 chars so we have to strip
//   hyphens from our external id component.
//  We'll maintain this format for all external ids for consistency.
export const makeNewCommExternalId = () => `${sourcePrefixForJvp}${uuidv4().replace(/-/g, '')}`;

export const parseJvpUserId = (user: JVPUser) =>
  user.auth0_uuid || `${user.external_source}__${user.external_id}`;

const urlSegmentIsPageSlug = (segment: string): boolean => {
  return PAGE_SLUGS.includes(segment);
};

/**
 * Employer slug is always that first part of the URL before any page slugs
 * It is used for analytics events and bootstrapping
 * ie: if we have a customer with a segment like "lyondellbasell/edison" we expect "lyondellbasell"
 */
export const getEmployerSlugFromLocation = (location: Location): string => {
  const nonEmptyPathParts = location.pathname.split('/').filter(Boolean);
  return nonEmptyPathParts[0];
};

/**
 * Segment slug for cases where we have like lyondellbasell/edison
 * We want the last part (domain.tld/(employer)(/segment)?(/page)? of the URL path excluding page slug segments
 * If the url excluding page slug is only 1 part, we do not have a segment slug
 */
export const getSegmentSlugFromLocation = (location: Location): string => {
  const nonEmptyPathParts = location.pathname.split('/').filter(Boolean);

  // filter out path parts that resemble a page slug.
  const slugParts = nonEmptyPathParts.filter((seg) => {
    return !urlSegmentIsPageSlug(seg);
  });

  return slugParts.length > 1 ? slugParts[slugParts.length - 1] : '';
};

export const getFullAlexUrl = (location: Location, segmentSlug: string | undefined): string =>
  location.href + (segmentSlug || '');

export const formatPathForInsights = (path, fixedPath) => {
  if (path === fixedPath) {
    return 'landing';
  }

  const parts = path && path?.split('/');

  // Filter out any empty parts resulting from leading/trailing slashes
  const filteredParts = parts && parts?.filter((part) => part !== '');

  // If there's only one part, return it as is
  if (filteredParts?.length <= 1) {
    return filteredParts[0] || '';
  }

  // Join all parts except the first one
  return filteredParts?.slice(1).join('/');
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
};

export const moveSelectedPlanToTop = (availablePlans, result) => {
  const preselectedPlanId = result?.previousSelectedMatchedPlan?.plan_details?.jv_medical_plan_id;

  // Reorder availablePlans array with the preselected plan first
  const reorderedPlans = [...availablePlans];

  // Find the index of the preselected plan
  const preselectedIndex = reorderedPlans?.findIndex((plan) => plan?.plan?.external_id === preselectedPlanId);

  if (preselectedIndex !== -1) {
    // Remove the preselected plan from its current position
    const [preselectedPlan] = reorderedPlans?.splice(preselectedIndex, 1) || [];

    // Insert the preselected plan at the beginning of the array
    reorderedPlans?.unshift(preselectedPlan);
  }

  return reorderedPlans;
};

export const getInvalidPlanMessage = (
  tierCode: string[],
  hasPrevPlan: boolean,
  matchedPlan?: PreviousSelectedPlan,
): invalidPlanMessageType => {
  // null represents the case where we don't have to invalidate the user's existing selection and show them a message

  if (!hasPrevPlan) return null;

  if (!matchedPlan) return 'noLongerEligible';

  if (isEmpty(tierCode)) return null;

  if (parseInt(matchedPlan.tier_code || '0', 10) !== parseInt(tierCode[0], 10)) {
    return 'everyoneNotCovered';
  }
  return null;
};
