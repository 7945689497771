import { Modal, ModalContent, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ALL_NONSELECTABLE_PLAN_TYPES, ALL_SELECTABLE_PLAN_TYPES } from 'Containers/OverviewPage/constants';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { useTextContext } from 'Contexts/textContext';
import { Icon, IconTypes } from 'DesignLibrary/atoms';
import { Button } from 'DesignLibrary/atoms/Button';
import { H2, Paragraph } from 'DesignLibrary/atoms/typography';
import { useViewport } from 'DesignLibrary/context/viewportContext';
import Text from 'Shared/Text';
import { Recommendation, SelectedSupplementalPlans, SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';
import { HSA_PLUS_PATH, OVERVIEW_PATH, RESULT_PATH, VIEW_BENEFIT_PATH, VIEW_PLAN_PATH } from 'Utils/urls';

import { CtaItem } from './CtaItem';
import { CtaPlaceholderItem, SupplementalToggle } from './CtaItem/styled';
import { SupplementalPopupContainer, MobileCartWrapper, ResultsContainer, MobileCartButton } from './styled';

interface ResultsProps {
  path: string;
  selectedPlan: Recommendation | Record<string, never>;
  isCoverageWaived: boolean;
  selectedSupplementalPlans: SelectedSupplementalPlans;
  hsaContribution: number | null;
  recommendedHsaContribution: number | null;
  isHsaUnlocked: boolean;
  isSupplementalUnlocked: boolean;
  hidePlanScore: boolean;
}

export const Results = ({
  path,
  selectedPlan,
  isCoverageWaived,
  selectedSupplementalPlans,
  hsaContribution,
  recommendedHsaContribution,
  isHsaUnlocked,
  isSupplementalUnlocked,
  hidePlanScore,
}: ResultsProps) => {
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();
  const dispatch = useDispatch();

  const [isSupplementalOpen, setIsSupplementalOpen] = useState(false);
  const [isMobileCartOpen, setIsMobileCartOpen] = useState(false);

  const handleNext = (path: string) => {
    setIsMobileCartOpen(false);
    setIsSupplementalOpen(false);
    dispatch(push(path));
  };

  const chakraFocusOverrides = {
    _focus: {
      boxShadow: `0 0 0 2px var(--text-black)`,
      outline: 'none',
    },
  };

  const renderSelectedPlan = () => {
    if (isCoverageWaived) {
      return (
        <CtaItem
          title={retrieveContentfulData<string>('call_to_action.health_waived', '')}
          onClick={() => handleNext(RESULT_PATH)}
          planScoreProps={{ waived: true }}
          tooltip={{
            id: 'cta-tooltip-waived',
            label: retrieveContentfulData<string>('call_to_action.tooltips.results'),
          }}
        />
      );
    }
    if (!isEmpty(selectedPlan)) {
      const planScoreData = hidePlanScore
        ? undefined
        : {
            score: selectedPlan.score,
            tier: selectedPlan.tier,
          };

      return (
        <CtaItem
          title={selectedPlan.plan.plan_marketing_name}
          onClick={() => handleNext(RESULT_PATH)}
          planScoreProps={planScoreData}
          tooltip={{
            id: 'cta-tooltip-selected-plan',
            label: retrieveContentfulData<string>('call_to_action.tooltips.results'),
          }}
        />
      );
    }
    return (
      <CtaPlaceholderItem>
        <Paragraph size="small" color="--text-gray-light">
          <Text field="call_to_action.health_default" />
        </Paragraph>
      </CtaPlaceholderItem>
    );
  };

  const renderHsa = () => {
    if (isHsaUnlocked && !isEmpty(selectedPlan) && selectedPlan.plan.hsa_eligible) {
      const contribution =
        hsaContribution !== undefined && hsaContribution !== null
          ? hsaContribution
          : recommendedHsaContribution;
      const hsaValue = toDollars(contribution);
      const perYear = retrieveContentfulData<string>('tool_tips.number_with_pay_period.yearly.underline');
      return (
        <CtaItem
          title={`${hsaValue}${perYear}`}
          onClick={() => handleNext(HSA_PLUS_PATH)}
          supplementalPlanType="hsa"
          tooltip={{
            id: 'cta-tooltip-hsa',
            label: retrieveContentfulData<string>('call_to_action.tooltips.hsa'),
          }}
        />
      );
    }
    return null;
  };

  const renderHra = () => {
    if (!isEmpty(selectedPlan) && selectedPlan.plan.hra_eligible) {
      const hraContribution =
        selectedPlan.plan.spending_account_contributions.hra_annual.employer_contribution_amount;

      const tooltipTitle = hraContribution ? (
        <Text field="call_to_action.tooltips.employerContribution" vars={{ x: hraContribution }} />
      ) : (
        <Text field="health_section.recommendation.hra_info_no_contribution" />
      );

      return (
        <CtaItem
          title="HRA"
          tooltip={{
            id: 'cta-tooltip-hra',
            label: {
              text: '',
              title: tooltipTitle,
            },
          }}
          supplementalPlanType="hra"
          onClick={() => null}
          isDisabled
        />
      );
    }
    return null;
  };

  const getSupplementalPopupButtonIcon = () => {
    let icon: Record<string, IconTypes | null> = {
      iconLeft: 'ArrowLeft',
      iconRight: null,
    };
    if (path === RESULT_PATH || path === HSA_PLUS_PATH || path.includes(VIEW_PLAN_PATH)) {
      icon = { iconLeft: null, iconRight: 'ArrowRight' };
    }
    return icon;
  };

  const renderSupplementalItemsContent = () => {
    // ALL_SELECTABLE_PLAN_TYPES is array of plan types in desired order.
    //   - Also include non_selectable_plan types (will only display if they're auto-enrolled)
    const sortedSupplementalPlans = [...ALL_SELECTABLE_PLAN_TYPES, ...ALL_NONSELECTABLE_PLAN_TYPES]
      .map((planType) => {
        // check if selectedPlans has value for plan type, if so, add to 'sorted array'
        const plan = selectedSupplementalPlans[planType];

        if (plan) {
          return plan;
        }

        return null;
      })
      .filter((i) => i) as SupplementalPlan[]; // remove null values from sorted array

    return sortedSupplementalPlans.map((plan) => (
      <CtaItem
        key={`cta-benefit-${plan.plan_type}`}
        title={plan.name}
        supplementalPlanType={plan.plan_type as InsuranceType}
        onClick={() => handleNext(`${VIEW_BENEFIT_PATH}/${plan.plan_type}`)}
      />
    ));
  };

  const renderSupplemental = () => {
    // render the actual supplemental button that opens the popup
    const benefits = Object.values(selectedSupplementalPlans).filter((i) => i);
    if (isSupplementalUnlocked && benefits.length > 0) {
      return (
        <SupplementalPopupContainer>
          <Popover
            placement="top"
            offset={[0, 20]}
            onOpen={() => setIsSupplementalOpen((prev) => !prev)}
            onClose={() => setIsSupplementalOpen((prev) => !prev)}
          >
            <PopoverTrigger>
              <SupplementalToggle>
                <Paragraph weight="bold" size="small" color="--text-black">
                  {retrieveContentfulData<string>('call_to_action.supplemental')}
                </Paragraph>
                <Icon type={isSupplementalOpen ? 'CaretDown' : 'CaretUp'} size="small" color="--text-gray" />
              </SupplementalToggle>
            </PopoverTrigger>
            <PopoverContent className="supplemental-popup" {...chakraFocusOverrides}>
              <div className="popup-content">
                <Button
                  {...getSupplementalPopupButtonIcon()}
                  onClick={() => handleNext(OVERVIEW_PATH)}
                  stretch
                  buttonType="primary"
                  color="black"
                  isDisabled={path === OVERVIEW_PATH}
                >
                  <Text field="call_to_action.supplemental" />
                </Button>
                <div className="supplemental-items">{renderSupplementalItemsContent()}</div>
              </div>
            </PopoverContent>
          </Popover>
        </SupplementalPopupContainer>
      );
    }
    return null;
  };

  // ----------------------
  // MOBILE COMPONENTS
  // ----------------------
  const calculateCompleteCount = () => {
    let count = 0;

    // Count health plan, waived plan, hsa or hra
    if (isCoverageWaived) count += 1;
    if (!isEmpty(selectedPlan)) count += 1;
    if (selectedPlan?.plan?.hsa_eligible) count += 1;
    if (selectedPlan?.plan?.hra_eligible) count += 1;

    // Count selected supplemental plans
    Object.values(selectedSupplementalPlans).forEach((plan) => {
      if (plan) count += 1;
    });

    return count;
  };

  const renderCart = () => (
    <>
      <MobileCartButton isDisabled={calculateCompleteCount() === 0}>
        <Button
          iconLeft="ShoppingCart"
          isDisabled={calculateCompleteCount() === 0}
          onClick={() => setIsMobileCartOpen(true)}
        />
        <span className="cart-count">{calculateCompleteCount()}</span>
      </MobileCartButton>
      <Modal onClose={() => setIsMobileCartOpen(false)} isOpen={isMobileCartOpen} size="full">
        <ModalContent sx={{ height: '100%' }}>
          <MobileCartWrapper>
            <section className="top-content">
              <H2>
                <Text field="call_to_action.cart_header" />
              </H2>
              <Paragraph>
                <Text field="call_to_action.cart_text" />
              </Paragraph>
              <div className="cart-items">
                {renderSelectedPlan()}
                {renderHsa()}
                {renderHra()}
                {renderSupplementalItemsContent()}
              </div>
            </section>
            <section className="close-button">
              <Button
                iconLeft="X"
                buttonType="primary"
                color="black"
                size="small"
                onClick={() => setIsMobileCartOpen(false)}
                stretch
              >
                <Text field="call_to_action.cart_close_button" />
              </Button>
            </section>
          </MobileCartWrapper>
        </ModalContent>
      </Modal>
    </>
  );

  return (
    <ResultsContainer>
      {device === 'mobile' ? (
        <>{renderCart()}</>
      ) : (
        <>
          {renderSelectedPlan()}
          {renderHsa()}
          {renderHra()}
          {renderSupplemental()}
        </>
      )}
    </ResultsContainer>
  );
};
