import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { Button, H5, Paragraph } from 'DesignLibrary/atoms';
import { Recommendation } from 'Types/entities';

import { CardContainer, EligibleCardsContainer } from './styled';

interface CardProps {
  account: 'hsa' | 'hra' | 'fsa';
  employerHsaContribution: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  selectedPlan: Recommendation;
}

const Card = ({
  account,
  employerHsaContribution,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  selectedPlan,
}: CardProps) => {
  const { isOpen: eduOpen, onOpen: eduOnOpen, onClose: eduOnClose } = useDisclosure();
  return (
    <>
      <CardContainer account={account}>
        <div>
          {/* TODO: CONTENTFUL Add Fields */}
          <H5 weight="bold">{account.toLocaleUpperCase()} Eligible</H5>
          <Paragraph size="small">See how much to contribute below.</Paragraph>
        </div>
        <Button onClick={eduOnOpen}>Learn More</Button>
      </CardContainer>
      <EducationalDrawer
        isOpen={eduOpen}
        infoType={account}
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        employerContribution={employerHsaContribution}
        selectedPlan={selectedPlan}
        handleClose={eduOnClose}
      />
    </>
  );
};

export interface EligibleCardsProps {
  isHsaEligible: boolean;
  isFsaEligible: boolean;
  employerHsaContribution: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  selectedPlan: Recommendation;
}

export const EligibleCards = ({
  isHsaEligible,
  isFsaEligible,
  employerHsaContribution,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  selectedPlan,
}: EligibleCardsProps) => {
  const cardProps = {
    employerHsaContribution,
    individualHsaContributionLimit,
    familyHsaContributionLimit,
    selectedPlan,
  };
  if (isHsaEligible) {
    return (
      <EligibleCardsContainer>
        <Card account="hsa" {...cardProps} />
        {isFsaEligible && <Card account="fsa" {...cardProps} />}
      </EligibleCardsContainer>
    );
  }
  if (isFsaEligible) {
    return (
      <EligibleCardsContainer>
        <Card account="fsa" {...cardProps} />
      </EligibleCardsContainer>
    );
  }
  return null;
};
