import { Box, Stack } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { handleUnlockMainNav } from 'Containers/App/actions';
import { COVERAGE_TIERS } from 'Containers/App/constants';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectGlobalField,
} from 'Containers/App/selectors';
import { AlexProducts } from 'Containers/App/types';
import {
  changeFormValue,
  recommendWithEphemeralPlan,
  selectHealthPlan,
} from 'Containers/CommercialRoutes/actions';
import {
  makeGetDisplayPdfLink,
  makeGetHsaContributionLimit,
  makeGetRecommendationByPlanId,
} from 'Containers/CommercialRoutes/selectors';
import { EnteredPlan } from 'Containers/CommercialRoutes/types';
import {
  makeGetCoverageTier,
  makeGetHasSpouse,
  makeGetIsFamily,
  makeGetSelectedProduct,
} from 'Containers/ProfilePage/selectors';
import { DRUG_TYPES, SERVICE_TYPES } from 'Containers/ResultPage/constants';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useProfileContext } from 'Contexts/profileContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';
import { PeopleLikeYou } from 'Utils/apiTypes';
import { RESULT_PATH } from 'Utils/urls';

import { CostBreakdownCard } from './CostBreakdownCard';
import { CtaCards } from './CtaCards';
import Disclaimers from './Disclaimers';
import { DoctorsProvidersCard } from './DoctorsProvidersCard';
import { DoctorVisitsCard } from './DoctorVisitsCard';
import { OtherNotesCard } from './OtherNotesCard';
import { PlanEligibility } from './PlanEligibility';
import { PlanEssentialsCard } from './PlanEssentialsCard';
import { PrescriptionsCard } from './PrescriptionsCard';
import { PricingOverview } from './PricingOverview';
import { Container, CtaCardsWrapper, Footnote, TopBar } from './styled';
import { ViewPlanPageHeader } from './ViewPlanPageHeader';

interface ViewPlanPageStateProps {
  client: string;
  selectedProduct: AlexProducts | '';
  recommendation: Recommendation;
  isFamily: boolean;
  hasSpouse: boolean;
  hsaContributionLimit: number | null;
  selectedPlan: GlobalReducerState['commercialApp']['selectedHealthPlan'];
  searchProviderLinks: {
    [key: string]: string;
  } | null;
  maximumAvailableAnnualSihraContribution: number | null;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  enteredPlan: EnteredPlan;
  displayPdfButton: boolean;
  hidePlanScore: boolean;
  coverageTier: COVERAGE_TIERS;
  peopleLikeYou: PeopleLikeYou | Record<string, never>;
}

interface ViewPlanPageDispatchProps {
  selectPlan: (recommendation: Recommendation | Record<string, never>) => void;
  goToPreviousPage: () => void;
  getRecommendationsWithEphemeralPlan: (enteredPlan: EnteredPlan) => void;
  saveEphemeralPlan: (plan: EnteredPlan) => void;
  lockFuturePages: () => void;
}

export type ViewPlanPageProps = ViewPlanPageStateProps & ViewPlanPageDispatchProps;

export const ViewPlanPage = ({
  recommendation,
  selectedProduct,
  selectedPlan,
  client,
  isFamily,
  hasSpouse,
  hsaContributionLimit,
  searchProviderLinks,
  maximumAvailableAnnualSihraContribution,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  enteredPlan,
  displayPdfButton,
  hidePlanScore,
  coverageTier,
  peopleLikeYou,
  selectPlan,
  goToPreviousPage,
  getRecommendationsWithEphemeralPlan,
  saveEphemeralPlan,
  lockFuturePages,
}: ViewPlanPageProps) => {
  const plan = new Plan(recommendation);
  const { selectedPublicationInfo } = useProfileContext();

  // display provider search section feature enabled from alex builder
  const docFinderEnabled = selectedPublicationInfo?.publication?.docfinder_enabled;

  // Plan properties
  const { planId } = plan;

  const handleSelectHealthPlan = () => {
    // set selected plan on Commercial reducer
    selectPlan(recommendation);
  };

  const handleDeselectHealthPlan = () => {
    // set deselected plan on Commercial reducer
    selectPlan({});
    lockFuturePages();
  };

  // TODO - Do we want to make this predicate a property on `Plan`?
  const isBuildAPlan = recommendation.plan.plan_type === 'Custom';

  const displayEditPlanButton = isBuildAPlan;

  const pdfButtonLink = recommendation.plan.benefits_summary_url || '';

  const displaySearchProviderButton = !isBuildAPlan && searchProviderLinks !== null;
  const searchProviderButtonLink = get(searchProviderLinks, planId, '');

  const planPdfButtonTextField = 'health_section.button_text.planPdf';
  const providerSearchButtonTextField = 'health_section.button_text.providerSearch';

  const getIsAnyCostSubjectToDeductible = () => {
    const serviceCostTypes = [SERVICE_TYPES.PRIMARY_CARE, SERVICE_TYPES.SPECIALIST_CARE];
    const drugCostTypes = [
      DRUG_TYPES.GENERIC_DRUG,
      DRUG_TYPES.BRAND_DRUG,
      DRUG_TYPES.NON_FORMULARY_DRUG,
      DRUG_TYPES.SPECIALTY_DRUG,
    ];
    // We don't display imputed benefits - Make sure we are only checking displayed benefits
    const isAnyDrugCostSubjectToDeductible = drugCostTypes.some(
      (i) => plan[i].deductible_applies && !plan[i].imputed,
    );
    const isAnyServiceCostSubjectToDeductible = serviceCostTypes.some((i) => plan[i].deductible_applies);
    return isAnyDrugCostSubjectToDeductible || isAnyServiceCostSubjectToDeductible;
  };

  const { is_improved_recommendation_explanation_enabled } = useFeatureFlagContext();
  const selectedProductIsGo = selectedProduct === 'go';
  const isImprovedRecExplanationEnabled =
    selectedProductIsGo && is_improved_recommendation_explanation_enabled;

  return (
    <>
      <PageLayout>
        <TopBar>
          <Button
            className="back-to-results"
            iconLeft="ArrowLeft"
            buttonType="standalone"
            onClick={goToPreviousPage}
          >
            <Text field="health_details_section.previous_page_button_text" />
          </Button>
        </TopBar>
      </PageLayout>
      <PageLayout bg="x" id="plan-detail-container">
        <Container>
          <section className="header-card">
            <ViewPlanPageHeader
              recommendation={recommendation}
              selectedPlan={selectedPlan}
              isImprovedRecExplanationEnabled={isImprovedRecExplanationEnabled}
              handleEnroll={handleSelectHealthPlan}
              handleDeselect={handleDeselectHealthPlan}
              maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution || 0}
              displayEditPlanButton={displayEditPlanButton}
              displayPdfButton={displayPdfButton && !isBuildAPlan}
              pdfButtonLink={pdfButtonLink}
              displaySearchProviderButton={displaySearchProviderButton}
              searchProviderButtonTextField={providerSearchButtonTextField}
              searchProviderButtonLink={searchProviderButtonLink}
              individualHsaContributionLimit={individualHsaContributionLimit}
              familyHsaContributionLimit={familyHsaContributionLimit}
              enteredPlan={enteredPlan}
              hidePlanScore={hidePlanScore}
              getRecommendationsWithEphemeralPlan={getRecommendationsWithEphemeralPlan}
              saveEphemeralPlan={saveEphemeralPlan}
              isFamily={isFamily}
              goToPreviousPage={goToPreviousPage}
              plyData={peopleLikeYou}
            />
          </section>

          <Stack gap={2} className="plan-content">
            <Disclaimers client={client} planId={planId} hasSpouse={hasSpouse} />

            <PricingOverview recommendation={recommendation} hsaContributionLimit={hsaContributionLimit} />

            <PlanEligibility
              recommendation={recommendation}
              selectedPlan={selectedPlan}
              maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution || 0}
              individualHsaContributionLimit={individualHsaContributionLimit}
              familyHsaContributionLimit={familyHsaContributionLimit}
              hsaContributionLimit={hsaContributionLimit}
            />

            <PlanEssentialsCard client={client} recommendation={recommendation} coverageTier={coverageTier} />

            <PrescriptionsCard client={client} recommendation={recommendation} />

            {docFinderEnabled && <DoctorsProvidersCard planId={planId} />}

            <DoctorVisitsCard client={client} recommendation={recommendation} />

            {getIsAnyCostSubjectToDeductible() && (
              <Footnote>
                <Paragraph size="small" id="afterDeductible" aria-hidden>
                  *<Text field="health_details_section.after_deductible" />
                </Paragraph>
              </Footnote>
            )}

            <CostBreakdownCard recommendation={recommendation} hsaContributionLimit={hsaContributionLimit} />

            <OtherNotesCard
              recommendation={recommendation}
              hsaContributionLimit={hsaContributionLimit || 0}
            />

            <CtaCardsWrapper>
              <CtaCards
                left={{
                  isVisible: displayPdfButton && !isBuildAPlan,
                  headerTextField: 'health_details_section.cta_pdf_header_text',
                  buttonTextField: planPdfButtonTextField,
                  buttonUrl: pdfButtonLink,
                  type: 'pdf',
                }}
                right={{
                  isVisible: displaySearchProviderButton,
                  headerTextField: 'health_details_section.cta_provider_header_text',
                  buttonTextField: providerSearchButtonTextField,
                  buttonUrl: searchProviderButtonLink,
                  type: 'provider',
                }}
              />
            </CtaCardsWrapper>
          </Stack>
        </Container>
      </PageLayout>
      <PageLayout>
        <Box pt={10} pb={24} className="bottom-button">
          <Button
            className="back-to-results"
            iconLeft="ArrowLeft"
            buttonType="standalone"
            onClick={goToPreviousPage}
          >
            <Text field="health_details_section.previous_page_button_text" />
          </Button>
        </Box>
      </PageLayout>
    </>
  );
};

const mapStateToProps = (state, props) =>
  createStructuredSelector<GlobalReducerState, ViewPlanPageStateProps>({
    client: makeSelectConfigField('client'),
    selectedProduct: makeGetSelectedProduct(),
    recommendation: makeGetRecommendationByPlanId(props.match.params.planId),
    isFamily: makeGetIsFamily(),
    hasSpouse: makeGetHasSpouse(),
    hsaContributionLimit: makeGetHsaContributionLimit(),
    maximumAvailableAnnualSihraContribution: makeSelectCommercialField('annual_sihra_contribution'),
    searchProviderLinks: makeSelectConfigField('provider_search_links'),
    selectedPlan: makeSelectCommercialField('selectedHealthPlan'),
    individualHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitIndividual'),
    familyHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitFamily'),
    enteredPlan: makeSelectCommercialField('entered_plan'),
    displayPdfButton: makeGetDisplayPdfLink(),
    hidePlanScore: makeSelectConfigField('hide_plan_score'),
    coverageTier: makeGetCoverageTier(),
    peopleLikeYou: makeSelectCommercialField('people_like_you'),
  });

export function mapDispatchToProps(dispatch): ViewPlanPageDispatchProps {
  return {
    selectPlan: (plan) => dispatch(selectHealthPlan(plan)),
    goToPreviousPage: () => dispatch(push(RESULT_PATH)),
    getRecommendationsWithEphemeralPlan: (plan) => dispatch(recommendWithEphemeralPlan(plan)),
    saveEphemeralPlan: (plan) => dispatch(changeFormValue(plan, 'entered_plan')),
    lockFuturePages: () => dispatch(handleUnlockMainNav(RESULT_PATH)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ViewPlanPage);
