import { ContentfulHealthSection } from 'ContentfulDefaults/types/health';

import { fsaModal, hraModal, hsaModal } from './healthSectionEducationalModals';

export const health: {
  health_section: ContentfulHealthSection;
} = {
  health_section: {
    contentful_name: 'Health Section (DEFAULT DO NOT CHANGE)',
    page_header: {
      text: 'Choose a Health Plan',
      text_alternate: 'Compiling Your Responses',
      text_welcome: 'Welcome back!',
      subtext: 'There are {x} plans that you are eligible to choose from.',
      subtext_alternate: 'There is {x} plan that you are eligible to choose from.',
      previous_selected_plan_text:
        'There are {x} plans that you are eligible to choose from. The plan you picked last time is highlighted up top. ',
      previous_selected_plan_text_alternate:
        'There is {x} plan that you are eligible to choose from. The plan you picked last time is highlighted up top. ',
    },
    loading_statuses: {
      '25%': 'Compiling your responses...',
      '50%': 'Compiling your responses...',
      '75%': 'Analyzing eligible plans...',
      '100%': 'Just about finished...',
    },
    title_info_bar: {
      profileComplete: 'Awesome, profile complete!',
    },
    button_text: {
      hra: "What's an HRA?",
      hsa: "What's an HSA?",
      next: 'Continue',
      sihra: "What's an SIHRA?",
      review: 'Go to Review',
      compare: 'Compare',
      hsaPlus: 'Go to HSA',
      noPlans: 'Back to Eligibility',
      allPlans: 'Back to All Plans',
      viewPlan: 'View Details',
      choosePlan: 'Choose Plan',
      noCoverage: 'Waive Coverage',
      selectedPlan: 'Unselect',
      deselectPlan: 'Deselect',
      showAllPlans: 'Show All Plans',
      supplemental: 'Go to Supplemental Plans',
      insuranceModal: 'More on Health Insurance',
      providerSearch: 'Search Providers',
      summaryOfBenefits: 'Full Plan Details',
      compareModalShowOut: 'Show Out-of-Network',
      planPdf: 'Plan PDF',
    },
    plan_tips: {
      ranking: {
        text: {
          p1: 'Based on the information you’ve provided about your health care needs and preferences, we’ve evaluated the costs and coverage of each plan and identified your best matches.',
          p2: 'When we assess value, we look at the coverage you might get and the premiums that you will pay for each plan. To estimate the coverage that you might get, we simulate the experiences of people like you in each plan, using actual medical claims, and then we use your responses to the risk preference questions to evaluate how much value you get from additional risk protection.',
          p3: 'It’s important to note that even if you have a strong preference for risk protection, your best value may still be a plan with lower coverage. This might be because you don’t face a lot of risk or because the higher coverage options are priced high enough that they will never be a good value.',
        },
        underline: 'How are these plans ranked?',
      },
      bestMatch: {
        text: "Based on your preferences, plan details, and predicted health care costs, we've identified this plan to be the best match for you{x}.",
        title: 'Your Best Match',
        underline: 'Your Best Match!',
      },
      colorTiers: {
        redText:
          'This plan gets a Red rating.  This means that it’s not a very good value, and there is a very low chance that you will ever end up saving money with this plan.',
        redTitle: 'Red Plan',
        greenText:
          'This plan gets a Green rating.  This means that it’s a good value for you, based on your predicted health care needs and preferences.',
        greenTitle: 'Green Plan',
        yellowText:
          'This plan gets a Yellow rating. This means that it’s a moderate value for you, but you will get a better value if you pick a plan with a Green rating.',
        yellowTitle: 'Yellow Plan',
      },
      hsaMatching: {
        text: "If you pick this HSA Eligible health plan, your employer will match {matchPercent}% of your contributions up to {matchLimit} made to your HSA. You can use this to help pay your medical bills, and you get to keep what you don't use.",
        title: 'What does that mean?',
        underline: 'match {matchPercent}% (up to {matchLimit}) of your contributions',
      },
      hraContribution: {
        text: 'If you pick this HRA Eligible health plan, your employer will contribute up to {x} to the account. You can use this to help pay for your medical bills.',
        title: 'Whats does that mean?',
        underline: 'contribute up to {x} to your HRA',
      },
      hraIntroduction: {
        text: 'A Health Reimbursement Arrangement (HRA) is a spending account that your employer sets up to help pay for medical expenses that your insurance doesn’t cover. If your employer set up an HRA, you can submit receipts for medical bills that you have paid and your employer will reimburse those charges up until you have used up all of your allocated funds. Any HRA funds that you do not use go back to your employer at the end of the year, but your employer may allow you to carry over some portion of your HRA to the following year.',
        title: 'Health Reimbursement Account (HRA)',
      },
      hsaContribution: {
        text: "If you pick this HSA Eligible health plan, your employer will contribute {x} to your HSA. You can use this to help pay your medical bills, and you get to keep what you don't use.",
        underline: 'contribute {x} to your HSA',
      },
      hsaIntroduction: {
        text: "HSAs are special accounts that allow you to save and invest money to pay for health care expenses. You never pay taxes on HSA contributions, earnings or withdrawals, and this triple-tax advantage makes them the single best way to save for your health care costs both now and in the future. Individuals can contribute up to {x} per year (individuals age 55 or older may be eligible to make a catch-up contribution of $1,000), and families can contribute up to {x} per year in 2022. Once you save up enough to reach your HSA administrator's minimum savings requirement, you can invest any excess funds that you accumulate.",
        title: 'Health Savings Account (HSA)',
      },
      sihraContribution: {
        text: "If you pick this SIHRA Eligible health plan offered by your spouse's employer, your employer will contribute up to {x} to the account. You can use this to help pay for your medical bills.",
        title: 'What does that mean?',
        underline: 'contribute up to {x} to your SIHRA',
      },
      sihraIntroduction: {
        text: 'A Spousal Incentive Health Reimbursement Arrangement (SIHRA) is a spending account that your employer sets up to help pay for medical expenses that your insurance doesn’t cover. If your employer set up an SIHRA, you can submit receipts for medical bills that you have paid and your employer will reimburse those charges up until you have used up all of your allocated funds. Any SIHRA funds that you do not use go back to your employer at the end of the year, but your employer may allow you to carry over some portion of your SIHRA to the following year.',
        title: 'Spousal Incentive Health Reimbursement Account (SIHRA)',
      },
    },
    no_plans_modal: {
      title: 'No Plan Available',
      description:
        'Based on your responses to the eligibilty questions, you are not eligible for any plans. Please make sure you’ve answered these eligibility questions correctly or contact your HR department for more details.',
    },
    plans_heading: 'Your Health Plans',
    insurance_modal_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The basic idea of health insurance is that you pay an up front fee, referred to as a premium, and in exchange, your insurance plan will cover some of the cost of your medical bills. The amount that your insurance plan covers can depend on many factors that can get pretty complicated. Don’t worry about that, though! Picwell will look at all of those details, so you don’t have to. Even so, there are a few key terms that you should know that will help you understand how health insurance works.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Plan Types',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'You might have noticed that there are a lot of acronyms floating around in the health insurance world. ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HMO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'PPO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', and ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HDHP',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' are all acronyms that describe some important features of your health plan.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HMO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' stands for health maintenance organization. HMOs usually provide more restrictions on the health care that you get. You’ll need a referral from your primary care provider before you get any non-emergency medical care, and non-emergency out-of-network costs may not be covered. HMOs that cover some portion of out-of-network costs are often referred to a ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'Point of Service (POS)',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' plans. The upside of an HMO is that these restrictions are aimed at lowering costs, so you’ll usually pay lower premiums for these types of plans.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'PPO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' stands for preferred provider organization. PPOs don’t require you to get a referral for non-emergency medical care, and they will provide more comprehensive coverage for out-of-network care than an HMO. PPOs provide greater flexibility in how you get your medical care, but you’ll usually pay higher premiums for these types of plans.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HDHP',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' stands for high deductible health plan. The defining feature of an HDHP is its deductible, Plans that are HMOs or PPOs can both be HDHPs. The term HDHP is usually reserved for plans that have a deductible that is high enough to be paired with an HSA. In 2022, that means the deductible needs to be at least $1,400 for individual coverage and $2,800 for family coverage.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Premiums',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Your premiums are the price that you pay for health insurance. As a general rule, the more you pay in premiums, the more your insurance plan will pay when you get a medical bill. High premiums are also frequently associated with things like broader provider networks and less restrictions for things like seeing a specialist. (We’ll cover those later).',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Premiums are tax free. This means that if you get your health insurance through your employer, your premiums are deducted from your paycheck before you pay any taxes.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Deductibles',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'The ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'deductible',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' defines the amount that you need to pay before your insurance starts covering costs. If your plan has a deductible of $1,000, your insurance will not start covering any of your medical bills until you have incurred that much in medical bills. Even if your plan does have a deductible, preventive care will always be covered from the get go. This means that even if you have a plan with a high deductible, you won’t have to pay anything for things like vaccinations, an annual physical or a well-baby visit.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Some health plans will have different deductibles for prescription drugs and other medical care. For example, if a plan has a $0 deductible for prescription drugs and a $1,000 deductible for other medical care, that means your insurance starts covering the cost of prescriptions right away.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Copayments and Coinsurance',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Copayments and coinsurance define how much of a medical bill you pay. If your plan has a deductible, copayments and coinsurance kick in after you have met your deductible. A copayment refers to a fixed dollar amount that you pay for a medical service, while coinsurance refers to a percentage of the bill that you pay.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Here are a couple examples.',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' Let’s say your health plan charges a $20 copay for a primary care office visit and has a 10% coinsurance rate for a specialist office visit. If you have already met your deductible, then in this scenario, you’ll pay $20 for the primary care appointment, no matter how much the final bill turns out to be, but the amount you pay for the specialist appointment will depend on the bill. If the specialist bill is $400, you pay $40, but if the bill turns out to be $500, you pay $50.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Out-of-pocket (OOP) maximums',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Your out-of-pocket (OOP) costs refer to the total amount that you pay for your medical bills after you add up your deductible, coinsurance and copayments. Your health plan’s OOP maximum places a cap on how much you are responsible for paying each year. Once the sum of your deductible, coinsurance and copayments reach the OOP maximum, your health plan will cover any remaining medical bills.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Here’s an example.',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' Let’s say you get really sick and end up getting admitted to the hospital for a week, and you wrack up $21,000 in medical bills. If you have a health plan with a $1,000 deductible, it charges a 20% coinsurance rate for hospital admissions and has an OOP maximum of $3,000, here’s how the health plan would calculate your payments. Right off the bat, you’ll pay $1,000, leaving $20,000 that would be covered by coinsurance. Your coinsurance rate of 20% would have you paying an additional $4,000 in coinsurance payments, but since the OOP maximum caps your cost at $3,000, you only pay an additional $2,000 in coinsurance payments.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Formulary',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'A formulary is a list of prescription drugs that defines what your health plan covers and how it is covered. If a prescription is not included on the formulary, it may not be covered by your health insurance, meaning that you would be responsible for paying the full cost of that prescription. If a prescription is on the formulary, then the amount that you pay will depend on the tier that the drug is on. Formularies usually have different tiers for generic drugs, preferred brand name drugs, non-preferred brand name drugs. You usually pay the least for prescriptions that are on the generic drug or preferred brand name drug tiers.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value:
                "If you take any routine prescriptions, it's always a good idea to make sure that your prescription is on the formulary and find out what tier it is on.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Provider Networks',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Provider networks are lists of health care providers and facilities that are included in a health plan’s network. Your health plan will help cover costs that you incur from “in-network” providers, but if you get medical care from out-of-network providers, your health plan may cover less of the cost or not cover those costs at all.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'If you have any health care providers or locations that you routinely go to, make sure to check that they are in-network before enrolling in a health plan. In many cases, plans with fewer providers in network charge lower premiums. This can give you an opportunity to save money, but it may mean that you would need to switch doctors to make sure your plan will help cover your medical bills.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    eligibility_text: {
      hra: {
        tag: 'HRA Eligible',
        standardPlan: 'This is an HRA eligible plan.',
      },
      hsa: {
        tag: 'HSA Eligible',
        standardPlan: 'This is an HSA eligible plan.',
        contributionPlan:
          "This plan allows you to contribute to an HSA (Health Savings Account).  You can use this to help pay your medical bills, and you get to keep what you don't use",
      },
      sihra: {
        tag: 'SIHRA Eligible',
        standardPlan: 'This is an SIHRA eligible plan.',
      },
    },
    client_disclaimers: {
      resultPageSihra: {
        text: "Enroll in your spouse's insurance plan and your employer will contribute up to {x} to a Spousal Incentive HRA account.",
        title: 'Spousal Incentive HRA Eligible',
      },
      resultPageSpousal: {
        text: 'This is a result page spousal plan disclaimer',
        title: "Head's up",
      },
      viewPlanPageSpousal: {
        text: 'This is a plan details disclaimer',
        title: "Head's up",
      },
      buildAPlanModalSihra: {
        note: 'Please note:  The SIHRA benefit cannot be combined with any spousal health plan that includes the employer or employee contributing to an HSA .  Having an existing balance in an HSA is allowed for SIHRA participation.',
        text: "Enroll in your spouse's insurance plan and your employer will contribute up to {x} to a Spousal Incentive HRA account.",
        title: 'Spousal Incentive HRA Eligible',
      },
    },
    employer_involvement_options: {
      hsaMatch: 'HSA Match',
      hraContribution: 'HRA Contribution',
      hsaContribution: 'HSA Contribution',
    },
    hsa_hra_employer_contribution: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'and your employer will ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
                {
                  type: 'bold',
                },
              ],
              value: 'contribute/match',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' next year.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    no_employer_contribution_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "If you pick an HSA-eligible health plan, you can make pre-tax contributions to help pay your medical bills, and you get to keep what you don't use to help pay for future health care expenses. Click on the plan to see how much you can save on taxes by paying for your out-of-pocket costs through your HSA.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsa_video: 'https://vimeo.com/499286061',
    hra_video: 'https://vimeo.com/499286770',
    hsa_modal_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'While health insurance helps you cover the cost of medical care, it does not cover everything. A Health Savings Account, or HSA for short, is a great way to save for those expenses.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Here’s how it works:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- First, after enrolling into HSA eligible health plan, open up a health savings account.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Next, choose a contribution amount which will get deducted from your paycheck. Some employers will help you by also contributing to your HSA. Any money you don’t use this year is yours to keep, even if you switch jobs.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Here are some other things you should know:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- You can use your HSA to pay for qualified medical expenses including medical copays, vision and dental expenses, prescription drugs, and more.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '- Any money you contribute to an HSA lowers your taxes.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '- Individuals can contribute up to ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' per year and families can contribute up to ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' per year in 2022.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- As your balance grows, you can invest that money. The best part is that any earnings and any withdrawals for qualified expenses are tax-free.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Please make sure to carefully review your plan details for more information.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_modal_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Your health insurance plans come with a Health Reimbursement Arrangement, or HRA. An HRA is an account that your employer sets up and contributes money to in order to help you pay for qualified out-of-pocket health care expenses. This could include medical copays, vision and dental expenses, prescription drugs, and more.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Here’s how it works:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Once you enroll into an HRA eligible plan, your employer will fund an account that you can use to offset your medical expenses.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- As you get medical care, the insurance company will pay your medical bills that are covered under your medical plan from your HRA until you have used up all of your allocated funds. Depending how your employer set up your HRA, you might have to submit your receipts for reimbursement. But keep in mind, your reimbursements are limited to the amount your employer funds your HRA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Here are some other things you should know:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- If you don’t use all of your HRA dollars for reimbursement, you may be able to use those funds in future years, depending on how much your employer allows you to roll-over.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- If you decide to enroll in a plan that is not HRA qualified or leave your current employer, you will lose all of the HRA money your employer has contributed.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Please make sure to carefully review your plan details for more information.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    waive_coverage_text: "Don't want health insurance?",
    waive_coverage_modal: {
      title: 'Health Plan Waived',
      disclaimer: 'Come back at any time to update your plan.',
      description:
        "You've decided to waive your health coverage. You can always change this decision later by returning to this page and selecting another health plan.",
      loadingText: 'Waiving Your Health Plan',
    },
    saved_plan_modal: {
      title: 'Health Plan Saved',
      disclaimer: 'Come back at any time to change your plan.',
      loadingText: 'Saving Your Health Plan',
    },
    hsa_reminder: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Important HSA Reminder!',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "You've selected an HSA Eligible health care plan. Make sure to properly open an HSA account to take advantage of what an HSA has to offer.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_reminder: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Important HRA Reminder!',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'You’ve selected an HRA eligible health care plan. An account will automatically be set up for you. There are no additional tasks needed to access this fantastic benefit!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsahra_plan_details: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'If you pick this ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' Eligible health plan, your employer will ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ". You can use this to help pay your medical bills, and you get to keep what you don't use. View the plan details below to see how your employer's ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' helps you save.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsa_plan_details: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'If you pick this HSA Eligible health plan, your employer will ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ". You can use this to help pay your medical bills, and you get to keep what you don't use. View the plan details below to see how your employer's ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' helps you save.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_plan_details: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'If you pick this HRA Eligible health plan, your employer will ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ". You can use this to help pay your medical bills. View the plan details below to see how your employer's ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' helps you save.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    plan_details_heading: 'Plan Details',
    comprehensive_plan_terms: {
      drug: 'Drug',
      medical: 'Medical',
    },
    breakdown_heading: 'Cost Breakdown',
    plan_cost_contributions: {
      oop: {
        no: 'Employer {x} contributions do not cover the cost of your out-of-pocket costs.',
        yes: 'Employer {x} contributions will help you pay for your out-of-pocket costs.',
      },
      premium: {
        no: 'Employer {x} contributions do not cover the cost of your premium.',
        yes: 'Employer {x} contributions will help you pay for your premium costs.',
      },
    },
    premium_cost_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Premium Costs',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'You will always pay premiums for this plan. ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    oop_cost_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Out-of-Pocket Estimated Costs',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We calculate estimated out-of-pocket costs by comparing your answers against millions of data points from people like you. ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    premium_cost_receipt: {
      yearlyCost: 'Yearly Cost',
      premiumCosts: 'Premium Costs',
      yearlyPremiums: 'Yearly Premiums',
      yearlyMultiplier: 'Yearly Multiplier',
    },
    oop_cost_receipt: {
      drugs: 'Prescription Drugs',
      hsaMatch: 'Employer HSA Match',
      medicalCare: 'Medical Care',
      oopEstimate: 'Out-of-pocket Estimate',
      remainingOop: 'Remaining Out-of-Pocket',
      hraContribution: 'Effective Employer HRA Contribution',
      hsaContribution: 'Employer HSA Contribution',
      recommendedHsaContribution: 'Recommended HSA Contribution',
      hsaTaxSavings: 'HSA Tax Savings',
      noReimburseCopay: 'HRA does not cover copay reimbursement.',
    },
    other_plan_notes: {
      title: 'Other Plan Notes',
      hsaEligible: {
        employerContribution:
          'Make sure to check how your employer distributes HSA contributions when finalizing your plan. Sometimes employers distribute funds throughout the year meaning the funds might not be fully available to pay your medical expenses when your plan starts.',
        'oopEstimate_<_hsaLimit':
          'You might want to consider contributing more than the recommended HSA contribution as it will help with future healthcare costs.',
        'employerContribution_>_oopEstimate': {
          employer:
            'Your employer HSA contribution will cover your estimated out-of-pocket expenses. This leaves you with {remainder} ({hsaContribution} minus {oopEstimate}) left in your HSA. This extra value will stay in your HSA until you need to use it.',
          personal:
            "Your personal HSA contribution recommendation is $0 because your employer's HSA contribution covers all of your estimated out-of-pocket costs. You can always add to your HSA to cover unforeseen costs.",
        },
      },
      comprehensiveInNetworkOopLimit:
        'Your maximum out-of-pocket is {inNetworkMax}. In a worst-case scenario, you will not pay more than {inNetworkMax} for in-network covered services in 2022.',
    },
    next_steps: {
      fbs: "Next up, let's choose your supplemental coverage",
      noFbs: "Let's review your selections",
      hsaPlus: "Next up, let's look into your Health Savings Account.",
    },
    contribution_types: {
      hra: 'HRA',
      hsa: 'HSA',
      both: 'HSA & HRA',
      sihra: 'SIHRA',
      hra_sihra: 'HRA & SIHRA',
    },
    provider_search_text: {
      heading: 'Search Providers',
      subheading: "Lookup providers within {x}'s network.",
    },
    summary_of_benefits: {
      heading: 'Need More Details?',
      subheading: 'Take a look at the full plan details PDF.',
    },
    null_plan_detail_text: {
      deductibleAndOopMax: 'Unlimited',
    },
    compare_plan_text: {
      compareBar: {
        thirdPlan: 'Compare up to 3 plans',
        secondPlan: 'Select a second plan',
      },
      compareModal: {
        copay: 'Copay',
        inNetwork: 'In-Network',
        outOfNetwork: 'Out-Of-Network',
        deductible: 'Deductible',
        oopLimit: 'Out-Of-Pocket Limit',
        terms: {
          premium: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Premium',
          },
          planType: {
            term: 'NO TOOLTIP',
            title: 'Plan Type',
          },
          brandDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Brand Drug',
          },
          genericDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Generic Drug',
          },
          oopEstimate: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Out-of-Pocket Estimate',
          },
          primaryCare: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Primary Care',
          },
          estYearlyTotal: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Estimated Yearly Total',
          },
          specialistCare: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Specialist Care',
          },
          nonFormularyDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Non-formulary Drug',
          },
          specialtyDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Specialty Drug',
          },
          score: {
            term: 'The Score indicates how good a value each plan is, for you, personally, based on what your health care needs are along with your preferences for risk protection. The best value available to you will have the highest score, but you should think of any plan with a Score above 90 as a great match for you.',
            title: 'Score',
          },
        },
        headers: {
          copays: 'Copays and Coinsurances',
          mainDetails: 'The Main Details',
          deductiblesLimits: 'Deductibles and Limits',
        },
        perVisit: 'per visit',
        modalTitle: 'Compare Your Plans',
        notCovered: 'Not Covered',
        coinsurance: 'Coinsurance',
        selectThirdPlan: 'Psst, you can compare up to 3 plans at once.',
        afterDeductibleAsterisk: 'After deductible is met',
      },
    },
    min_max_cost_sharing: {
      maxCostSharing: ' up to {x}',
    },
    recommendation: {
      contentful_name: '[DEFAULT] Health Recommendation',
      yearly_expense_total: 'YEARLY EXPENSE TOTAL',
      yearly_premium: 'YEARLY PREMIUM',
      premium: 'PREMIUM',
      deductible: 'DEDUCTIBLE',
      out_of_pocket_estimate: 'OUT-OF-POCKET EST.',
      out_of_pocket_max: 'OUT-OF-POCKET MAX.',
      view_details: 'View Details',
      choose_plan_button: 'Choose Plan',
      compare: 'Compare',
      unlimited: 'Unlimited',
      unselect: 'Unselect',
      hsa_info_with_employer_match: {
        contentful_name: '[DEFAULT] HSA Info With Employer Match',
        header: 'HSA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will match ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' of your contributions up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hsa_info_with_contribution: {
        contentful_name: '[DEFAULT] HSA Info With Contribution',
        header: 'HSA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will contribute up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' annually.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hsa_info: {
        contentful_name: '[DEFAULT] HSA Info',
        header: 'HSA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'Pay for medical bills with tax-free HSA contributions',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hra_info_with_contribution: {
        contentful_name: '[DEFAULT] HRA Info With Contribution',
        header: 'HRA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will contribute up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' annually.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hra_info_no_contribution: 'You’ve chosen an HRA eligible plan',
      sihra_info_with_contribution: {
        contentful_name: '[DEFAULT] SIHRA Info With Contribution',
        header: 'SIHRA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will contribute up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' annually.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      fsa_info_with_contribution: {
        contentful_name: '[DEFAULT] FSA Info With Contribution',
        header: 'FSA Eligible',
        text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'and your employer will contribute ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [{ type: 'code' }, { type: 'bold' }],
                  value: '{x}',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        color: 'green',
        icon: 'CurrencyDollar',
      },
      fsa_info: {
        contentful_name: '[DEFAULT] FSA Info',
        header: 'FSA Eligible',
        text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Pay for medical bills with tax-free FSA contributions',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        color: 'green',
        icon: 'CurrencyDollar',
      },
      plan_fit_labels: {
        contentful_name: '[default] Plan Fit Tiers',
        best_fit_label: 'Best Match',
        great_fit_label: 'Good Match',
        fair_fit_label: 'Fair Match',
        poor_fit_label: 'Poor Match',
        best_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, this plan is likely the best value for you.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        great_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, this plan looks like a good value for you.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        fair_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, this plan looks like a decent fit for you. You may get a better value if you pick a plan with a “good fit” rating or higher.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        poor_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, there’s a low chance you’ll end up saving money with this plan. You’ll likely get a better value if you pick a plan with a “good fit” rating or higher.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        plan_fit_link_text: 'your plan fit breakdown',
        why_button_text: 'Why?',
      },
      in_network_providers_heading: 'IN-NETWORK',
      in_network_providers_add_button: 'Add providers',
      in_network_providers_none: 'none',
      in_network_providers_add_provider_text: 'Add your providers to see if they are covered by this plan.',
      invalidate_plan_message: {
        noLongerEligible: 'The plan you picked last time is no longer available to you.',
        everyoneNotCovered:
          'You’ve updated who you’re covering so some plan details may have changed. Make sure everything still looks good before you continue. ',
      },
    },
    secondary_content: {
      explore_our_predictions_cta: 'We rank your plans from best (99) to worst. Curious how we do it?',
      explore_our_predictions_button_text: 'Explore Our Predictions',
      build_a_plan_cta: 'Use our Build-A-Plan tool to consider any external health plans.',
      build_a_plan_button_text: 'Build-A-Plan',
      build_a_plan_instructions_button_text: "Here's What You Need",
      build_a_plan_instructions_button_link:
        'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-English.pdf',
      waive_coverage_cta: "Don't need an employer health plan?",
      waive_coverage_button_text: 'Waive Coverage',
      hsa_hra_fsa_cta: 'Learn more about these savings and spending accounts.',
      hsa_button_text: 'HSA',
      hra_button_text: 'HRA',
      fsa_button_text: 'FSA',
      member_selection_mobile_header: {
        everyone: 'Covering all members',
        yourself: 'Covering yourself',
        familyMembers: 'Covering {x} members',
      },
      member_selection_header: 'Who are you covering?',
      member_overage_dependent_disclaimer: {
        contentful_name: '[DEFAULT] ',
        header: 'Disclaimer for dependents that are too old to cover',
        text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Too old!',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        color: 'blue',
        icon: 'CheckCircle',
      },
      tooltips: {
        memberSelectionHeader: {
          text: 'I am in [Health] Secondary Content > Tooltips',
          title: 'JAKE BLANK!',
        },
        providerSelectionHeader: {
          text: 'Create a list of your doctors and other healthcare providers to learn which plans include them in-network.',
        },
        continue_button_tooltip: {
          text: 'Note: Each provider’s network status can change at any time, so it’s a good idea to contact them before your next appointment to see if they are still in-network.',
        },
      },
      member_selection_info_bar: {
        contentful_name: '[Health] Member Selection Info Bar',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'Build-a-plan details will have to be re-added if you update household members.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      member_selection_update_button: 'Update Members',
      member_selection_pregnancy_save: 'Save Answer',
      member_selection_pregnancy_question:
        "You've updated who you're covering. Is your covered family still planning for a pregnancy in the upcoming year?",
      member_selection_modal_header: 'Update Profile Questions',
      member_selection_modal_incentives_prompt:
        'You changed who you will be covering and might need to make adjustments to your incentive questions.',
      hsa_modal: hsaModal,
      hra_modal: hraModal,
      fsa_modal: fsaModal,
      accessibility: {
        waived_coverage_alert: 'Coverage Waived',
        loading_bar_alert: 'Analyzing your eligible plans',
        loading_bar_complete_alert: 'Your plans are ready',
        compare_bar_remove_plan_button: 'Remove Plan',
        plan_selected_alert: 'Plan selected',
        compare_modal_add_plan_alert: 'Selected {x}',
        compare_modal_remove_plan_alert: 'Removed {x}',
      },
      provider_selection_header: 'Doctors & Providers',
      provider_selection_add_button: 'Add Providers',
      provider_selection_update_button: 'Update Providers',
      provider_selection_cta_no_providers: 'No doctors or providers added yet.',
      none_added: 'None added',
    },
    text_note: {
      short_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'text note short text',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      long_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'long text text note',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      button_text: 'Read More',
    },
    text_notes: [
      {
        plan_year: 2024,
        short_text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'text note short text',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        long_text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'long text text note',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        button_text: 'Leer más',
      },
    ],
    provider_selection_modal: {
      title: 'Doctors & Providers',
      zipcode_label: 'ZIP CODE',
      zipcode_validation_error: 'Enter a valid zip code',
      save_button: 'Continue',
      search_char_limit_cta: 'Enter at least 3 characters',
      search_no_results_cta: 'No results',
      distance_label: 'DISTANCE AWAY IN MILES',
      select_location_label: 'Select a location',
      add_button_cta: 'Add',
      cancel_button_cta: 'Cancel',
      create_list_description:
        'Create a list of your doctors and other healthcare providers to learn which plans include them in-network.',
      locations: 'Locations',
      location: 'Location',
      footer_text: 'Check provider status',
      update_button_cta: 'Update',
      loading: 'Loading...',
      search_placeholder: 'Search for doctors or providers',
    },
  },
};
