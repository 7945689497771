import React from 'react';

import { RiskAversion, Survey } from 'Containers/ProfilePage/types';
import { BuilderBenefit, InsuranceType } from 'Containers/ViewBenefitPage/types';
import { ContentfulFbsBenefit } from 'ContentfulDefaults/types/benefits';
import { useTextContext } from 'Contexts/textContext';
import { H4, H6, Icon, Tooltip } from 'DesignLibrary/atoms';
import { SupplementalIcon } from 'Shared/SupplementalIcon';
import Text from 'Shared/Text';
import { CoveredMembers, ReasonsWhy, SupplementalPlan } from 'Types/entities';

import { CONTENT_MAP } from './constants';
import PlanContent from './PlanContent';
import SelectedPlanCard from './SelectedPlanCard';
import { Container, Header, RecommendedBadge } from './styled';

export interface SupplementalCardProps {
  client: string;
  employerName: string;
  insuranceType: InsuranceType;
  builderBenefits?: Record<string, BuilderBenefit> | Record<string, never>;
  selectedPlan?: SupplementalPlan | null;
  coveredMembers?: CoveredMembers;
  // only used to determine fsa/fsa_medical values
  healthHsaEligible?: boolean;
  supplementalPlans?: SupplementalPlan[];
  recommendedLabel?: string | null;
  isEducational?: boolean;
  reasonsWhy: ReasonsWhy;
  selectedHealthDeductible: number;
  dependentsCount: number;
  surveyAnswers: Survey;
  riskAversion: RiskAversion;
  contentfulPlan?: ContentfulFbsBenefit;
  fbsBestMatch?: boolean;
  builderCustomerKey: string | null;
  handleOpenBenefit: (insuranceType: InsuranceType) => void;
}

export const SupplementalCard = ({
  client,
  employerName,
  insuranceType,
  builderBenefits,
  selectedPlan,
  coveredMembers = {},
  healthHsaEligible = false,
  supplementalPlans = [],
  recommendedLabel,
  isEducational = false,
  reasonsWhy,
  selectedHealthDeductible,
  dependentsCount,
  surveyAnswers,
  riskAversion,
  contentfulPlan,
  fbsBestMatch = false,
  builderCustomerKey,
  handleOpenBenefit,
}: SupplementalCardProps) => {
  const { retrieveContentfulData } = useTextContext();

  const isFsaOrFsaMedical = healthHsaEligible && ['fsa', 'fsa_medical'].includes(insuranceType);

  const cardTitle = (): string => {
    if (isEducational) {
      return contentfulPlan?.card_title || '';
    }

    if (isFsaOrFsaMedical) {
      return retrieveContentfulData<string>(CONTENT_MAP[insuranceType].titleAlt, '');
    }

    return retrieveContentfulData<string>(CONTENT_MAP[insuranceType].title, '');
  };

  const cardDescription = (): string => {
    if (isEducational) {
      return contentfulPlan?.card_description || '';
    }

    if (isFsaOrFsaMedical) {
      return retrieveContentfulData<string>(CONTENT_MAP[insuranceType].descriptionAlt, '');
    }

    return retrieveContentfulData<string>(CONTENT_MAP[insuranceType].description, '');
  };

  const title = cardTitle();
  const description = cardDescription();
  const isEmployerPaid = supplementalPlans.some((i) => builderBenefits?.[i.external_id]?.is_employer_paid);
  const isAutoEnrolled = selectedPlan ? selectedPlan.auto_enrolled : false;
  const planCount = supplementalPlans.length;
  const recommended = recommendedLabel === 'not_recommended' ? null : recommendedLabel;
  const isBest = recommended === 'best';

  const recommendedBadgeTooltip = retrieveContentfulData<string>(
    'benefits_section_additional.tooltips.recommended_badge',
  );

  const bestMatchBadgeTooltip = retrieveContentfulData<string>(
    'benefits_section_additional.tooltips.best_match_badge',
  );

  const renderPlanContent = () => {
    if (selectedPlan) {
      return (
        <SelectedPlanCard
          insuranceType={insuranceType}
          selectedPlan={selectedPlan}
          builderBenefit={builderBenefits?.[selectedPlan.external_id]}
          coveredMembers={coveredMembers}
          handleOpenBenefit={handleOpenBenefit}
          planCount={planCount}
          isAutoEnrolled={isAutoEnrolled}
          isEmployerPaid={isEmployerPaid}
        />
      );
    }

    return (
      <PlanContent
        insuranceType={insuranceType}
        title={title}
        description={description}
        client={client}
        employerName={employerName}
        builderBenefits={builderBenefits}
        handleOpenBenefit={handleOpenBenefit}
        healthHsaEligible={healthHsaEligible}
        nonSelectablePlans={supplementalPlans}
        isEducational={isEducational}
        reasonsWhy={reasonsWhy}
        selectedHealthDeductible={selectedHealthDeductible}
        dependentsCount={dependentsCount}
        surveyAnswers={surveyAnswers}
        riskAversion={riskAversion}
        planCount={planCount}
        builderCustomerKey={builderCustomerKey}
      />
    );
  };

  return (
    <Container
      id={`${insuranceType}-section`}
      data-testid={`${insuranceType}-section`}
      className="benefit-container"
      isAutoEnrolled={isAutoEnrolled}
      recommended={recommended}
      fbsBestMatch={fbsBestMatch}
    >
      {recommended && !isAutoEnrolled && (
        <RecommendedBadge recommended={recommended} fbsBestMatch={fbsBestMatch}>
          <Tooltip
            label={isBest ? bestMatchBadgeTooltip : recommendedBadgeTooltip}
            id={`${insuranceType}-recommended-badge-tooltip`}
          >
            <div className="text">
              <Icon
                size="small"
                weight="fill"
                color={fbsBestMatch && !isBest ? '--text-primary-blue' : '--primary-white'}
                type={fbsBestMatch && isBest ? 'CrownSimple' : 'Star'}
              />
              <H6
                color={fbsBestMatch && !isBest ? '--colors-primary-blue' : '--primary-white'}
                as="div"
                underline
              >
                {isBest ? (
                  <Text field="benefits_section_additional.best_match_badge_label" />
                ) : (
                  <Text field="benefits_section_additional.recommended_badge_label" />
                )}
              </H6>
            </div>
          </Tooltip>
        </RecommendedBadge>
      )}
      <div className="main-content">
        <Header>
          <span className="title">
            <div className="icon">
              <SupplementalIcon type={insuranceType} />
            </div>
            <H4 as="h3" data-testid="supplemental-card-title">
              {title}
            </H4>
          </span>
          {isEmployerPaid && !selectedPlan && (
            <span className="auto-enrolled">
              <Text field="benefits_section.auto_enrolled_info.tagText" />
            </span>
          )}
        </Header>

        {renderPlanContent()}
      </div>
    </Container>
  );
};

export default SupplementalCard;
